import React, { useState } from "react"; 
import CampaignsAdd from "./CampaignsAdd";
import AIAssisted from "./AI_Assisted";
import UserCampaignSequence from "./UserCampaignSequence";

const PageHeader = () => {
    const [showCampaignsModal, setShowCampaignsModal] = useState(false);
    const [showAI_AssistedModal, setShowAI_AssistedModal] = useState(false);
    const [showUser_campaign_SequenceModal, setShowUser_campaign_SequenceModal] = useState(false);

    const openCampaignsModal = () => setShowCampaignsModal(true);
    const closeCampaignsModal = () => setShowCampaignsModal(false);

    const openAI_AssistedModal = () => setShowAI_AssistedModal(true);
    const closeAI_AssistedModal = () => setShowAI_AssistedModal(false);

    const openuser_campaign_SequenceModalModal = () => setShowUser_campaign_SequenceModal(true);
    const closeuser_campaign_SequenceModalModal = () => setShowUser_campaign_SequenceModal(false);

    return (  
     <header className="border-bottom px-4 ff-inter d-flex py-3 align-items-center position-sticky top-0 bg-white  zindex-1">
         <h1 className="ff-inter h4 w-auto d-inline-block me-auto mb-0">Campaigns</h1> 
         <div className="dropdown me-3">
             <div className="input-group  ">
                 <span className="input-group-text bg-white">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                         <path d="M16.927 17.04L20.4001 20.4M19.2801 11.44C19.2801 15.7699 15.77 19.28 11.4401 19.28C7.11019 19.28 3.6001 15.7699 3.6001 11.44C3.6001 7.11006 7.11019 3.59998 11.4401 3.59998C15.77 3.59998 19.2801 7.11006 19.2801 11.44Z" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" />
                     </svg>
                 </span>
                 <input type="text" className="form-cogbgntrol fs-14 border-start-0 ps-0 shadow-none no-outline border" placeholder="Search Email" aria-label="Username" aria-describedby="basic-addon1" />
             </div>
         </div>
         <div className="dropdown me-3">
             <button className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                 <div className="text-start text-truncate w-200" id="statusDropdown">
                     <img id="statusImage" src="assets/img/svg/ata-icon-1.svg" alt="" /> All Status
                 </div>
             </button>
                <ul className="dropdown-menu fs-14" aria-labelledby="statusDropdown">
                    <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-1.svg', ' All Status', 'statusDropdown')"><img src="assets/img/svg/ata-icon-1.svg" alt="" /> All Status</button></li>
                    <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-2.svg', ' Paused', 'statusDropdown')"><img src="assets/img/svg/ata-icon-2.svg" alt="" /> Paused</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-3.svg', ' Has errors', 'statusDropdown')"><img src="assets/img/svg/ata-icon-3.svg" alt="" /> Has errors</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-4.svg', ' No custom tracking domain', 'statusDropdown')"><img src="assets/img/svg/ata-icon-4.svg" alt="" /> No custom tracking domain</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-5.svg', ' Warmup active', 'statusDropdown')"><img src="assets/img/svg/ata-icon-5.svg" alt="" /> Warmup active</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-6.svg', ' Warmup paused', 'statusDropdown')"><img src="assets/img/svg/ata-icon-6.svg" alt="" /> Warmup paused</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-7.svg', ' Warmup has errors', 'statusDropdown')"><img src="assets/img/svg/ata-icon-7.svg" alt="" /> Warmup has errors</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown('assets/img/svg/ata-icon-8.svg', ' No Tag', 'statusDropdown')"><img src="assets/img/svg/ata-icon-8.svg" alt="" /> No Tag</button></li>
             </ul>
         </div>
         <div className="dropdown me-3">
             <button className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                 <div className="text-start text-truncate w-200" id="timeDropdown">Newest First</div>
             </button>
             <ul className="dropdown-menu fs-14" aria-labelledby="timeDropdown">
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown(null,  ' Newest First', 'timeDropdown')"> Newest First</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown(null,  ' Oldest First', 'timeDropdown')">  Oldest First</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown(null,  ' Name (A-Z)', 'timeDropdown')">Name (A-Z)</button></li>
                 <li><button className="dropdown-item" type="button" onClick="changeDropdown(null,  ' Name (Z-A)', 'timeDropdown')">Name (Z-A)</button></li>
             </ul>
         </div>
         <svg xmlns="http://www.w3.org/2000/svg" width="1" height="51" viewBox="0 0 1 51" fill="none">
             <line x1="0.5" y1="2.18558e-08" x2="0.499998" y2="51" stroke="#E1E5E8" />
         </svg>
            <button type="button" className="btn btn-primary border mx-3 fs-14" id="addNewMember" onClick={openCampaignsModal} ><img src="assets/img/svg/plus.svg" alt="" /> Add new </button> 
            {showCampaignsModal && (
                <CampaignsAdd
                    closeModal={closeCampaignsModal}
                    openAI_AssistedModal={openAI_AssistedModal}
                    openuser_campaign_SequenceModalModal={openuser_campaign_SequenceModalModal}
                />
            )}
            {showAI_AssistedModal && <AIAssisted closeModal={closeAI_AssistedModal} />}

            {showUser_campaign_SequenceModal && <UserCampaignSequence closeModal={closeuser_campaign_SequenceModalModal}  />}
     </header>

  );
};

export default PageHeader;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/img/Search.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input{
    width: 240px !important;
    padding: 7px !important;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: center left 10px;
    padding-left: 20px !important;
    padding-left: 35px !important;
}

.details-heading{
    font-size: 20px ;
    font-weight: 600 ;
}`, "",{"version":3,"sources":["webpack://./src/page/Campaigns/DetailsCommponents/styles/SendEmailDetails.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,mDAAoD;IACpD,4BAA4B;IAC5B,qCAAqC;IACrC,6BAA6B;IAC7B,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".search-input{\n    width: 240px !important;\n    padding: 7px !important;\n    background: url('../../../../assets/img/Search.png');\n    background-repeat: no-repeat;\n    background-position: center left 10px;\n    padding-left: 20px !important;\n    padding-left: 35px !important;\n}\n\n.details-heading{\n    font-size: 20px ;\n    font-weight: 600 ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import Header from "../../common/Header.js";
import PageHeader from "./Header.js";
import TabBarDasdh from "./tabs.js";
import SummaryCard from "../../common/Summary_card.js";  
import BarChart from "../../common/BarChart.js";  

const Dashboard = () => {
      
    return (
        <div className="w-100 d-flex">
            <Header />
            <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                <PageHeader />
                <div className="px-4 ff-inter py-3">
                    <h5 className="w-100 mb-3">Performance summary</h5>
                    <div className="row g-3 row-cols-1 row-cols-md-3 row-cols-lg-5 mb-3 mb-mb-4 mb-lg-5">
                        <div className="col">
                            <SummaryCard summaryTitlte="Total numbers of campaign" backgroundColor="#FFF4D9" tooltipCodes="25" countForNumber="25" count={20} simbol={true} showCountIndicator={true} showInfoImg={ true} />
                        </div>
                        <div className="col">
                            <SummaryCard summaryTitlte="Active campaigns" backgroundColor="#D9F2FD" tooltipCodes="8" countForNumber="8" count={20.2} simbol={true} showCountIndicator={true} showInfoImg={true} />
                        </div>
                        <div className="col">
                            <SummaryCard summaryTitlte="Inactive campaigns" backgroundColor="#FFE2F7" tooltipCodes="15" countForNumber="15" count={9.4} simbol={true} showCountIndicator={true} showInfoImg={true} />
                        </div>
                        <div className="col">
                            <SummaryCard summaryTitlte=" Total numbers of replied rate" backgroundColor="#EBE8FF" tooltipCodes="54" countForNumber="54" count={-4.4} simbol={true} showCountIndicator={true} showInfoImg={true} />
                        </div>
                        <div className="col">
                            <SummaryCard summaryTitlte="Lead Generated" backgroundColor="#E8FFDA" tooltipCodes="10 | $0" countForNumber="10 | $0" count={-58} simbol={true} showCountIndicator={true} showInfoImg={true} /> 
                        </div>
                    </div>
                    <div className="row  mb-3 mb-mb-4 mb-lg-5">
                        <div className="col-12 col-md-6">
                            <div className="bg-F5F8FF rounded-4 p-3">
                                <h5 className="w-100 mb-3 ">Email Data Chart</h5>
                                <BarChart></BarChart>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="bg-F5F8FF rounded-4 p-3">
                                <div className="row g-3 row-cols-1 row-cols-md-2  mb-3 mb-mb-4 mb-lg-5">
                                    <div className="cols">
                                        <SummaryCard summaryTitlte="Hot Leads" backgroundColor="#fff"   countForNumber="77" count={100} simbol={true} showCountIndicator={true} className="border h-100" /> 
                                    </div>
                                    <div className="cols">
                                        <SummaryCard summaryTitlte="Further Action Leads" backgroundColor="#fff"   countForNumber="17" count={100} simbol={true} showCountIndicator={true} className="border h-100" /> 
                                    </div>
                                    <div className="cols">
                                        <SummaryCard summaryTitlte="Leads to Review" backgroundColor="#fff"   countForNumber="37" count={10} simbol={true} showCountIndicator={true} className="border h-100" /> 
                                    </div>
                                    <div className="cols">
                                        <SummaryCard summaryTitlte="DND" backgroundColor="#fff"   countForNumber="13743"  className="border h-100" /> 
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>  
                    <h5 className="w-100 mb-3 ">Analytics</h5>
                    <TabBarDasdh></TabBarDasdh> 
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CampaignData, CampaignDataSaveEdit } from '../../../services/authFunctions'; // Assuming you have an API method to update stage

const DetailsTabSequences = () => {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null); // Store selected stage for editing
    const [subject, setSubject] = useState(''); // Editable subject field
    const [description, setDescription] = useState(''); // Editable description field

    // Fetch campaign data on initial load
    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                //console.log("Fetching campaign data for ID:", campaignId);
                const campaignsData = await CampaignData(campaignId);
               // console.log("Fetched Campaign Data:", campaignsData);

                // Ensure campaignsData is an array and find the campaign
                if (Array.isArray(campaignsData)) {
                    const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                    if (filteredCampaign) {
                        setCampaign(filteredCampaign);
                    } else {
                        setError('Campaign not found');
                    }
                } else {
                    console.error("Expected an array, but received:", campaignsData);
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaigns:", err);
                setError('Failed to fetch campaigns');
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchCampaigns();
        } else {
            setError('Campaign ID is missing');
            setLoading(false);
        }
    }, [campaignId]);

    // Handle selecting a stage and setting the form data
    const handleStageClick = (stage) => {
        setSelectedStage(stage); // Set selected stage
        setSubject(stage.subject || ''); // Set subject to editable field
        setDescription(stage.description || ''); // Set description to editable field
    };

    const handleSave = async () => {
        console.log('Save button clicked');

        // Check if a stage is selected before proceeding
        if (selectedStage && selectedStage.id) {
            try {
                // Prepare the updated stage data with subject and description
                const updatedStage = {
                    ...selectedStage,
                    subject,
                    description
                };

                // Update the stages array with the modified stage
                const updatedStages = campaign.stages.map(stage =>
                    stage.id === selectedStage.id ? updatedStage : stage
                );

                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    return date.toISOString().slice(0, 19).replace('T', ' '); // Convert to MySQL-compatible format
                };

                // Check if campaign ID exists before proceeding
                if (!campaign || !campaign.id) {
                    alert("Campaign ID is missing. Cannot update.");
                    return; // Exit if ID is invalid
                }

                // Prepare the request data with formatted dates
                const requestData = {
                    user_id: "1",
                    id: campaign.id,
                    name: campaign.name,
                    status: campaign.status,
                    scheduled_at: campaign.scheduled_at,
                    start_date: formatDate(campaign.start_date), // Format start_date
                    end_date: formatDate(campaign.end_date), // Format end_date
                    stages: updatedStages // Only update the stages
                };

                console.log("Request Data to be sent to API:", requestData);

                // Send the updated data to the API (this should update the campaign stages)
                const response = await CampaignDataSaveEdit(requestData);
                console.log("API Response from CampaignDataSaveEdit:", response); // Log the API response

                // Check if the response is valid and contains the updated campaign data
                if (response && response.id) {
                    // Replace the alert with Bootstrap success alert
                    showAlert("Stage updated successfully!", "success");

                    // Update local state with the new stages after successful save
                    setCampaign(prevCampaign => ({
                        ...prevCampaign,
                        stages: updatedStages
                    }));
                } else {
                    console.error('Unexpected API response:', response);
                    showAlert("Unexpected error occurred while saving stage.", "danger");
                }
            } catch (err) {
                console.error("Error updating stage:", err);
                showAlert("Error updating stage", "danger");
            }
        } else {
            showAlert("No valid stage selected to update.", "danger");
        }
    };

    const showAlert = (message, type) => {
        const alertContainer = document.getElementById("alert-container");

        // Create a new alert div with the appropriate message and Bootstrap class
        const alertDiv = document.createElement("div");
        alertDiv.classList.add("alert", `alert-${type}`, "alert-dismissible", "fade", "show", "fixed-top");
        alertDiv.setAttribute("role", "alert");
        alertDiv.innerHTML = `
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    `;

        // Append the alert to the container
        alertContainer.appendChild(alertDiv);

        // Automatically remove the alert after 5 seconds
        setTimeout(() => {
            alertDiv.classList.remove("show");
            alertDiv.classList.add("fade");
            setTimeout(() => alertDiv.remove(), 500); // Remove after fading out
        }, 3000);
    };





    if (loading) {
        return <p>Loading campaign data...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    // Render the campaign stages and the form for editing the selected stage
    return (
        <div className="ff-inter h-100">
            <div id="alert-container"></div>
            <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                    <div className="bg-F5F8FF p-3 rounded-3">
                        {campaign && campaign.stages && campaign.stages.length > 0 ? (
                            campaign.stages.map((stage) => (
                                <div key={stage.id}>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-dark text-decoration-none mb-3 d-block"
                                        onClick={() => handleStageClick(stage)} // Set stage on click
                                    >
                                        Stage Number {stage.stage_number}
                                    </a>
                                    <hr />
                                </div>
                            ))
                        ) : (
                            <p>No stages available</p>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-8 col-xl-9">
                    <div className="border rounded-3 p-3">
                        {selectedStage ? (
                            <>
                                <div className="row   align-items-center w-100">
                                    <div className="col-auto">
                                        <label htmlFor="inputSubject" className="col-form-label">Subject</label>
                                    </div>
                                    <div className="col">
                                        <input
                                            id="inputSubject"
                                            type="text"
                                            className="form-control w-100"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)} // Update subject on input change
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="w-100 mb-3">
                                    <textarea
                                        className="form-control w-100"
                                        id="inputDescription"
                                        rows="10"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)} // Update description on textarea change
                                    />
                                </div>
                                <div className="col-auto">
                                    <button type="button" className="btn btn-primary mb-3" onClick={handleSave}>
                                        Save
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p>Select a stage to edit.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsTabSequences;

import React, { useState, useMemo } from 'react';
import './styles/CampaignDataTable.css';
import { useSelector } from 'react-redux';
import show_detail_icon from "../../../assets/img/note-icon.png";
import stop_icon from "../../../assets/img/play-icon.png";
import resume_icon from "../../../assets/img/paush-icon.png";
import { Tooltip } from 'react-tooltip';

const CampaignDataTable = ({
    contacts,
    formatDate,
    formatTime,
    openPopup,
    handleStopAiReply,
    handleResumeAiReply,
    stoppingAi,
    resumingAi,
    getPlainTextMainTable,
    searchTerm,
    selectedOptions,
    allColumns,
}) => {
    const searchQuery = useSelector(state => state.campaignDetails.searchQuery);
    const selectedFilters = useSelector(state => state.campaignDetails.selectedFilters);

    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowSelection = (email) => {
        setSelectedRows((prev) =>
            prev.includes(email) ? prev.filter((id) => id !== email) : [...prev, email]
        );
    };

    const getRowData = (emailGroup) => {
        const { primaryEmail, latestActivity, latestActivityType, dndLog, oooLog, processed_email, bounce_email, emailAnalytics } = emailGroup;
        const isSent = primaryEmail?.is_sent === 1;
        const emailBody = primaryEmail?.body || 'N/A';
        const plainTextMessage = getPlainTextMainTable(emailBody);

        let displayDate, displayTime, status, messageText, sentiment = null, isDndActivity = false, isOooActivity = false, isBounceActivity = false, analyticsStatus = [];

        if (oooLog && processed_email) {
            displayDate = formatDate(processed_email.created_at);
            displayTime = formatTime(processed_email.created_at);
            status = 'Received';
            messageText = getPlainTextMainTable(processed_email.email_body);
            sentiment = 'Out of Office';
            isOooActivity = true;
        }
        else if (latestActivityType === 'bounce_email' && bounce_email) {
            displayDate = formatDate(bounce_email.created_at);
            displayTime = formatTime(bounce_email.created_at);
            status = 'Bounce';
            messageText = getPlainTextMainTable(bounce_email.bounce_reason);
            sentiment = 'Bounce';
            isBounceActivity = true;
        }
        else if (latestActivityType === 'dnd_log' && dndLog) {
            displayDate = formatDate(dndLog.created_at);
            displayTime = formatTime(dndLog.created_at);
            status = 'Received';
            messageText = dndLog.matched_keyword;
            sentiment = 'DND';
            isDndActivity = true;
        } else if (latestActivityType === 'ooo_log' && oooLog) {
            displayDate = formatDate(oooLog.created_at);
            displayTime = formatTime(oooLog.created_at);
            status = 'Received';
            messageText = oooLog.matched_keyword;
            sentiment = 'Out of Office';
            isOooActivity = true;
        } else if (latestActivityType === 'processed_email' && latestActivity) {
            displayDate = formatDate(latestActivity.created_at);
            displayTime = formatTime(latestActivity.created_at);
            status = 'Received';
            messageText = getPlainTextMainTable(latestActivity.email_body);
            sentiment = latestActivity.sentiment;
        } else if (isSent) {
            displayDate = formatDate(primaryEmail?.created_at);
            displayTime = formatTime(primaryEmail?.created_at);
            status = 'Sent';
            messageText = plainTextMessage;
        }
        else {
            displayDate = formatDate(primaryEmail?.created_at);
            displayTime = formatTime(primaryEmail?.created_at);
            status = 'N/A';
            messageText = plainTextMessage;
        }

        if (emailAnalytics) {
            if (emailAnalytics.opened === 1) {
                analyticsStatus.push('Opened');
            }
            if (emailAnalytics.clicked === 1) {
                analyticsStatus.push('Clicked');
            }
        }

        return {
            displayDate,
            displayTime,
            status,
            messageText,
            sentiment,
            isDndActivity,
            isOooActivity,
            isBounceActivity,
            analyticsStatus
        };
    };

    const filterContacts = (contacts, searchTerm) => {
        return contacts.filter(emailGroup => {
            const rowData = getRowData(emailGroup);

            const statusFilter = selectedFilters.status || [];
            const sentimentFilter = selectedFilters.sentiment || [];

            const statusMatch = statusFilter.length === 0 || statusFilter.includes(rowData.status);
            const sentimentMatch = sentimentFilter.length === 0 || sentimentFilter.includes(rowData.sentiment);
            const searchTermMatch = !searchTerm || emailGroup.primaryEmail?.recipient_email?.toLowerCase().includes(searchTerm.toLowerCase());

            return statusMatch && sentimentMatch && searchTermMatch;
        });
    };

    const filteredContacts = filterContacts(contacts, searchTerm);

    const sortedContacts = [...filteredContacts].sort((a, b) => {
        const aData = getRowData(a);
        const bData = getRowData(b);

        const aIsSent = aData.status === 'Sent';
        const bIsSent = bData.status === 'Sent';

        if (aIsSent && !bIsSent) {
            return 1;
        }
        if (!aIsSent && bIsSent) {
            return -1;
        }

        const dateA = a.latestActivity?.created_at || a.primaryEmail?.created_at;
        const dateB = b.latestActivity?.created_at || b.primaryEmail?.created_at;

        return new Date(dateB) - new Date(dateA);
    });

    const visibleColumns = allColumns.filter(col => selectedOptions.includes(col.data));

    console.log("sortedContacts", filteredContacts)
    return (
        <>
            <table className="table table-hover">
                <thead className="table">
                    <tr>
                        {visibleColumns.map(column => (
                            <th key={column.data} className='fs-14 fw-600'>{column.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedContacts?.map((emailGroup, index) => {
                        const rowData = getRowData(emailGroup);
                        const { primaryEmail, isAiStopped, latestActivity } = emailGroup;
                        const isStopButtonDisabled = rowData.status === 'Sent' || rowData.isDndActivity || rowData.isOooActivity || rowData.isBounceActivity;
                        return (
                            <tr key={index}>
                                {visibleColumns.map(column => {
                                    switch (column.data) {
                                        case "checkbox":
                                            return (
                                                <td key={column.data}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedRows.includes(emailGroup.primaryEmail.recipient_email)}
                                                        onChange={() => handleRowSelection(emailGroup.primaryEmail.recipient_email)}
                                                    />
                                                </td>
                                            );
                                        case 'sr_no':
                                            return <td key={column.data}>{index + 1}</td>;
                                        case 'sender_email':
                                            return <td key={column.data}>{emailGroup.primaryEmail?.sender_email}</td>;
                                        case 'recipient_email':
                                            return <td key={column.data}>{emailGroup.primaryEmail?.recipient_email}</td>;
                                        case 'stage':
                                            return <td key={column.data}>{emailGroup.primaryEmail?.stage || 'N/A'}</td>;
                                        case 'date':
                                            return <td key={column.data}>{rowData.displayDate}</td>;
                                        case 'time':
                                            return <td key={column.data}>{rowData.displayTime}</td>;
                                        case 'status':
                                            return (
                                                <td key={column.data}>
                                                    {/* Main Status Badge */}
                                                    {/* <span
                                                        className={`badge ${rowData.isDndActivity ? 'bg-success' : rowData.isOooActivity
                                                            ? 'bg-success'
                                                            : rowData.isBounceActivity ? 'bg-orange text-dark'
                                                                : rowData.status === 'Received'
                                                                    ? 'bg-28C5E0'
                                                                    : rowData.status === 'Sent'
                                                                        ? 'bg-warn'
                                                                        : 'bg-secondary'
                                                            }`}
                                                    >
                                                        {rowData.status}
                                                    </span> */}

                                                    <span
                                                        className={`badge ${rowData.isDndActivity
                                                            ? 'bg-success' : rowData.status === 'Received' ? 'bg-28C5E0'
                                                                : rowData.status === 'Sent' ? 'bg-FFA15E'
                                                                    : rowData.status === 'Bounce' ? 'bg-FFB223'
                                                                        : rowData.status === 'Open'
                                                                            ? 'bg-info' : 'bg-secondary'
                                                            }`}
                                                    >
                                                        {rowData.status}
                                                    </span>

                                                    {/* Analytics Status Badges */}
                                                    {rowData.analyticsStatus.map(aStatus => (
                                                        <span key={aStatus} className={`badge ${aStatus === 'Opened' ? 'bg-F83628' : 'bg-click'} ms-1`}>{aStatus}({emailGroup.emailAnalytics[aStatus.toLowerCase()]})</span>
                                                    ))}
                                                </td>
                                            );
                                        case 'message':
                                            return (
                                                <td key={column.data}>
                                                    <span
                                                        title={rowData.messageText}
                                                        className="text-truncate d-inline-block"
                                                        style={{
                                                            maxWidth: '200px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {rowData.messageText.split(' ').slice(0, 3).join(' ')}...
                                                    </span>
                                                </td>
                                            );
                                        case 'sentiment':
                                            return (
                                                <td key={column.data}>
                                                    {rowData.isDndActivity ? (
                                                        <span className="badge bg-danger">DND</span>
                                                    ) : rowData.isOooActivity ? (
                                                        <span className="badge bg-747474">Out of Office</span>
                                                    ) : rowData.isBounceActivity ? (
                                                        <span className="badge bg-FFB223">Bounce</span>
                                                    ) : rowData.sentiment ? (
                                                        <span
                                                            className={`badge bg-${rowData.sentiment.trim().toLowerCase() === 'positive'
                                                                ? '63C888'
                                                                : rowData.sentiment.trim().toLowerCase() === 'negative'
                                                                    ? 'FF8F8F'
                                                                    : 'bg-BBBBBB'
                                                                }`}
                                                        >
                                                            {rowData.sentiment.trim()}
                                                        </span>
                                                    ) : (
                                                        <span className="d-flex justify-content-center">-</span>
                                                    )}
                                                </td>
                                            );
                                        case 'view_details':
                                            return (
                                                <td key={column.data} className="text-center">
                                                    <button
                                                        onClick={() => openPopup(emailGroup,)}
                                                        className="expand-button cursor-pointer border-0 bg-transparent"
                                                        data-tooltip-id="tooltip"
                                                        data-tooltip-content={rowData.messageText}
                                                    >
                                                        {/* Show details */}
                                                        <img src={show_detail_icon} alt='showdetails' className="tooltip-icon" />

                                                    </button>
                                                    <Tooltip id="tooltip" place="top" effect="solid" style={{ maxWidth: "300px" }} />
                                                </td>
                                            );
                                        case 'stop_ai':
                                            return (
                                                <td key={column.data} className="text-center">
                                                    {console.log("emailGroup.isAiStopped ", emailGroup.isAiStopped)}
                                                    {emailGroup.isAiStopped ? (
                                                        <button
                                                            className="btn p-0 border-0"
                                                            onClick={() => {
                                                                const recipientEmail = emailGroup.primaryEmail?.recipient_email;
                                                                console.log("recipientEmail", recipientEmail)

                                                                const confirmed = window.confirm(
                                                                    `Are you sure you want to resume sending responses for ${recipientEmail}?`
                                                                );
                                                                if (confirmed) {
                                                                    handleResumeAiReply(recipientEmail, index);
                                                                }
                                                            }}
                                                            disabled={!!resumingAi[index]}
                                                        >
                                                            <img src={resume_icon} alt="stop_icon" width="30" height="30" />
                                                            {/* {!!resumingAi[index] ? 'Resuming...' : <img src={resume_icon} alt="stop_icon"/>} */}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn p-0 border-0"
                                                            onClick={() => {

                                                                const recipientEmail = emailGroup.primaryEmail?.recipient_email;
                                                                console.log("recipientEmail", recipientEmail)

                                                                const confirmed = window.confirm(
                                                                    `Are you sure you want to stop sending responses for ${recipientEmail}?`
                                                                );
                                                                if (confirmed) {
                                                                    handleStopAiReply(recipientEmail, index);
                                                                }
                                                            }}
                                                            disabled={!!resumingAi[index]}

                                                        // disabled={isStopButtonDisabled || !!stoppingAi[index] || !latestActivity}
                                                        >
                                                            <img src={stop_icon} alt="stop_icon" width="30" height="30" />
                                                            {/* {!!stoppingAi[index] ? 'Stopping...' : <img src={stop_icon} alt="stop_icon" width="30" height="30"/>} */}
                                                        </button>
                                                    )}
                                                </td>
                                            );
                                        default:
                                            return <td key={column.data}></td>;
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default CampaignDataTable;
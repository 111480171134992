import React, { useState, useEffect } from 'react';
import DataTableComponent from '../../common/DataTableComponent';
import { ContactsData } from '../../services/authFunctions';

const ContactData = ({ filters, searchQuery, campaign_id }) => {
    const [data, setData] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [emailIds, setEmailIds] = useState([]);  // State to store emailIds

    const columns = [
        { title: "First Name", data: "first_name", isVisible: false },
        { title: "Last Name", data: "last_name", isVisible: false },
        { title: "Title", data: "title", isVisible: false },
        { title: "Company", data: "company", isVisible: false },
        { title: "Phone Number", data: "phone_number", isVisible: false },
        { title: "Email", data: "email", isVisible: false },
        { title: "City", data: "city", isVisible: false },
        { title: "State", data: "state", isVisible: false },
        { title: "Country", data: "country", isVisible: false },
        { title: "Gender", data: "gender", isVisible: false },
        { title: "Employees", data: "employees", isVisible: false },
        { title: "Industry", data: "industry", isVisible: false },
        { title: "Linkedin", data: "linkedin", isVisible: false },
        { title: "Website", data: "website", isVisible: false },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const contacts = await ContactsData();
                const trimmedContacts = contacts.map(contact => {
                    const trimmedContact = {};
                    // Trim all string fields in contact
                    Object.keys(contact).forEach(key => {
                        if (typeof contact[key] === 'string') {
                            trimmedContact[key] = contact[key].trim();
                        } else {
                            trimmedContact[key] = contact[key];
                        }
                    });
                    return trimmedContact;
                });
                setData(trimmedContacts);
                setLoading(false);
                setSelectedOptions(["first_name", "last_name", "email", "company"]);
            } catch (error) {
                setError("Failed to fetch Data");
                setLoading(false);
            }
        };
        fetchData();
    }, []);  // On initial render, fetch the data

    // Store email IDs after filtering the data
    useEffect(() => {
        if (data.length > 0) {
            const filteredData = filterData(data).filter((row) =>
                Object.keys(row).some((key) =>
                    String(row[key]).toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
            const emails = filteredData.map(contact => contact.email).filter(Boolean); // Extract emails and remove any null/undefined values
            setEmailIds(emails);  // Store the emails in the state
            // Save email IDs to localStorage
            localStorage.setItem('emailIds', JSON.stringify(emails));
        }
    }, [data, searchQuery, filters]); // Re-run this effect whenever data, filters, or searchQuery change

    // Retrieve email IDs from localStorage when the component mounts
    useEffect(() => {
        const storedEmails = localStorage.getItem('emailIds');
        if (storedEmails) {
            setEmailIds(JSON.parse(storedEmails));
        }
    }, []);  // Run only once when the component mounts

    const handleCheckboxChange = (optionId) => {
        setSelectedOptions((prev) => {
            if (prev.includes(optionId)) {
                return prev.filter((id) => id !== optionId);
            } else {
                return [...prev, optionId];
            }
        });
    };

    // Filter data based on the applied filters
    const filterData = (data) => {
        return data.filter((contact) => {
            // Ensure the contact object exists and has necessary properties
            if (!contact) return false;  // Skip if the contact is undefined or null

            const campaignIdMatch = contact.campaign_id && contact.campaign_id.toString() === campaign_id;
            const cityFilter = filters.city || [];
            const countryFilter = filters.country || [];
            const genderFilter = filters.gender || [];
            const industryFilter = filters.industry || [];
            const stateFilter = filters.state || [];

            // Check for city and other filters safely
            const cityMatch = cityFilter.length === 0 || (contact.city && cityFilter.includes(contact.city));
            const countryMatch = countryFilter.length === 0 || (contact.country && countryFilter.includes(contact.country));
            const genderMatch = genderFilter.length === 0 || (contact.gender && genderFilter.includes(contact.gender));
            const industryMatch = industryFilter.length === 0 || (contact.industry && industryFilter.includes(contact.industry));
            const stateMatch = stateFilter.length === 0 || (contact.state && stateFilter.includes(contact.state));

            // Return true if all conditions match
            return campaignIdMatch && cityMatch && countryMatch && genderMatch && industryMatch && stateMatch;
        });
    };

    const filteredColumns = columns.filter(col => selectedOptions.includes(col.data));
    const filteredData = filterData(data).filter((row) =>
        Object.keys(row).some((key) =>
            String(row[key]).toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    return (
        <>
            <hr />
            <div className="text-end">
                <div className="dropdown me-3 pos position-relative w-auto d-inline-block">
                    <button
                        className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <div className="text-start text-truncate w-200" id="ContDataDropdown">
                            Table Structure
                        </div>
                    </button>

                    <ul className="dropdown-menu fs-14 px-3 w-100" aria-labelledby="ContDataDropdown">
                        {columns.map((column) => (
                            <li className="mb-1" key={column.data}>
                                <label>
                                    <input
                                        className="me-1"
                                        type="checkbox"
                                        checked={selectedOptions.includes(column.data)}
                                        onChange={() => handleCheckboxChange(column.data)}
                                    />
                                    {column.title}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <hr />
            <div className="px-3">
                {loading ? (
                    <div>Loading...</div>  // Display loading message when fetching data
                ) : error ? (
                    <div>{error}</div>  // Display error message if there was an issue
                ) : (
                    <>
                        {/* Render the DataTableComponent with filtered data */}
                        <DataTableComponent
                            tableData={filteredData}
                            columns={filteredColumns}
                            showCheckboxes={true}
                            showEditButton={true}
                        />
                    </>
                )}
            </div>

            {/* Render Email IDs 
            <div className="email-list">
                <h5>Emails</h5>
                <ul>
                    {emailIds.length > 0 ? (
                        emailIds.map((email, index) => <li key={index}>{email}</li>)
                    ) : (
                        <li>No emails found.</li>
                    )}
                </ul>
            </div>*/}
        </>
    );
};

export default ContactData;

import React from 'react';

const CountIndicator = ({ count, className, simbol }) => {
    return (
        <>
            {count >= 0 ? (
                <span className={className} > 
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none" className="me-1">
                        <path d="M0.0909091 5.63636L5.13636 0.590909L10.1818 5.63636H7.59091V10H2.68182V5.63636H0.0909091Z" fill="#18A27D" />
                    </svg>
                    {`${count} ${simbol ? '%' : ''}`}
                </span>
            ) : (
                    <span className={className} > 
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none" className="me-1">
                        <path d="M10.9091 4.36364L5.86364 9.40909L0.818182 4.36364L3.40909 4.36364L3.40909 0L8.31818 0L8.31818 4.36364L10.9091 4.36364Z" fill="#FF0303" />
                    </svg>
                        {`${Math.abs(count)} ${simbol ? '%' : ''}`}
                </span>
            )}
        </>
    );
};

export default CountIndicator;

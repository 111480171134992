import React, { useEffect } from "react";
import "./styles/AddLeadsModalStyle.css";
import down_arrow from "../../../assets/img/chevron_down.png"
import axios from "axios";

const AddLeadsModal = ({ onClose }) => {

    // const fetchCountries = async () => {
    //     try {
    //         const response = await axios.get(
    //             `https://maps.googleapis.com/maps/api/geocode/json?components=country&key=${GOOGLE_API_KEY}`
    //         );
    //         if (response.data.results) {
    //             const countryList = response.data.results.map((item) => ({
    //                 name: item.formatted_address,
    //                 code: item.address_components[0].short_name,
    //             }));
    //             // setCountries(countryList);
    //             console.log("countryList",countryList)
    //         }
    //     } catch (error) {
    //         console.error("Error fetching countries:", error);
    //     }
    // };

    // useEffect(()=>{
    //     fetchCountries();
    // },[])

    return (
        <div className="modal-overlay lead-modal">
            <div className="modal-content">

                <div className="modal-header">
                    <h5 className="modal-title">Add Prospect Details</h5>
                    <button className="close-btn" onClick={onClose}>&times;</button>
                </div>

                <div className="modal-body">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">First Name*</label>
                                <input type="text" className="form-control width-70" placeholder="First Name" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Last Name*</label>
                                <input type="text" className="form-control width-70" placeholder="Last Name" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Phone</label>
                                
                                <div className="d-flex width-70 dropdown">

                                    <select className="form-select form-control me-2" style={{ width: "80px" }}>
                                        <option>IN</option>
                                        <option>US</option>
                                        <option>UK</option>
                                    </select>
                                    {/* <img  src={down_arrow} alt="down-arrow" style={{height:"7px",width:"12px"}}/> */}
                                    <input type="text" className="form-control " placeholder="+91 1234567890" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Email*</label>
                                <input type="email" className="form-control width-70" placeholder="abc@xyz.com" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Country*</label>
                                <select className="form-select width-70">
                                    <option>Country</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">State</label>
                                <select className="form-select width-70">
                                    <option>State</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">City</label>
                                {/* <input type="text" className="form-control width-70" placeholder="City" /> */}
                                <select className="form-select width-70">
                                    <option>City</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Industry*</label>
                                <select className="form-select width-70">
                                    <option>Industry</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Company Name*</label>
                                <input type="text" className="form-control width-70" placeholder="Company Name" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Website</label>
                                <input type="text" className="form-control width-70" placeholder="www.abc.com" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Title</label>
                                <input type="text" className="form-control width-70" placeholder="Your Role" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="width-30">Employees*</label>
                                <select className="form-select width-70">
                                    <option>1-10</option>
                                    <option>11-50</option>
                                    <option>51-200</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button className="btn btn-primary">Add</button>
                    <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default AddLeadsModal;


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-orange {
    background-color: rgb(255, 145, 0) !important;
    color: white !important;
}
.bg-open{
    background-color: #0074BA !important;
    color: white !important;
}

.bg-click{
    background-color: #9A47FF !important;
    color: white !important;
}
.bg-green {
    background-color:#97F2B9 ;
    color: #111111 !important;
}
.bg-warn{
    background-color:#dda602;
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/page/Campaigns/DetailsCommponents/styles/CampaignDataTable.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,uBAAuB;AAC3B;AACA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;AACA;IACI,wBAAwB;IACxB,uBAAuB;AAC3B","sourcesContent":[".bg-orange {\n    background-color: rgb(255, 145, 0) !important;\n    color: white !important;\n}\n.bg-open{\n    background-color: #0074BA !important;\n    color: white !important;\n}\n\n.bg-click{\n    background-color: #9A47FF !important;\n    color: white !important;\n}\n.bg-green {\n    background-color:#97F2B9 ;\n    color: #111111 !important;\n}\n.bg-warn{\n    background-color:#dda602;\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

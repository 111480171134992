import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js/auto';

Chart.register(...registerables);

const BarChart = ({ barChartData }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (!barChartData || !barChartData.labels || !barChartData.datasets) {
            return; 
        }

        const ctx = canvasRef.current.getContext('2d');

        const myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: barChartData.labels,
                datasets: barChartData.datasets.map(dataset => ({
                    ...dataset,
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointHoverRadius: 5,
                    pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                    tension: 0.4,
                }))
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                animations: {
                    radius: {
                        duration: 400,
                        easing: 'linear',
                        loop: (context) => context.active
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 20,
                            boxHeight: 2,
                            padding: 10,
                            font: {
                                size: 14,
                                weight: 'bold',
                            },
                            color: '#333',
                        },
                    },
                    tooltip: {
                        callbacks: {
                            title: function (tooltipItems) {
                                return 'Data Points';
                            },
                            label: function (tooltipItem) {
                                const datasetLabel = tooltipItem.dataset.label || '';
                                const value = tooltipItem.raw;
                                return `${datasetLabel}: ${value}`;
                            },
                            afterLabel: function (tooltipItem) {
                                const datasetLabels = tooltipItem.chart.data.datasets.map((dataset, index) => {
                                    const value = tooltipItem.chart.data.datasets[index].data[tooltipItem.dataIndex];
                                    return `${dataset.label}: ${value}`;
                                });
                                return datasetLabels;
                            }
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            color: 'rgba(0, 0, 0, .1)',
                            lineWidth: 1,
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)',
                            lineWidth: 1,
                        }
                    }
                },
            }
        });

        return () => {
            myChart.destroy();
        };
    }, [barChartData]);

    return (
        <div style={{ height: '316px', width: '100%' }}>
            <canvas
                ref={canvasRef}
                id="myChart"
                style={{ height: '100%', width: '100%' }}
            />
        </div>
    );
};

export default BarChart;
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DELETE_ICON from "../../assets/img/DeleteBtn.png";
import { RotatingLines } from 'react-loader-spinner';
import {
  setCampaignData,
  handleAddSequence,
  handleRemoveSequence,
  handleChange,
  resetCampaignForm,
  generateEmailContent,
  selectChannelContent,
  clearChannelContent,
} from "../../features/openAiCampaignFormSlice";
import {
  closeAiAssistedModal,
  openFinalizeContentModal,
} from "../../features/AIcampaignSlice";
import { useNavigate } from "react-router-dom";
import ai_illustration from "../../assets/img/ai_illustration.png";
import styles from "./styles/EmailContentGenerator.module.css";
import FinalizeContent from "./FinalizeSequences";

const OpenAiEmailContentGenerator = ({showModal, setShowModal}) => {
  // const [finalizeModalOpen, setFinalizeModalOpen] = useState(false);
  // console.log(finalizeModalOpen, "finalizeModalOpen");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state to manage the visibility of the AI-assisted modal
  // const [aiModalOpen, setAiModalOpen] = useState(true);

  const { campaignData, sequences, loading, channelContent, isSuccess, error } =
    useSelector((state) => state.campaignForm);
  const { finalizeContentModalOpen } = useSelector(
    (state) => state.campaign
  );

  const contentRef = useRef(null);

  useEffect(() => {
    if (isSuccess) {
      // setFinalizeModalOpen(true);
      // dispatch(openFinalizeContentModal());
      setShowModal('second')

    }
    if (error) {
      alert(`Error generating content: ${error}`);
    }

    return () => {
      dispatch(setCampaignData({}));
    };
  }, [isSuccess, error, dispatch]);

  // const handleContinueWithoutAI = useCallback(() => {
  //   // Close the AI modal using local state
   
  //   dispatch(clearChannelContent());
    
  //   // Open the finalize content modal after a short delay
 
  //   setTimeout(() => {
  //     // setFinalizeModalOpen(true);
  //     dispatch(openFinalizeContentModal());
  //   }, 500);
  //   setAiAssistedModalOpen(false);

  // }, [dispatch]);

  const handleContinueWithoutAI = () => {
    // Close the AI modal using local state
   
    // dispatch(clearChannelContent());
    
    // Open the finalize content modal after a short delay
 
    // setTimeout(() => {
    //   setFinalizeModalOpen(true);
    //   // dispatch(openFinalizeContentModal());
    // }, 500);
    setShowModal("second");

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!sequences.every((seq) => seq.channel && seq.delay)) {
      alert("Please fill out all sequence fields.");
      return;
    }

    const requiredFields = [
      "company_name",
      "pain_points",
      "value_proposition",
      "call_to_action",
    ];
    const missingFields = requiredFields.filter(
      (field) => !campaignData[field]
    );
    if (missingFields.length > 0) {
      alert(
        `Please fill out the following fields: ${missingFields.join(", ")}`
      );
      return;
    }

    dispatch(generateEmailContent());
   
    // setAiAssistedModalOpen(false);
  };

  const handleCloseModal = useCallback(() => {
   setShowModal(null);
  });

  return (
    <>
     
        <>
          <div
            className="modal-backdrop fade show"
            onClick={""}
            aria-hidden="true"
          ></div>
          <div
            className="modal fade show d-block"
            id="ai-assisted"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="ai-assisted-label"
            // aria-hidden={!aiModalOpen}
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content d-flex flex-column" style={{ minHeight: '80vh', maxHeight: '90vh' }}>
                <div className="modal-header">
                  <h5 className="modal-title">Add your company information</h5>
                  <button
                    type="button"
                    className={`btn ${styles.backButton}`}
                    onClick={handleCloseModal}
                    aria-label="Back"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.5 19L9.20711 12.7071C8.81658 12.3166 8.81658 11.6834 9.20711 11.2929L15.5 5"
                        stroke="#111111"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    {"Back"}
                  </button>
                </div>

                <div className="d-flex flex-grow-1 overflow-hidden">
                  <div className={`col-md-4 ${styles.aiAssistanceSection}`}>
                    <div className="d-flex flex-column h-100">
                      <h2 className={`campaign-title ${styles.aiTitle}`}>
                        Let AI assist with your sequences
                      </h2>
                      <p className={styles.aiSubtitle}>
                        Use OB AI to generate a complete campaign with sequential
                        contact points to engage target audiences at scale.
                      </p>
                      <div className="campaign-input-wrapper">
                        <div className="d-flex gap-2">
                          <img
                            src={ai_illustration}
                            alt="AI Illustration"
                            className={styles.aiImg}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`col-md-8 ${styles.companyInfoSection} d-flex flex-column`}
                  >
                    <div
                      className="campaign-content p-3"
                      style={{ overflowY: "auto", flexGrow: 1 }}
                      ref={contentRef}
                    >
                      <p className={styles.companySubtitle}>
                        <i className="bi bi-info-circle me-1"></i>
                        OB will try to pre-fill the sections below using your
                        existing information. We recommend editing as needed to help
                        generate higher quality content. This content will be used
                        only for this sequence, and won't impact other team members'
                        work.
                      </p>
                      <form onSubmit={handleSubmit}>
                        {[
                          {
                            label: "Company or Product Name*",
                            key: "company_name",
                            type: "text",
                          },
                          {
                            label: "Customer Pain Points*",
                            key: "pain_points",
                            type: "textarea",
                          },
                          {
                            label: "Value Proposition*",
                            key: "value_proposition",
                            type: "textarea",
                          },
                          {
                            label: "Call-to-Action*",
                            key: "call_to_action",
                            type: "textarea",
                          },
                          {
                            label: "Target Audience",
                            key: "target_audience",
                            type:"text",
                          },
                          {
                            label: "Company Overview",
                            key: "company_overview",
                            type: "text",
                          },
                          {
                            label: "Additional Context",
                            key: "additional_context",
                            type: "textarea",
                          },
                        ]?.map(({ label, key, type }) => (
                          <div className="mb-3" key={key}>
                            <label htmlFor={key} className="form-label">
                              {label}
                            </label>
                            {type === "textarea" ? (
                              <textarea
                                id={key}
                                className="form-control"
                                rows="3"
                                value={campaignData[key] || ""}
                                onChange={(e) =>
                                  dispatch(
                                    setCampaignData({ [key]: e.target.value })
                                  )
                                }
                              />
                            ) : (
                              <input
                                type={type}
                                id={key}
                                className="form-control"
                                value={campaignData[key] || ""}
                                onChange={(e) =>
                                  dispatch(
                                    setCampaignData({ [key]: e.target.value })
                                  )
                                }
                              />
                            )}
                          </div>
                        ))}

                        {/* Sequences */}
                        <div className="mb-3">
                          <label className="form-label">Add Your Sequence</label>
                          {sequences?.map((sequence, index) => (
                            <div
                              key={index}
                              className="d-flex gap-2 mb-2 align-items-center"
                            >
                              <select
                                className="form-select"
                                value={sequence.channel}
                                onChange={(e) =>
                                  dispatch(
                                    handleChange({
                                      index,
                                      field: "channel",
                                      value: e.target.value,
                                    })
                                  )
                                }
                                required
                              >
                                <option value="">Select Channel</option>
                                <option value="email">Email</option>
                                <option value="sms">SMS</option>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="linkedin">LinkedIn</option>
                                <option value="twitter">X (Twitter)</option>
                              </select>
                              <input
                                type="number"
                                className="form-control "
                                style={{ width: "120px" }}
                                placeholder="Length"
                                value={sequence.delay}
                                onChange={(e) =>
                                  dispatch(
                                    handleChange({
                                      index,
                                      field: "delay",
                                      value: e.target.value,
                                    })
                                  )
                                }
                                required
                              />
                              {sequences.length > 1 && (
                                <button
                                  type="button"
                                  className="btn btn-outline-light"
                                  onClick={() =>
                                    dispatch(handleRemoveSequence(index))
                                  }
                                >
                                  <img src={DELETE_ICON} alt="Delete" />
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-outline-secondary mt-2"
                            onClick={() => dispatch(handleAddSequence())}
                          >
                            + Add Sequence
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="modal-footer">
                      <button
                        className={`btn btn-light ${styles.buttonCancle}`}
                        type="button"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        className={`btn btn-primary text-white ${styles.buttonWithoutAI}`}
                        type="button"
                        onClick={handleContinueWithoutAI}
                      >
                        Continue Without AI
                      </button>
                      <button
                        className={`btn btn-primary text-white ${styles.generateButton}`}
                        type="submit"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {/* {loading ? "Generating..." : "Generate"} */}
                        {loading ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <RotatingLines
                                        strokeColor="white"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="20"
                                        visible={true}
                                    />
                                    <span style={{ marginLeft: '5px' }}>Generating...</span>
                                </div>
                            ) : "Generate"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      
        {/* finalizeModalOpen */}
      {/* { showModal === "second" &&  ( */}
        {/* <div className="modal-overlay">
          <div className="modal-content">
            <FinalizeContent  showModal={showModal}/>
          </div>
        </div> */}
      {/* )} */}
    </>
  );
};

export default OpenAiEmailContentGenerator;
// App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppRoutes from './routes/Routes';
 

const App = () => {

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${process.env.PUBLIC_URL}/assets/css/css.css`;
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, []);

    return (
        <Router>
            <AppRoutes />
        </Router>
    );
};

export default App;

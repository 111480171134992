import React from "react";
import Header from "../../common/Header.js";
import PageHeader from "./Header.js";

const EmailAccounts = () => {




    return (
        <div className="w-100 d-flex">
            <Header />
            <div className="w-100  mb-3 mb-mb-4 mb-lg-5">
                <PageHeader />
                <div className="px-4 ff-inter py-3">
                    <table className="table table-striped dataTable overflow-visible w-100" >
                        <thead>
                            <tr>
                                <th><input className="form-check-input me-1" type="checkbox" value="" /></th>
                                <th>Email </th>
                                <th>Emails sent</th>
                                <th>Warmup emails</th>
                                <th>Health score</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input className="form-check-input me-1" type="checkbox" value="" /></td>
                                <td className="custom-pointer">victor@officebeacon.tech</td>
                                <td className="custom-pointer"> 0 of 30   </td>
                                <td className="custom-pointer">72</td>
                                <td className="custom-pointer">100%</td>
                                <td>
                                    <img src="assets/img/svg/ata-icon-5.svg" width="24" alt="" />
                                    <div className="btn-group">
                                        <a href="javascript:void(0)" className="text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/img/svg/three-points-vertical.svg" width="24" alt="" />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end fs-12">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M5.33317 6.66667H13.3332L10.6665 4M10.6665 9.33333L2.6665 9.33333L5.33317 12" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Reconnect account
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6.6665 7.33333V11.3333M9.33317 7.33333V11.3333M2.6665 4.66667H13.3332M3.99984 4.66667V12C3.99984 13.1046 4.89527 14 5.99984 14H9.99984C11.1044 14 11.9998 13.1046 11.9998 12V4.66667H3.99984ZM9.99984 4.66667V3.33333C9.99984 2.59695 9.40288 2 8.6665 2H7.33317C6.59679 2 5.99984 2.59695 5.99984 3.33333V4.66667H9.99984Z" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Remove account
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </td>
                            </tr>

                            <tr className="custom-pointer">
                                <td><input className="form-check-input me-1" type="checkbox" value="" /></td>
                                <td className="custom-pointer">victor@officebeacon.tech</td>
                                <td className="custom-pointer"> 0 of 30   </td>
                                <td className="custom-pointer">72</td>
                                <td className="custom-pointer">100%</td>
                                <td>
                                    <img src="assets/img/svg/ata-icon-5.svg" width="24" alt="" />
                                    <div className="btn-group">
                                        <a href="javascript:void(0)" className="text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/img/svg/three-points-vertical.svg" width="24" alt="" />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end fs-12">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M5.33317 6.66667H13.3332L10.6665 4M10.6665 9.33333L2.6665 9.33333L5.33317 12" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Reconnect account
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6.6665 7.33333V11.3333M9.33317 7.33333V11.3333M2.6665 4.66667H13.3332M3.99984 4.66667V12C3.99984 13.1046 4.89527 14 5.99984 14H9.99984C11.1044 14 11.9998 13.1046 11.9998 12V4.66667H3.99984ZM9.99984 4.66667V3.33333C9.99984 2.59695 9.40288 2 8.6665 2H7.33317C6.59679 2 5.99984 2.59695 5.99984 3.33333V4.66667H9.99984Z" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Remove account
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </td>
                            </tr>

                            <tr className="custom-pointer">
                                <td><input className="form-check-input me-1" type="checkbox" value="" /></td>
                                <td className="custom-pointer">victor@officebeacon.tech</td>
                                <td className="custom-pointer"> 0 of 30   </td>
                                <td className="custom-pointer">72</td>
                                <td className="custom-pointer">100%</td>
                                <td>
                                    <img src="assets/img/svg/ata-icon-5.svg" width="24" alt="" />
                                    <div className="btn-group">
                                        <a href="javascript:void(0)" className="text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/img/svg/three-points-vertical.svg" width="24" alt="" />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end fs-12">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M5.33317 6.66667H13.3332L10.6665 4M10.6665 9.33333L2.6665 9.33333L5.33317 12" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Reconnect account
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6.6665 7.33333V11.3333M9.33317 7.33333V11.3333M2.6665 4.66667H13.3332M3.99984 4.66667V12C3.99984 13.1046 4.89527 14 5.99984 14H9.99984C11.1044 14 11.9998 13.1046 11.9998 12V4.66667H3.99984ZM9.99984 4.66667V3.33333C9.99984 2.59695 9.40288 2 8.6665 2H7.33317C6.59679 2 5.99984 2.59695 5.99984 3.33333V4.66667H9.99984Z" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Remove account
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </td>
                            </tr>
                            <tr className="custom-pointer">
                                <td><input className="form-check-input me-1" type="checkbox" value="" /></td>
                                <td className="custom-pointer">victor@officebeacon.tech</td>
                                <td className="custom-pointer"> 0 of 30   </td>
                                <td className="custom-pointer">72</td>
                                <td className="custom-pointer">100%</td>
                                <td>
                                    <img src="assets/img/svg/ata-icon-5.svg" width="24" alt="" />
                                    <div className="btn-group">
                                        <a href="javascript:void(0)" className="text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/img/svg/three-points-vertical.svg" width="24" alt="" />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end fs-12">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M5.33317 6.66667H13.3332L10.6665 4M10.6665 9.33333L2.6665 9.33333L5.33317 12" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Reconnect account
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6.6665 7.33333V11.3333M9.33317 7.33333V11.3333M2.6665 4.66667H13.3332M3.99984 4.66667V12C3.99984 13.1046 4.89527 14 5.99984 14H9.99984C11.1044 14 11.9998 13.1046 11.9998 12V4.66667H3.99984ZM9.99984 4.66667V3.33333C9.99984 2.59695 9.40288 2 8.6665 2H7.33317C6.59679 2 5.99984 2.59695 5.99984 3.33333V4.66667H9.99984Z" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Remove account
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </td>
                            </tr>
                            <tr className="custom-pointer">
                                <td><input className="form-check-input me-1" type="checkbox" value="" /></td>
                                <td className="custom-pointer">victor@officebeacon.tech</td>
                                <td className="custom-pointer"> 0 of 30   </td>
                                <td className="custom-pointer">72</td>
                                <td className="custom-pointer">100%</td>
                                <td>
                                    <img src="assets/img/svg/ata-icon-5.svg" width="24" alt="" />
                                    <div className="btn-group">
                                        <a href="javascript:void(0)" className="text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/img/svg/three-points-vertical.svg" width="24" alt="" />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end fs-12">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M5.33317 6.66667H13.3332L10.6665 4M10.6665 9.33333L2.6665 9.33333L5.33317 12" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Reconnect account
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6.6665 7.33333V11.3333M9.33317 7.33333V11.3333M2.6665 4.66667H13.3332M3.99984 4.66667V12C3.99984 13.1046 4.89527 14 5.99984 14H9.99984C11.1044 14 11.9998 13.1046 11.9998 12V4.66667H3.99984ZM9.99984 4.66667V3.33333C9.99984 2.59695 9.40288 2 8.6665 2H7.33317C6.59679 2 5.99984 2.59695 5.99984 3.33333V4.66667H9.99984Z" stroke="#747474" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    Remove account
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </td>
                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EmailAccounts;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CampaignData, CampaignDataSaveEdit } from '../../../services/authFunctions';

const DetailsTabSchedule = () => {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stages, setStages] = useState([]);

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                // Fetch campaign data
                const campaignsData = await CampaignData(campaignId);
                if (Array.isArray(campaignsData)) {
                    // Filter the specific campaign by campaignId
                    const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                    if (filteredCampaign) {
                        // Map the stages with the correct fields
                        setCampaign(filteredCampaign);
                        setStages(filteredCampaign.stages.map(stage => ({
                            ...stage,
                            subject: stage.subject || '', // If subject is missing, set as empty string
                            start_date: stage.start_date || '', // Directly use start_date
                            start_time: stage.start_time || '', // Directly use start_time
                        })));
                    } else {
                        setError('Campaign not found');
                    }
                } else {
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaign data:", err);
                setError('Failed to fetch campaign data');
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchCampaign();
        } else {
            setError('Campaign ID is missing');
            setLoading(false);
        }
    }, [campaignId]);

    // Handle changes in subject, start_date, and start_time for each stage
    const handleChange = (index, field, value) => {
        const updatedStages = [...stages];
        updatedStages[index] = {
            ...updatedStages[index],
            [field]: value,
        };
        setStages(updatedStages);
    };

    const handleSave = async () => {
        if (!campaignId || !stages.length) {
            setError('Campaign ID or Stage data is missing');
            return;
        }

        try {
            const updatedStagesData = stages.map(stage => ({
                ...stage,
                subject: stage.subject || '', // Ensure subject is not null
                start_date: stage.start_date || '', // Ensure start_date is not null
                start_time: stage.start_time || '', // Ensure start_time is not null
            }));

            const formatDate = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().slice(0, 19).replace('T', ' '); // Convert to MySQL-compatible format
            };

            if (!campaign || !campaign.id) {
                alert("Campaign ID is missing. Cannot update.");
                return;
            }

            const requestData = {
                user_id: "1", // Replace with actual user ID dynamically
                id: campaign.id,
                name: campaign.name,
                status: campaign.status,
                scheduled_at: campaign.scheduled_at,
                start_date: formatDate(campaign.start_date), // Format and send start_date
                end_date: formatDate(campaign.end_date), // Format and send end_date
                stages: updatedStagesData, // Send the updated stages
            };

            console.log("Request Data to be sent to API:", requestData);

            const response = await CampaignDataSaveEdit(requestData);

            console.log("API Response from CampaignDataSaveEdit:", response);

            // Check if the response contains expected data
            if (response && response.id) {
                showAlert("Stage updated successfully!", "success");

                // Update the local state with the saved data
                setCampaign(prevCampaign => ({
                    ...prevCampaign,
                    stages: updatedStagesData,
                }));

                // **Refetch Data**
                await refetchCampaignData();  // Call your refetch function here
            } else {
                console.error('Unexpected API response:', response);
                showAlert("Unexpected error occurred while saving stage.", "danger");
            }
        } catch (err) {
            console.error("Error updating stage:", err);
            showAlert("Error updating stage", "danger");
        }
    };

    // Function to fetch the updated campaign data (this is a placeholder, adapt to your logic)
    const refetchCampaignData = async () => {
        try {
            const response = await CampaignData();  // Replace with your actual data-fetching function
            if (response && response.data) {
                console.log('Fetched updated campaign data:', response.data);
                setCampaign(response.data); // Update the campaign state with the new data
            } else {
                console.error('Failed to fetch updated data');
            }
        } catch (error) {
            console.error('Error fetching updated campaign data:', error);
        }
    };


    const showAlert = (message, type) => {
        const alertContainer = document.getElementsByClassName("alert-container")[0];

        // Create a new alert div with the appropriate message and Bootstrap class
        const alertDiv = document.createElement("div");
        alertDiv.classList.add("alert", `alert-${type}`, "alert-dismissible", "fade", "show", "fixed-top");
        alertDiv.setAttribute("role", "alert");
        alertDiv.innerHTML = `${message} <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>`;

        // Append the alert to the container
        alertContainer.appendChild(alertDiv);

        // Automatically remove the alert after 5 seconds
        setTimeout(() => {
            alertDiv.classList.remove("show");
            alertDiv.classList.add("fade");
            setTimeout(() => alertDiv.remove(), 500); // Remove after fading out
        }, 3000);
    };

    return (
        <div className="ff-inter h-100">
            <div className="alert-container"></div>
            <div className="row mb-3">
                {stages.map((stage, index) => (
                    <div key={stage.id} className="col-12 col-md-4 col-xl-3">
                        <div className="bg-F5F8FF p-3 rounded-3">
                            <div className="text-dark">
                                Stage {stage.stage_number}
                            </div>
                            <hr />
                            <div className="mb-3">
                                <label className="form-label">Subject</label>
                                <textarea
                                    className="form-control"
                                    value={stage.subject}
                                    onChange={(e) => handleChange(index, 'subject', e.target.value)}
                                    rows="2"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Start Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={stage.start_date} // Directly use start_date
                                    onChange={(e) => handleChange(index, 'start_date', e.target.value)} // Directly use start_date
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Time</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    value={stage.start_time} // Directly use start_time
                                    onChange={(e) => handleChange(index, 'start_time', e.target.value)} // Directly use start_time
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={handleSave}>Save</button>
            </div>
        </div>
    );
};

export default DetailsTabSchedule;

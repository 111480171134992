import React, { useState } from "react";

const SearchBar = ({ onSearchChange }) => {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchQuery(value);
        onSearchChange(value); // Pass search query to the parent component
    };

    return (
        <> 
            <input type="text" className="bg-F5F8FF border-0 w-100 p-2 rounded-3" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
        </>
    );
};

export default SearchBar;

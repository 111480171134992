import React, { useState } from 'react';
import { resetPassword } from '../services/authFunctions';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        try {
            await resetPassword({ email });
            setSuccessMessage('Password reset link sent to your email!');
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };


    return (
        <div className="login-bg vh-100 w-100 d-flex align-items-center justify-content-center login-bg">
            <div className="w-390 bg-white">
                <div className="p-3 p-md-4">
                    <form className="row" onSubmit={handleSubmit}>
                        <div className="col-12 pt-md-2">
                            <h1 className="h4">Forgot your password?</h1>
                            <p className="tc-D7D7D7 fs-14">Enter your email below to receive a password reset link.</p>
                        </div>
                        <div className="col-12 mb-4 position-relative">
                            <label htmlFor="email" className="form-label fs-14">Email address</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-12 mb-4">
                            <button type="submit" className="btn btn-primary w-100">Reset Password</button>
                        </div>
                    </form>
                    {successMessage && (
                        <div className="col-12 text-success text-center mb-md-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect width="24" height="24" rx="12" fill="#D9F2E6" />
                                <path d="M9.08333 12.5833L10.8333 14.3333L14.9167 10.25M17.25 12C17.25 14.8995 14.8995 17.25 12 17.25C9.1005 17.25 6.75 14.8995 6.75 12C6.75 9.1005 9.1005 6.75 12 6.75C14.8995 6.75 17.25 9.1005 17.25 12Z" stroke="#309161" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="col-12 text-danger text-center mb-md-2">
                            {errorMessage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

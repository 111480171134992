import { React, useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import eyeIcon from "./../../../assets/img/eye_icon.png";
import thunderIcon from "./../../../assets/img/thunder.png";
import aiTool from "./../../../assets/img/ai_Icon.png";
import dashboard from "./../../../assets/img/dashboard_icon.png";
import sicon from "./../../../assets/img/s_icon.png";
import zigzagicon from "./../../../assets/img/zigzag_icon.png";
import { Modal, Container, Row, Col, Form, Button, Card, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ContactsData } from "../../../services/authFunctions";
import insertIcon from "./../../../assets/img/variable-icon.png";
import email_preview from "./../../../assets/img/icon/preview-icon.png";
import variable_icon from "./../../../assets/img/icon/variables-icon.png";
import test_email from "./../../../assets/img/icon/test-email-icon.png";
import sent_email from "./../../../assets/img/icon/sent_mail.png";

const StageEditor = ({ selectedStage, subject, description, handleSubjectChange, handleDescriptionChange, handleSave }) => {

    const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
    const [data, setData] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const quillRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const contacts = await ContactsData();
                const trimmedContacts = contacts.map(contact => ({
                    ...contact,
                    first_name: contact.first_name?.trim(),
                    email: contact.email?.trim(),
                }));

                setData(trimmedContacts);
                if (trimmedContacts.length > 0) {
                    setSelectedUser(trimmedContacts[0]);
                }
            } catch (error) {
                setError("Failed to fetch Data");
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const insertVariable = (variable) => {
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        if (range) {
            // editor.insertText(range.index, variable);
            editor.insertText(range.index, `{{${variable}}}`);
        }
    };

    return (
        <div className="border rounded-3 p-3">
            {/* {selectedStage ? (
                <> */}
            {/* <div className="row align-items-center w-100"> */}
            <div className="d-flex align-items-center justify-content-between w-100">

                <div className="col-auto">
                    <label htmlFor="inputSubject" className="col-form-label">Subject</label>
                </div>
                <div className="col">
                    <input
                        id="inputSubject"
                        type="text"
                        className="form-control w-100 border-0 "
                        value={subject}
                        onChange={handleSubjectChange}
                        placeholder="Your Subject"
                    />
                </div>
                <div className="d-flex">
                    <button className="btn btn-white me-2 border" onClick={() => setShowEmailPreviewModal(true)}>
                        <img src={eyeIcon} alt='eye_icon' /><span className='fs-14 mx-2'>Preview</span>
                    </button>
                </div>
            </div>
            <hr />
            <div className="w-100 email_editor">
                <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    value={description}
                    placeholder="Start typing here..."
                    onChange={handleDescriptionChange}
                    className="w-100"
                    style={{ height: "300px" }}
                    modules={{
                        toolbar: [
                            [{ header: [1, 2, 3, false] }],
                            ["bold", "italic", "underline", "strike"],
                            [
                                {
                                    color: [
                                        "#000000", "#e60000", "#ff9900", "#ffff00",
                                        "#008a00", "#0066cc", "#9933ff", "#ffffff",
                                        "#facccc", "#ffebcc", "#ffffcc", "#cce8cc",
                                        "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666",
                                        "#ffc266", "#ffff66", "#66b966", "#66a3e0",
                                        "#c285ff", "#888888", "#a10000", "#b26b00",
                                        "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600",
                                        "#003700", "#002966", "#3d1466",
                                    ],
                                },
                                {
                                    background: [
                                        "#000000", "#e60000", "#ff9900", "#ffff00",
                                        "#008a00", "#0066cc", "#9933ff", "#ffffff",
                                        "#facccc", "#ffebcc", "#ffffcc", "#cce8cc",
                                        "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666",
                                        "#ffc266", "#ffff66", "#66b966", "#66a3e0",
                                        "#c285ff", "#888888", "#a10000", "#b26b00",
                                        "#b2b200", "#006100", "#0047b2", "#6b24b2",
                                        "#444444", "#5c0000", "#663d00", "#666600",
                                        "#003700", "#002966", "#3d1466",
                                    ],
                                },
                            ],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link", "image"],
                            ["clean"],
                        ],
                    }}
                    formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "list",
                        "bullet",
                        "link",
                        "image",
                        "color",
                        "background",
                    ]}
                />
            </div>
            <div className="d-flex justify-content-between align-items-center mx-1" style={{ position: "relative", zIndex: "2" }}>
                {/* Save Button */}
                <button type="button" className="btn btn-primary" onClick={handleSave} style={{ cursor: "pointer" }}>
                    Save
                </button>

                {/* Modules Toolbar */}
                <div className="d-flex  d-flex justify-content-center align-items-center">

                    {/* <img src={aiTool} alt="aitools" className='img-16' /><button className="btn btn-light me-2">AI Tools</button> */}
                    <Dropdown>
                        <Dropdown.Toggle variant="light" className="d-flex align-items-center">
                            <img src={thunderIcon} alt="Insert Variables" className="me-2" />
                            Insert Variables
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* <Dropdown.Item onClick={() => insertVariable(`{{${selectedUser?.first_name || "First Name"}}}`)}> */}
                            <Dropdown.Item onClick={() => insertVariable("firstname")}>
                                First Name
                            </Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => insertVariable(`{{${selectedUser?.last_name || "Last Name"}}}`)}> */}
                            <Dropdown.Item onClick={() => insertVariable("lastname")}>
                                Last Name
                            </Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => insertVariable(`{{${selectedUser?.first_name || "First Name"}}}`)}>
                                Signature
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <img src={dashboard} alt="aitools" className='img-16' /><button className="btn btn-light me-2">Templates</button>
                    <button className="btn btn-light me-2"><strong>A</strong></button>
                    <button className="btn btn-light me-2"><img src={sicon} alt="Image" /></button>
                    <button className="btn btn-light me-2"><img src={zigzagicon} alt="Image" /></button>
                    <button className="btn btn-light"><code>&lt;/&gt;</code></button> */}
                </div>
            </div>

            {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{subject}</h5>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal> */}

            <Modal show={showEmailPreviewModal} onHide={() => setShowEmailPreviewModal(false)} centered size="lg" className='preview-modal'>
                <Modal.Header closeButton className="border-0 pb-0">
                    <Modal.Title className="fw-semibold fs-24"><img src={email_preview} alt='email_preview' /> Email Preview</Modal.Title>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <Form>
                        {/* Send To Field */}
                        <Form.Group className="mb-3 d-flex align-items-center">
                            <Form.Label className="me-3 fc-BB fs-16 mb-0" style={{ minWidth: "60px" }}>Send to:</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email address"
                                defaultValue={selectedUser?.email || ""}
                                className="rounded-pill px-3 fs-12 fc-black"
                            />
                        </Form.Group>

                        {/* Subject Field */}
                        <Form.Group className="mb-3 d-flex align-items-center">
                            <Form.Label className="me-3 fc-BB fs-16 mb-0" style={{ minWidth: "60px" }}>Subject:</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={subject}
                                className="border-0 fs-12 fc-black"
                            />
                        </Form.Group>

                        {/* Email Content Preview */}
                        <Card className="p-3 border-0 shadow-sm fs-16 rounded-3" style={{ background: "#F6F6F6", maxHeight: "230px", overflowY: "auto" }}>
                            <div
                                className="email-preview-content"
                                dangerouslySetInnerHTML={{
                                    __html: description
                                        .replace(/{{firstname}}/g, selectedUser?.first_name || "User")
                                        .replace(/{{lastname}}/g, selectedUser?.last_name || ""),
                                }}
                            />
                        </Card>

                        <div className="border rounded p-3 shadow-sm mt-3">
                            {/* Header */}


                            {/* Content Section */}
                            <div className="row">
                                {/* Left Side - Email Selection */}
                                <div className="col-md-3 border-end">
                                    <div className='d-flex'>
                                        <span className="me-2"><img src={test_email} alt="test_email" /></span>
                                        <p className="fw-semibold mb-0 fs-24">Test Email</p>
                                    </div>
                                    <label className="fc-BB fs-16 mt-3">Send from:</label>
                                    <select className="form-select mt-1">
                                        <option>alex@office-beacon.site</option>
                                    </select>
                                </div>

                                {/* Right Side - Variables */}
                                <div className="col-md-8">
                                    <div className='d-flex'>
                                        <span className="me-2"><img src={variable_icon} alt="variable" /></span>
                                        <p className="fw-semibold mb-0 fs-24 ">Variables</p>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-4">
                                            <label className="fc-BB fs-16">firstName :</label>
                                            <input
                                                type="text"
                                                value="Aakash"
                                                className="form-control mt-1"
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="fc-BB fs-16">sendingAccountFirstName:</label>
                                            <input
                                                type="text"
                                                value="Alex"
                                                className="form-control mt-1"
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="fc-BB fs-16">sendingAccountName:</label>
                                            <input
                                                type="text"
                                                value="Alex Smith"
                                                className="form-control mt-1"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-4 d-flex justify-content-center gap-3">
                            <Button variant="outline-primary fc-black" className="px-4 fs-14" style={{ width: "263px" }}>Check Deliverability Score</Button>
                            <Button variant="primary" className=" px-4 fs-14" style={{ width: "263px" }}><img src={sent_email} alt='send_email' className='me-2' />Send Test Email</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* </>
            ) : (
                <p>Select a stage to edit.</p>
            )} */}
        </div>
    );
};

export default StageEditor;
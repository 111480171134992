import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from 'react-loader-spinner';

import { selectCampaignName, closeAiAssistedModal, closeFinalizeContentModal, } from "../../features/AIcampaignSlice"
import { selectChannelContent, clearChannelContent, setIsSuccessFalse } from "../../features/openAiCampaignFormSlice";
import styles from "./styles/EmailContentGenerator.module.css";

const FinalizeContent = ({ showModal, setShowModal }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [steps, setSteps] = useState([{ day: 1, subject: "", body: "" }]);
    const campaignName = useSelector(selectCampaignName)
    const [InternalCampaignName, setInternalCampaignName] = useState(campaignName);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const { aiAssistedModalOpen, finalizeContentModalOpen } = useSelector((state) => state.campaign);
    // const { channelContent } = useSelector(
    //     (state) => state.campaignForm
    // );
    const { campaignData, channelContent, sequences, isSuccess, error } =
        useSelector((state) => state.campaignForm);


    // State to manage the open/closed state of each accordion item
    const [openAccordionItems, setOpenAccordionItems] = useState([]);

    useEffect(() => {
        if (sequences.length > 0) {
            setSteps(
                sequences?.map((seq, index) => ({
                    day: index + 1,
                    subject: "",
                    body: "",
                    channel: seq.channel
                }))
            );
        }
    }, [sequences]);


    // Initialize all items to be open on initial load
    useEffect(() => {
        if (steps.length > 0) {
            setOpenAccordionItems(steps?.map((_, index) => index));
        }
    }, [steps]);

    useEffect(() => {
        if (channelContent && Object.keys(channelContent).length > 0) {
            const updatedSteps = Object.keys(channelContent)?.map((channel, index) => ({
                day: 1 + index * 3, // Day starts from 1, increasing by 3
                subject: channelContent[channel]?.subject || `Subject for ${channel}`, // Access nested object
                body: channelContent[channel]?.body || `Content for ${channel} ${index + 1}` // Access nested object
            }));
            setSteps(updatedSteps);
        } else {
            // Reset steps if channelContent is empty or null
            setSteps([{ day: 1, subject: "", body: "" }]);
        }
    }, [channelContent]);

    useEffect(() => {
        console.log(InternalCampaignName, "campaign name");
    }, [InternalCampaignName])


    const closeModal = () => {
        setIsModalOpen(false); // Update state to close the modal
        // You can add any other cleanup logic here
        // such as resetting form data
        dispatch(closeAiAssistedModal());
        navigate("/campaigns")
    };

    const handleBack = useCallback(() => {
        //   dispatch(clearChannelContent());
        //     dispatch(closeFinalizeContentModal());
        // dispatch(closeAiAssistedModal());
        // setAiAssistedModalOpen(false)

        setShowModal(null);
        dispatch(clearChannelContent());
        dispatch(setIsSuccessFalse(false));



    }, [dispatch]);

    const handleInputChange = (index, field, value) => {
        setSteps(prevSteps => {
            // Prevent redundant state updates
            if (prevSteps[index]?.[field] === value) return prevSteps;

            // Create a new array with updated step
            return prevSteps?.map((step, i) =>
                i === index ? { ...step, [field]: value } : step
            );
        });
    };

    const addSequence = () => {
        if (steps.length > 0) {
            const lastStep = steps[steps.length - 1];
            if (!lastStep.subject || !lastStep.body) {
                setAlertMessage("Please fill in subject and message for the previous sequence before adding a new one.");
                setAlertType("danger");
                return;
            }
        }
        setAlertMessage("");
        setAlertType("");

        const lastDay = steps.length > 0 ? steps[steps.length - 1].day + 3 : 1;

        setSteps([
            ...steps,
            {
                day: lastDay,
                subject: "",
                body: "",
            },
        ]);
    };

    const removeSequence = (indexToRemove) => {
        setSteps(steps.filter((_, index) => index !== indexToRemove));
    };

    const handleSaveSequence = async () => {
        setLoading(true);
        try {
            const calculatedEndDate = new Date(currentDate);
            calculatedEndDate.setDate(currentDate.getDate() + (steps.length - 1) * 3);
            setEndDate(calculatedEndDate);

            const stages = steps?.map((step, index) => {
                const stageStartDate = new Date(currentDate);
                stageStartDate.setDate(currentDate.getDate() + index * 3);
                stageStartDate.setHours(
                    currentDate.getHours(),
                    currentDate.getMinutes(),
                    currentDate.getSeconds()
                );

                const start_date = stageStartDate.toISOString().split("T")[0];
                const start_time = stageStartDate
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0];

                return {
                    stage_number: index + 1,
                    subject: step.subject,
                    description: step.body,
                    start_date: start_date,
                    start_time: start_time,
                };
            });

            const campaignData = {
                user_id: "1",
                name: campaignName,
                status: "Draft",
                scheduled_at: currentDate.toISOString().split("T")[0],
                start_date: currentDate.toISOString().split("T")[0],
                end_date: endDate.toISOString().split("T")[0],
                stages: stages,
            };

            console.log("Sending request to API...", campaignData);
            const response = await CampaignDataSave(campaignData);

            console.log("API Response:", response);

            if (response && response.id) {
                const campaignId = response.id;
                console.log(`Campaign created successfully with ID: ${campaignId}`);


                navigate(`/campaigns/${campaignId}`);

            } else {
                throw new Error("Campaign ID is missing in the response.");
            }


        } catch (error) {
            console.error("Error saving campaign:", error);
            setAlertMessage("There was an error saving the campaign. Please try again.");
            setAlertType("danger");
        } finally {

            setLoading(false);
            dispatch(clearChannelContent());
            dispatch(setIsSuccessFalse(false));


        }

    };
    const handleEditInformation = () => {
        dispatch(clearChannelContent());
        dispatch(setIsSuccessFalse(false));
        setShowModal("first");
    }

    const deleteLastSequence = () => {
        if (steps.length > 1) {
            setSteps(steps.slice(0, -1));
        } else {
            setAlertMessage("You must have at least one sequence.");
            setAlertType("danger");
        }
    };

    const url = process.env.REACT_APP_URL;
    const token = localStorage.getItem('token');

    const CampaignDataSave = async (data) => {
        try {
            const response = await axios.post(`${url}/api/campaigns`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error saving campaign:', error);
            throw error;
        }
    };

    // Function to toggle the accordion item
    const toggleAccordionItem = (index) => {
        if (openAccordionItems.includes(index)) {
            // Close the accordion item
            setOpenAccordionItems(openAccordionItems.filter((item) => item !== index));
        } else {
            // Open the accordion item
            setOpenAccordionItems([...openAccordionItems, index]);
        }
    };

    console.log("sequences", sequences)

    return (
        <>


            {/* {finalizeContentModalOpen && ( */}
            {showModal === "second" && (
                <>
                    <div className="modal-backdrop fade show"></div>
                    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">

                                <div className="modal-header bg-F5F8FF py-4">
                                    <div className="d-flex px-4 justify-content-between ff-inter w-100">
                                        <div>
                                            <p className="fw-600 fs-16">Finalize your content</p>
                                            <p className="mb-0 fw-400 fs-14">
                                                Review and edit your emails, then save your sequence.
                                            </p>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn fs-14 mt-2 me-2"
                                            onClick={handleBack}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                            >
                                                <path
                                                    d="M12.9166 15.8335L7.79036 10.7073C7.39983 10.3167 7.39983 9.68358 7.79036 9.29306L12.9166 4.16683"
                                                    stroke="#747474"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            Back
                                        </button>
                                        {/* <button type="button" className="btn-close" onClick={handleBack} aria-label="Close"></button> */}

                                    </div>
                                </div>

                                {/* Alert message */}
                                {alertMessage && (
                                    <div className={`alert alert-${alertType} alert-dismissible fade show`} role="alert">
                                        {alertMessage}
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => { setAlertMessage(""); setAlertType(""); }}></button>
                                    </div>
                                )}

                                <div className="modal-body h-100">



                                    <div className="border-bottom mb-3 pb-3 d-flex justify-content-between pt-3 px-3">
                                        <h5 className="fs-20 fw-600">Outbound AI Sequence</h5>
                                        <span className="fs-14 tc-979797">
                                            {steps.length} steps | {steps.length * 3} days in sequence
                                            <img
                                                src="assets/img/svg/info-icon.svg"
                                                width="20"
                                                className="ms-2"
                                                alt=""
                                            />
                                        </span>
                                    </div>


                                    <div className="accordion p-3" id="accordionExample">


                                        {steps?.map((step, index) => {
                                            const sequence = sequences[index] || {};
                                            return (
                                                <div className="accordion-item position-relative" key={index}>
                                                    {steps.length > 1 && (
                                                        <button
                                                            className="btn btn-danger btn-sm position-absolute top-0 end-0 m-2 z-10"
                                                            onClick={() => removeSequence(index)}
                                                        >
                                                            ×
                                                        </button>
                                                    )}
                                                    <h2 className="accordion-header" id={`heading${index}`}>
                                                        <button
                                                            className="accordion-button bg-white rounded-0"
                                                            type="button"
                                                            aria-expanded={openAccordionItems.includes(index)}
                                                            aria-controls={`collapse${index}`}
                                                            onClick={() => toggleAccordionItem(index)}
                                                        >
                                                            <div className="w-50 d-flex justify-content-start align-items-center">
                                                                <span className="fs-20 fw-500 pe-3">{index + 1}</span>
                                                                <span className="pe-3">
                                                                    <img src="assets/img/email-icon.png" alt="" />
                                                                </span>
                                                                <div>
                                                                    <p className="fs-14 mb-0 mx-2 fw-700 fc-black">Automatic</p>
                                                                </div>
                                                                <select
                                                                    className={`form-select ${styles.customDropdown}`}
                                                                    value={step.channel || sequence.channel || ""}
                                                                    onChange={(e) => {
                                                                        const newSteps = [...steps];
                                                                        newSteps[index].channel = e.target.value;
                                                                        setSteps(newSteps);
                                                                    }}
                                                                    required
                                                                >
                                                                    <option value="">Select Channel</option>
                                                                    <option value="email">Email</option>
                                                                    <option value="sms">SMS</option>
                                                                    <option value="whatsapp">WhatsApp</option>
                                                                    <option value="linkedin">LinkedIn</option>
                                                                    <option value="twitter">X (Twitter)</option>
                                                                </select>
                                                            </div>

                                                            <div className="w-50 d-flex justify-content-end align-items-center">
                                                                <select
                                                                    className={`form-select ${styles.customDropdown}`}
                                                                    value={step.day}
                                                                    onChange={(e) => {
                                                                        const newSteps = [...steps];
                                                                        newSteps[index].day = Number(e.target.value);
                                                                        setSteps(newSteps);
                                                                    }}
                                                                    required
                                                                >
                                                                    {Array.from({ length: 31 }, (_, i) => (
                                                                        <option key={i + 1} value={i + 1}>
                                                                            Day {i + 1}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <p className="tc-979797 fs-12 mb-0 mx-2 ">
                                                                    {/* Day {step.day} - */}
                                                                    Outreach
                                                                </p>
                                                            </div>

                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse${index}`}
                                                        className={`accordion-collapse collapse ${openAccordionItems.includes(index) ? 'show' : ''}`}
                                                        aria-labelledby={`heading${index}`}
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="w-100">
                                                                <div className="mb-2">
                                                                    <label className="form-label fs-14">Subject</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={step.subject}
                                                                        onChange={(e) => handleInputChange(index, "subject", e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="mb-2">
                                                                    <label className="form-label fs-14">Message</label>
                                                                    <ReactQuill
                                                                        theme="snow"
                                                                        value={step.body}
                                                                        onChange={(content) => handleInputChange(index, "body", content)}
                                                                        placeholder="Write message here..."
                                                                        modules={{
                                                                            toolbar: [
                                                                                [{ header: [1, 2, 3, false] }],
                                                                                ["bold", "italic", "underline", "strike"],
                                                                                [{ color: [] }, { background: [] }],
                                                                                [{ list: "ordered" }, { list: "bullet" }],
                                                                                ["link", "image"],
                                                                                ["clean"],
                                                                            ],
                                                                        }}
                                                                        formats={[
                                                                            "header",
                                                                            "bold",
                                                                            "italic",
                                                                            "underline",
                                                                            "strike",
                                                                            "list",
                                                                            "bullet",
                                                                            "link",
                                                                            "image",
                                                                            "color",
                                                                            "background",
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}



                                    </div>



                                    {/* Add Sequence Button */}
                                    <div className="text-center mt-3 mb-3">
                                        <button
                                            className="btn btn-outline-primary rounded-circle"
                                            onClick={addSequence}
                                            title="Add Sequence"
                                        >
                                            +
                                        </button>
                                        <button
                                            className="btn btn-outline-danger rounded-circle"
                                            onClick={deleteLastSequence}
                                            title="Delete Last Sequence"
                                            style={{ marginLeft: "10px" }}
                                        >
                                            -
                                        </button>
                                    </div>

                                </div>

                                <div class="modal-footer">


                                    <div className="text-center mt-1">
                                        <button className="btn border rounded-3 fs-14 w-200 p-2 my-2 me-2"

                                            onClick={handleEditInformation}>
                                            Edit my information
                                        </button>
                                        <button
                                            className="btn btn-primary rounded-3 fs-14 w-200 p-2 my-2"
                                            onClick={handleSaveSequence}
                                            disabled={loading}
                                        >
                                            {/* {loading ? "Saving..." : "Save Sequence"} */}
                                            {loading ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <RotatingLines
                                                        strokeColor="white"
                                                        strokeWidth="5"
                                                        animationDuration="0.75"
                                                        width="20"
                                                        visible={true}
                                                    />
                                                    <span style={{ marginLeft: '5px' }}>Saving...</span>
                                                </div>
                                            ) : "Save Sequences"}

                                        </button>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* )} */}
        </>
    );
};

export default FinalizeContent;
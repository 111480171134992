// src/components/MultiLineChart.js
 

import React, { useEffect, useRef } from 'react'; 
import { Chart, registerables } from 'chart.js/auto';

// Register Chart.js components
Chart.register(...registerables);

const BarChart = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const ctx = canvasRef.current.getContext('2d');

        const myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['1 Dec', '2 Dec', '3 Dec', '4 Dec', '5 Dec', '6 Dec', '7 Dec'],
                datasets: [
                    {
                        label: 'Sent',
                        data: [12, 19, 3, 5, 2, 3, 7],
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        fill: true,
                        borderWidth: 2,
                        pointStyle: 'circle',
                        pointRadius: 4,
                        pointHoverRadius: 5,
                        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                        pointBorderColor: 'rgba(255, 99, 132, 1)',
                        tension: 0.4,
                    },
                    {
                        label: 'Total opens',
                        data: [8, 11, 5, 8, 6, 4, 5],
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        fill: true,
                        borderWidth: 2,
                        pointStyle: 'circle',
                        pointRadius: 4,
                        pointHoverRadius: 5,
                        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                        pointBorderColor: 'rgba(54, 162, 235, 1)',
                        tension: 0.4,
                    },
                    // {
                    //     label: 'Unique opens',
                    //     data: [4, 3, 9, 6, 8, 9, 11],
                    //     borderColor: 'rgba(255, 206, 86, 1)',
                    //     backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    //     fill: true,
                    //     borderWidth: 2,
                    //     pointStyle: 'circle',
                    //     pointRadius: 4,
                    //     pointHoverRadius: 5,
                    //     pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                    //     pointBorderColor: 'rgba(255, 206, 86, 1)',
                    //     tension: 0.4,
                    // },
                    {
                        label: 'Total replies',
                        data: [7, 11, 5, 10, 12, 8, 9],
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        fill: true,
                        borderWidth: 2,
                        pointStyle: 'circle',
                        pointRadius: 4,
                        pointHoverRadius: 5,
                        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                        pointBorderColor: 'rgba(75, 192, 192, 1)',
                        tension: 0.4,
                    },
                    {
                        label: 'Total clicks',
                        data: [6, 8, 5, 7, 3, 5, 8],
                        borderColor: 'rgba(153, 102, 255, 1)',
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        fill: true,
                        borderWidth: 2,
                        pointStyle: 'circle',
                        pointRadius: 4,
                        pointHoverRadius: 5,
                        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                        pointBorderColor: 'rgba(153, 102, 255, 1)',
                        tension: 0.4,
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                animations: {
                    radius: {
                        duration: 400,
                        easing: 'linear',
                        loop: (context) => context.active
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 20,
                            boxHeight: 2,
                            padding: 10,
                            font: {
                                size: 14,
                                weight: 'bold',
                            },
                            color: '#333',
                        },
                    },
                    tooltip: {
                        callbacks: {
                            title: function (tooltipItems) {
                                return 'Data Points';
                            },
                            label: function (tooltipItem) {
                                const datasetLabel = tooltipItem.dataset.label || '';
                                const value = tooltipItem.raw;
                                return `${datasetLabel}: ${value}`;
                            },
                            afterLabel: function (tooltipItem) {
                                const datasetLabels = tooltipItem.chart.data.datasets.map((dataset, index) => {
                                    const value = tooltipItem.chart.data.datasets[index].data[tooltipItem.dataIndex];
                                    return `${dataset.label}: ${value}`;
                                });
                                return datasetLabels;
                            }
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            color: 'rgba(0, 0, 0, .1)',
                            lineWidth: 1,
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)',
                            lineWidth: 1,
                        }
                    }
                },
            }
        });

        // Cleanup function to remove the chart when the component unmounts
        return () => {
            myChart.destroy();
        };
    }, []);

    return (
        <div style={{ height: '316px', width: '100%' }}>
            <canvas
                ref={canvasRef}
                id="myChart"
                style={{ height: '100%', width: '100%' }}
            />
        </div>
    );
};
 

export default BarChart;

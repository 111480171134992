import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { CampaignContactDataSave } from '../../../services/authFunctions';

const CampaignData = ({ onImportSuccess }) => {
    const { campaignId } = useParams(); // Getting campaignId from URL params
    const [csvData, setCsvData] = useState([]); // Store parsed CSV data
    const [filteredData, setFilteredData] = useState([]); // Store filtered data based on campaign_id
    const [loading, setLoading] = useState(false); // Loading state for CSV parsing and API requests
    const [error, setError] = useState(null); // Error state for file parsing or API requests
    const [successMessage, setSuccessMessage] = useState(null); // State for success message
    const [buttonText, setButtonText] = useState('Import'); // State for the button text
    const fileInputRef = useRef(null); // Create a reference for the file input

    // Handle CSV file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLoading(true);
            setError(null);
            setSuccessMessage(null); // Reset success message when a new file is uploaded
            setButtonText('Uploading...'); // Change the button text to 'Uploading...'

            // Use PapaParse to parse the uploaded CSV file
            Papa.parse(file, {
                complete: (result) => {
                    setCsvData(result.data); // Store parsed data
                    setFilteredData(result.data); // Initially show all data
                    setLoading(false);

                    // Automatically upload the data after the file is parsed
                    handleUploadDataToAPI(file);
                },
                header: true, // Assume the first row contains headers
                skipEmptyLines: true, // Skip empty lines
            });
        }
    };

    // Send CSV data to the API using the imported function
    const handleUploadDataToAPI = async (file) => {
        if (!file) {
            setError("No file to upload.");
            setLoading(false);
            setButtonText('Import'); // Reset button text if no file is uploaded
            return;
        }

        setLoading(true);
        setError(null);

        // Prepare the payload to send to the API (FormData)
        const formData = new FormData();
        formData.append('import_file', file); // Add the file to the FormData
        formData.append('campaign_id', campaignId); // Add campaign_id to the form data

        try {
            const response = await CampaignContactDataSave(formData); // Call the CampaignContactDataSave function
            console.log('Data uploaded successfully:', response);

            // Show a browser alert after successful upload
            alert('Data uploaded successfully!'); // This will show a pop-up alert

            // Optionally, you can also display a success message on the UI
            setSuccessMessage('Data uploaded successfully!'); // Set success message
            setButtonText('Import'); // Reset button text after upload is successful
            onImportSuccess();
        } catch (err) {
            console.error('Error occurred:', err.message);
            setError(err.message); // Display error to the user
            setButtonText('Import'); // Reset button text if there was an error
        } finally {
            setLoading(false); // End loading
        }
    };

    // Fetch campaign details using campaignId from URL
    useEffect(() => {
        const fetchCampaign = async () => {
            if (!campaignId) {
                setError('Campaign ID is missing');
                setLoading(false);
                return;
            }

            try {
                const campaignsData = await CampaignData(campaignId);

                if (Array.isArray(campaignsData)) {
                    const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                    if (filteredCampaign) {
                        console.log('Campaign data fetched:', filteredCampaign);
                    } else {
                        setError('Campaign not found');
                    }
                } else {
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaign data:", err);
                setError('Failed to fetch campaign data');
                setLoading(false);
            }
        };

        fetchCampaign();
    }, [campaignId]); // Only re-fetch when campaignId changes

    // Filter data based on campaign_id
    const filterDataByCampaign = () => {
        if (!campaignId) {
            setFilteredData(csvData); // If no campaign_id, show all data
        } else {
            const filtered = csvData.filter(item => item.campaign_id === campaignId);
            setFilteredData(filtered); // Show data that matches the campaign_id
        }
    };

    // Trigger filtering when campaign_id or csvData changes
    useEffect(() => {
        filterDataByCampaign();
    }, [campaignId, csvData]);

    // Trigger file input click when the custom button is clicked
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the file input click
        }
    };


    return (
        <div>
            

            {/* Custom Button to Trigger File Upload */}
            <button
                type="button"
                className="btn btn-primary border mx-3 fs-14"
                id="addNewMember"
                onClick={handleButtonClick}
                disabled={loading}
            >
                {buttonText}
            </button>

            {/* Hidden file input */}
            <input
                type="file"
                accept=".csv"
                ref={fileInputRef} // Connect the input to the reference
                onChange={handleFileUpload}
                style={{ display: 'none' }} // Hide the actual file input
            />

            {/* Display loading or error messages 
            {loading && <p>Loading CSV data...</p>} 
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {/* Show filtered data in a table 
            {filteredData.length > 0 && !loading && (
                <table border="1">
                    <thead>
                        <tr>
                             
                            {Object.keys(filteredData[0]).map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((row, index) => (
                            <tr key={index}>
                                 
                                {Object.values(row).map((value, index) => (
                                    <td key={index}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            */}
            {/* If no data is found 
            {filteredData.length === 0 && !loading && <p>No data available for this Campaign ID.</p>} */}
        </div>
    );
};

export default CampaignData;

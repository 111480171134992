import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const EmailVerificationSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState("Verifying email...");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const email = params.get("email");
        const verified = params.get("verified");

        if (email && verified === "true") {
            setMessage("Email successfully verified!");
            setTimeout(() => {
                navigate("http://localhost:3000/campaigns");  
            }, 2000);
        } else {
            setMessage("Email verification failed.");
        }
    }, [location, navigate]);

    return (
        <div className="text-center">
            <h2>{message}</h2>
        </div>
    );
};

export default EmailVerificationSuccess;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const api_url = process.env.REACT_APP_URL;

export const fetchCampaignData = createAsyncThunk(
    'campaignDetailsPage/fetchCampaignData',
    async (campaignId, { rejectWithValue }) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `${api_url}/api/campaign-details`,
                { campaign_id: campaignId },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            if (response.data.success) {
                console.log("call from details page slice");
                 console.log("API Response Data:", response.data.details); // Added a console log to see if we have data in details
                return response.data.details;
            } else {
                return rejectWithValue(response.data.error || 'Failed to fetch campaigns');
            }
        } catch (err) {
            return rejectWithValue(err.response ? err.response.data.error : err.message || 'Failed to fetch campaigns');
        }
    }
);

const initialState = {
    campaign: null,
    loading: false,
    error: null,
};

const campaignDetailsPageSlice = createSlice({
    name: 'campaignDetailsPage',
    initialState,
    reducers: {
        resetState: (state) => {
          state.campaign = null;
          state.loading = false;
           state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaignData.pending, (state) => {
                state.loading = true;
                 state.error = null;
            })
            .addCase(fetchCampaignData.fulfilled, (state, action) => {
                state.loading = false;
                state.campaign = action.payload[0]; // Assuming you need the first campaign details object
                   state.error = null;
            })
            .addCase(fetchCampaignData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { resetState } = campaignDetailsPageSlice.actions;
export default campaignDetailsPageSlice.reducer;
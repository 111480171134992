import React, { useState, useEffect } from "react";
import Header from "../../common/Header.js";
import PageHeader from "./Header.js";
import { gsuiteDataShowdata } from '../../services/authFunctions';

const EmailAccounts = () => {
    // State to hold email data
    const [emailAccounts, setEmailAccounts] = useState([]);

    // Fetch email accounts data
    useEffect(() => {
        const fetchEmailAccounts = async () => {
            const data = await gsuiteDataShowdata(); // Fetch the data from API
            console.log("Fetched data:", data); // Log the response to see its structure

            // Check if GsuiteAccount exists and is an array
            if (data && data.GsuiteAccount && Array.isArray(data.GsuiteAccount)) {
                setEmailAccounts(data.GsuiteAccount); // Set the email accounts if the array is present
            } else {
                console.error("Data fetched is not in expected format:", data);
                setEmailAccounts([]); // Fallback to empty array if data is not in expected format
            }
        };

        fetchEmailAccounts();
    }, []);

    return (
        <div className="w-100 d-flex">
            <Header />
            <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                <PageHeader />
                <div className="px-4 ff-inter py-3">
                    <table className="table table-striped dataTable overflow-visible w-100">
                        <thead>
                            <tr>
                                <th><input className="form-check-input me-1" type="checkbox" value="" /></th>
                                <th>Email</th>
                                <th>Emails sent</th>
                                <th>Warmup emails</th>
                                <th>Health score</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailAccounts.length > 0 ? (
                                emailAccounts.map((account) => (
                                    <tr key={account.id}>
                                        <td><input className="form-check-input me-1" type="checkbox" value="" /></td>
                                        <td className="custom-pointer">{account.email}</td>
                                        <td className="custom-pointer">0 of 30</td>
                                        <td className="custom-pointer">72</td>
                                        <td className="custom-pointer">100%</td>
                                        <td>
                                            <img src="assets/img/svg/ata-icon-5.svg" width="24" alt="" />
                                            {account.is_active === 1 ? (
                                                <span className="badge bg-success">Verified Email</span>
                                            ) : (
                                                <span
                                                    className="badge bg-warn custom-pointer"
                                                    // onClick={() => {
                                                    //     const openURLnewtab = `${window.API_URL}/api/gsuite/auth/${account.email}`;
                                                    //     window.open(openURLnewtab, '_blank');
                                                    // }}
                                                    // onClick={() => {
                                                    //     const openURLnewwindow = `${window.API_URL}/api/gsuite/auth/${account.email}`;
                                                    //     const newWindow = window.open(
                                                    //         openURLnewwindow,
                                                    //         'authWindow',
                                                    //         'width=600,height=600,left=100,top=100'
                                                    //     );

                                                    //     const interval = setInterval(() => {
                                                    //         if (newWindow && newWindow.closed) {
                                                    //             clearInterval(interval);
                                                    //             window.location.reload(); 
                                                    //         }
                                                    //     }, 1000);

                                                    // }}

                                                    onClick={() => {
                                                        const authUrl = `${window.API_URL}/api/gsuite/auth/${account.email}`;
                                                        
                                                        // Open URL in the same tab
                                                        window.location.href = authUrl;
                                                    }}
                                                    

                                                    // onClick={() => {
                                                    //     const authUrl = `${window.API_URL}/api/gsuite/auth/${account.email}`;
                                                    //     const authWindow = window.open(authUrl, 'authWindow', 'width=600,height=600,left=100,top=100');

                                                    //     if (!authWindow) {
                                                    //         alert("Popup blocked! Please allow popups and try again.");
                                                    //         return;
                                                    //     }

                                                    //     // Listen for the message from the authentication window
                                                    //     window.addEventListener("message", (event) => {
                                                    //         // Ensure the message is from the expected window
                                                    //         if (event.origin !== window.API_URL) {
                                                    //             return; // Ignore messages from untrusted origins
                                                    //         }

                                                    //         // Check for the success message from the authWindow
                                                    //         if (event.data === "success") {
                                                    //             console.log("Authentication complete.");
                                                    //             // Close the auth window once authentication is complete
                                                    //             authWindow.close();
                                                    //             // Optionally reload the page or update the UI
                                                    //             window.location.reload();
                                                    //         }
                                                    //     });

                                                    //     const checkWindowClosed = setInterval(() => {
                                                    //         if (authWindow.closed) {
                                                    //             console.log("Authentication window closed manually. Reloading the page...");
                                                    //             clearInterval(checkWindowClosed);
                                                    //             window.location.reload();
                                                    //         }
                                                    //     }, 1000);
                                                    // }}

                                                >
                                                    Unverified Email
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EmailAccounts;

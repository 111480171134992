import { all } from 'axios';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import upArrow from "../../../assets/img/up_arrow.png"
import downArrow from "../../../assets/img/down_arrow.png"


const EmailDetailsPopup = ({ isOpen, onClose, popupData, popupEmail }) => {

    console.log(popupData, "popupdata")
    console.log(popupEmail, "popupEmail")
    const [openSections, setOpenSections] = useState({});
    const handleToggle = (index) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    if (!isOpen || !popupData) return null;

    const getPlainText = (htmlString) => {
        if (!htmlString) return 'N/A';
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || "N/A";
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleDateString('en-US');
    };

    const formatTime = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    // Remove duplicate entries while preserving order
    const uniqueEntries = new Set();
    const allConversation = [...popupData.allEmails]

        .flatMap(email => {
            const entries = [];

            if (email.processed_emails) {
                if (!uniqueEntries.has(`processed_email-${email.processed_emails.created_at}`)) {
                    uniqueEntries.add(`processed_email-${email.processed_emails.created_at}`);
                    entries.push({ type: 'processed_email', ...email.processed_emails });
                }

                if (email.processed_emails.response_sent &&
                    !uniqueEntries.has(`ai_response-${email.processed_emails.updated_at}`)) {
                    uniqueEntries.add(`ai_response-${email.processed_emails.updated_at}`);
                    entries.push({
                        type: 'ai_response',
                        created_at: email.processed_emails.updated_at,
                        response_sent: email.processed_emails.response_sent,
                        email_body: email.processed_emails.email_body,
                    });
                }
            }

            if (email.dnd_logs) {
                if (!uniqueEntries.has(`dnd_log-${email.dnd_logs.created_at}`)) {
                    uniqueEntries.add(`dnd_log-${email.dnd_logs.created_at}`);
                    entries.push({ type: 'dnd_log', ...email.dnd_logs });
                }
            }

            return entries;
        })
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    const hasResponses = allConversation.length > 0;

    return (
        <Modal show={isOpen} onHide={onClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{popupEmail}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ position: 'relative', maxHeight: '400px', overflow: 'auto' }}>
                <table className="table mb-0">
                    <thead>
                        <tr className="table-light">
                            <th>Recipient</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Sentiment</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hasResponses ? (
                            allConversation.map((item, index) => {
                                if (item.type === 'processed_email') {
                                    // Find the corresponding email in popupData.allEmails
                                    const emailItem = popupData.allEmails.find(email => email.processed_emails === item);
                                    // Check if emailItem exists and has ooo_logs
                                    const hasOooLog = emailItem?.ooo_logs;
                                    const sentiment = hasOooLog ? 'Out of Office' : item.sentiment || 'N/A';

                                    console.log('emailItem:', emailItem);
                                    console.log('hasOooLog:', hasOooLog);
                                    console.log('sentiment:', sentiment);

                                    return (
                                        <React.Fragment key={`${index}-${item.created_at}`}>
                                            <tr>
                                                <td>{popupEmail}</td>
                                                <td>{formatDate(item.created_at)}</td>
                                                <td>{formatTime(item.created_at)}</td>
                                                <td><span className="badge bg-success">Received</span></td>
                                                <td><span className="badge bg-success">{sentiment}</span></td>
                                                <td>
                                                    <button className="btn btn-sm"
                                                        onClick={() => handleToggle(index)}>
                                                        {/* {openSections[index] ? '↓' : '↑'} */}
                                                        {openSections[index] ? (
                                                            <img src={downArrow} alt="downarrow" />
                                                        ) : (
                                                            <img src={upArrow} alt="uparrow" />

                                                        )}
                                                    </button>
                                                </td>
                                            </tr>
                                            {item.email_body && openSections[index] && (
                                                <tr>
                                                    <td colSpan="6" className="bg-light">
                                                        <strong>Message:</strong>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.email_body ? item.email_body.replace(/\n/g, "<br>") : "N/A"
                                                            }}
                                                        ></div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.email_body || "N/A" }}></div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                if (item.type === 'ai_response') {
                                    return (
                                        <React.Fragment key={`${index}-${item.created_at}`}>
                                            <tr>
                                                <td>AI Response</td>
                                                <td>{formatDate(item.created_at)}</td>
                                                <td>{formatTime(item.created_at)}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>
                                                    <button className="btn btn-sm"
                                                        onClick={() => handleToggle(index + 'ai')}>
                                                        {/* {openSections[index + 'ai'] ? '↓' : '↑'} */}
                                                        {openSections[index + 'ai'] ? (
                                                            <img src={downArrow} alt="downarrow" />
                                                        ) : (
                                                            <img src={upArrow} alt="uparrow" />

                                                        )}
                                                    </button>
                                                </td>
                                            </tr>
                                            {item.response_sent && openSections[index + 'ai'] && (
                                                <tr>
                                                    <td colSpan="6" className="bg-light">
                                                        <strong>AI Response:</strong>
                                                        <div dangerouslySetInnerHTML={{ __html: item.response_sent || "N/A" }}></div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                if (item.type === 'dnd_log') {
                                    return (
                                        <React.Fragment key={`${index}-${item.created_at}`}>
                                            <tr>
                                                <td>{popupEmail}</td>
                                                <td>{formatDate(item.created_at)}</td>
                                                <td>{formatTime(item.created_at)}</td>
                                                <td><span className="badge bg-danger">DND</span></td>
                                                <td>DND</td>
                                                <td>
                                                    <button className="btn btn-sm"
                                                        onClick={() => handleToggle(index + 'dnd')}>
                                                        {openSections[index + 'dnd'] ? '↓' : '↑'}
                                                    </button>
                                                </td>
                                            </tr>
                                            {item.email_body && openSections[index + 'dnd'] && (
                                                <tr>
                                                    <td colSpan="6" className="bg-light">
                                                        <strong>Message:</strong>
                                                        <div dangerouslySetInnerHTML={{ __html: item.email_body || "N/A" }}></div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">No response data found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={onClose}>Close</button>
            </Modal.Footer>
        </Modal>
    );
};

export default EmailDetailsPopup;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, clearError, clearMessage, setErrors, setMessage } from '../features/authSlice'; // Import the register action
import eyeIcon from "../assets/img/eye_icon.png";
import eyecloseIcon from "../assets/img/eye-close-icon.png";

const RegisterForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, message, errors } = useSelector((state) => state.auth); // Get auth state from redux

    const [registerData, setRegisterData] = useState({
        name: '',
        email: '',
        password: '',
        c_password: '',
        agreeToTerms: false,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);    

    // Handle changes to the input fields
    const handleRegisterChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setRegisterData({ ...registerData, [e.target.name]: value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Handle the form submission for registration
    const handleSignupSubmit = async (e) => {
        e.preventDefault();

        console.log("registerData",registerData)
        // if (registerData.password !== registerData.c_password) {
        //     dispatch(setErrors({ c_password: 'Passwords do not match.' }));
        //     return;
        // }

        // // Check if user agreed to terms
        // if (!registerData.agreeToTerms) {
        //     dispatch(setMessage('Please agree to the Terms of Use and Privacy Policy'));
        //     return;
        // }

        // dispatch(registerUser(registerData));
    };

    useEffect(() => {
        if (message === 'Registration Successful!') {
            navigate('/dashboard')
        }
    }, [message, navigate])

    return (
        <div className="p-3 p-md-4">

            {/* {message && (
                    <div className={`alert ${message.includes('Success') ? 'alert-success' : 'alert-danger'}`}>
                        {message}
                    </div>
                )} */}

            <form className="row g-3" onSubmit={handleSignupSubmit}>
                <div className="col-md-12 col-12">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={registerData.name}
                        onChange={handleRegisterChange}
                        required
                    />
                </div>
                {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                )}
                <div className="col-12 mb-2">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        name="email"
                        value={registerData.email}
                        onChange={handleRegisterChange}
                        required
                    />
                </div>
                {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                )}
                {/* <div className="col-12 mb-2">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={registerData.password}
                        onChange={handleRegisterChange}
                        required
                    />
                    <span className="position-absolute top-50 translate-middle-y cursor-pointer " onClick={togglePasswordVisibility}>
                        <img src={showPassword ? eyeIcon : eyecloseIcon} alt="Toggle Password Visibility" width="20" />
                    </span>
                </div> */}

                <div className="col-12 mb-2 position-relative">
                    <input
                        type={showPassword ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Password"
                        name="password"
                        value={registerData.password}
                        onChange={handleRegisterChange}
                        required
                    />
                    <span
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        onClick={togglePasswordVisibility}
                    >
                        <img src={showPassword ? eyeIcon : eyecloseIcon} alt="Toggle Password Visibility" width="20" />
                    </span>
                </div>

                {errors.password && (
                    <div className="text-danger">{errors.password}</div>
                )}
                <div className="col-12 mb-2 position-relative">
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control pe-5"
                        placeholder="Confirm Password"
                        name="c_password"
                        value={registerData.c_password}
                        onChange={handleRegisterChange}
                        required
                    />
                     <span
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        onClick={toggleConfirmPasswordVisibility}
                    >
                        <img src={showConfirmPassword ? eyeIcon : eyecloseIcon} alt="Toggle Password Visibility" width="20" />
                    </span>
                    {errors.c_password && (
                        <div className="text-danger">{errors.c_password}</div>
                    )}
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="agreeTerms"
                            name="agreeToTerms"
                            checked={registerData.agreeToTerms}
                            onChange={handleRegisterChange}
                        />
                        <label className="form-check-label fs-12 tc-111111" htmlFor="agreeTerms">
                            I agree to the Instantly <a href="/terms" className="text-dark">Terms of Use</a> and{' '}
                            <a href="/privacy" className="text-dark">Privacy policy</a>
                        </label>
                    </div>
                </div>
                <div className="col-12">
                    <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={loading}
                    >
                        {loading ? 'Signing up...' : 'Sign Up'}
                    </button>
                </div>
            </form>
            <div className="position-relative my-4 py-1">
                <hr />
                <span className="bg-white position-absolute top-50 start-50 translate-middle px-3 pt-0 fs-14 tc-111111">
                    or
                </span>
            </div>

            <button className="btn text-center border p-2 text-center w-100 fs-14 mb-3">
                <img src="./assets/img/svg/google.svg" className="me-2" alt="Google" />
                Log in with Google
            </button>
            <button className="btn text-center border p-2 text-center w-100 fs-14 mb-3">
                <img src="./assets/img/svg/microsoft.svg" className="me-2" alt="Microsoft" />
                Log in with Microsoft
            </button>
        </div>
    );
};

export default RegisterForm;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchSelectedEmails } from "./selectedEmailSlice";

const api_url = process.env.REACT_APP_URL;

export const addCampaignAccounts = createAsyncThunk(
    "email/addCampaignAccounts",
    async ({ campaign_id, gsuite_account_ids }, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `${api_url}/api/campaign-accounts`,
                {
                    campaign_id, 
                    gsuite_account_ids,
                    delay_send: 1,
                    limit: 30
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("API Response:", response.data);

            if (response.data?.status) {
                thunkAPI.dispatch(fetchSelectedEmails(campaign_id));
            }

            return response.data;
        } catch (error) {
            console.error("API Error:", error.response?.data || "Unknown error");
            return thunkAPI.rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

const addEmailSlice = createSlice({
    name: "email",
    initialState: {
        emailData: [],
        loading: false,
        error: null,
        addEmailStatus: null,
    },
    reducers: {
        clearAddEmailStatus: (state) => {
            console.log("Clearing addStatus");
            state.addEmailStatus = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addCampaignAccounts.pending, (state) => {
                console.log("API Request Pending");
                state.addEmailStatus = "loading";
            })
            .addCase(addCampaignAccounts.fulfilled, (state, action) => {
                console.log("API Fulfilled:", action.payload);
                if (action.payload?.status) {
                    state.addEmailStatus = "success";
                    console.log("addStatus", state.addEmailStatus)

                } else {
                    state.addEmailStatus = "failed";
                }
            })
            .addCase(addCampaignAccounts.rejected, (state, action) => {
                console.error("API Rejected:", action.payload);
                state.addEmailStatus = "error";
                state.error = action.payload;
            });
    },
});


export const { clearAddEmailStatus } = addEmailSlice.actions;
export default addEmailSlice.reducer;

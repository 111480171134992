import { React, useState, useRef } from 'react'
import NoLeadsImg from '../../assets/img/NoLeadsIllustration.png'
import "../../../src/page/Campaigns/DetailsCommponents/styles/EmptyLeads.css";
import back_arrow from "../../assets/img/chevronleft.png";
import csv_icon from "../../assets/img/csv-icon.png";
import email_manually from "../../assets/img/mail-manually.png";
import google_logo from "../../assets/img/google-logo.png";
import { useNavigate } from 'react-router-dom';
import EmailManualModal from './DetailsCommponents/EmailManualModal';
import * as XLSX from 'xlsx';
import CSVUploadModal from './DetailsCommponents/CSVUploadModal';
import wave from "../../assets/img/icon/hand-wave.png";

const EmptyLeads = () => {

  const [showCsvLeadModal, setShowCsvLeadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showEmailManualModal, setShowEmailManualModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const accessToken = localStorage.getItem('token');
  const [sheetData, setSheetData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [showCsvUploadModal, setShowCsvUploadModal] = useState(false);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const allowedTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ];

    if (allowedTypes.includes(file.type)) {
      setSelectedFile(file);

      if (file.type === "text/csv") {
        readCsvFile(file);
        console.log("CSV file selected");
      } else {
        readExcelFile(file);
        console.log("Excel file selected");
      }

      setShowCsvLeadModal(false);
    } else {
      alert('Please upload a valid CSV, XLS, or XLSX file.');
    }
  };

  const processExcelData = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      console.error("Invalid or empty Excel data");
      return;
    }
    console.log("Processed Excel Data:", data);
    setExcelData(data);
  };

  const readCsvFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      processCsvData(text);
    };
    reader.readAsText(file);
  };

  const readExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet);
      console.log("Excel Data:", jsonData);

      processExcelData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const processCsvData = (csvText) => {
    const rows = csvText.split('\n').map((row) => row.split(','));
    setCsvData(rows);
    console.log('Parsed CSV Data:', rows);
  };

  const handleCsvClick = () => {
    setShowCsvUploadModal(true);
    // setShowCsvLeadModal(false)
  };

  const handleDivClick = () => {
    fileInputRef.current?.click();
  };

  const handleEmailManuallyClick = () => {
    setShowEmailManualModal(true);
  };

  const handleAddEmail = () => {
    if (email.trim() !== "") {
      setEmailList([...emailList, email]);
      setEmail("");
    }
  };

  const fetchGoogleSheets = async () => {
    if (!accessToken) return alert("Please sign in first!");

    const sheetId = "YOUR_SPREADSHEET_ID";
    const range = "Sheet1!A1:Z10";

    try {
      const response = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setSheetData(data.values || []);
    } catch (error) {
      console.error("Error fetching Google Sheets data:", error);
    }
  };

  return (
    <>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center align-items-center flex-column p-4  ">
                <img src={NoLeadsImg} alt="" />
                <div className='mt-4'>
                  <p className='fw-bold'><img src={wave} alt="hand-wave"/> Add Some Leads To get started</p>
                </div>
                <div>
                  <button
                    onClick={() => setShowCsvLeadModal(true)}
                    className="btn bg-primary mt-3"
                    style={{ color: "white" }}
                  >
                    + Add Leads
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showCsvLeadModal && (
          <div className="custom-modal-overlay">
            <div className="csv-lead-modal">
              <button className="back-button" onClick={() => setShowCsvLeadModal(false)}>
                <img src={back_arrow} alt='back' className='icon' /> <span className='fc-747474 fw-600 fs-14'>Back</span>
              </button>
              <div className="modal-content">
                <div className="option" onClick={handleCsvClick}>
                  <img src={csv_icon} alt="CSV" className="option-icon" />
                  <div className="justify-content-center flex-column option-border">
                    <p className="fs-14 m-0 fc-747474">Upload</p>
                    <p className="fs-24 fw-700 fc-black m-0">CSV</p>
                  </div>
                  <input
                    type="file"
                    accept=".csv ,.xls, .xlsx"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>

                <div className="option" onClick={handleEmailManuallyClick}>
                  <img src={email_manually} alt="Email" />
                  <div className='d-flex justify-content-center flex-column option-border'>
                    <p className='fs-14 m-0 fc-747474'>Enter</p>
                    <p className='fs-24 fw-700 fc-black m-0'>Email Manually</p>
                  </div>
                </div>
                <div className="option" onClick={fetchGoogleSheets}>
                  <img src={google_logo} alt="Google Sheets" />
                  <div className='justify-content-center flex-column option-border'>
                    <p className='fs-14 m-0 fc-747474'>Use</p>
                    <p className='fs-24 fw-700 fc-black m-0'>Google Sheets</p>
                  </div>
                </div>

                {sheetData.length > 0 && (
                  <table border="1">
                    <tbody>
                      {sheetData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, colIndex) => (
                            <td key={colIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        )}

        {showCsvUploadModal && (
          <div className="upload-section">
            <CSVUploadModal show={showCsvUploadModal} handleClose={() => setShowCsvUploadModal(false)}/>
          </div>
        )}

        {showEmailManualModal && (
          <EmailManualModal show={showEmailManualModal} handleClose={() => setShowEmailManualModal(false)} />
          
        )}
      </div>
    </>
  )
}

export default EmptyLeads;
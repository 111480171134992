import axios from 'axios';

// Use environment variables for dynamic base URL 
// woring on method 1
// method 1 // 
const API_URL = 'https://emailcamp.flowzdigital.com';

export const fetchData = async (endpoint) => {
    try {
        const token = localStorage.getItem('token');
        // console.log('Token:', token); // Log token to check if it's present

        const response = await axios.get(`${API_URL}/${endpoint}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

       // console.log('API Response:', response); // Log the full API response

        return response.data;
    } catch (error) {
        console.error('API Error', error);
        throw error;
    }
};

export const postData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem('token');  
        const headers = token ? {
            'Authorization': `Bearer ${token}`,   
            'Content-Type': 'application/json',
        } : { 'Content-Type': 'application/json' };  

        const response = await axios.post(`${API_URL}/${endpoint}`, data, { headers }); 
        console.log("API Full Response:", response.data);  
         
        return response.data;
    } catch (error) {
        console.error('API Error:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;   
    }
};

export const putData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem('token');
        const headers = token ? {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        } : { 'Content-Type': 'application/json' };

        const requestUrl = `${API_URL}/${endpoint}`;
        console.log("Request URL:", requestUrl); // Log the request URL

        // Send PUT request
        const response = await axios.put(requestUrl, data, { headers });
        console.log("Response from putData:", response.data); // Log the response data

        return response.data; // Ensure this is being returned
    } catch (error) {
        console.error('API Error:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error; // Rethrow error to propagate it
    }
};


export const importData = async (endpoint, data) => {
    try {
        const token = localStorage.getItem('token');

        // Prepare headers
        const headers = token ? {
            'Authorization': `Bearer ${token}`,
        } : {};

        // If the data is FormData (i.e., file upload), don't set 'Content-Type'
        if (data instanceof FormData) {
            // For FormData, we let the browser set the appropriate 'Content-Type' header
            delete headers['Content-Type'];
        } else {
            headers['Content-Type'] = 'application/json';
        }

        // Send the request with the appropriate headers
        const response = await axios.post(`${API_URL}/${endpoint}`, data, { headers });

        console.log("API Full Response:", response.data);  // Log the full API response for debugging
        return response.data;  // Return the response data
    } catch (error) {
        console.error('API Error:', error.response || error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;   // Propagate the error to be handled by the caller
    }
};

/*
export const postData = async (endpoint, data) => {
    try {
        // method 1 // 
        const response = await axios.post(`${API_URL}/${endpoint}`, data);
        // method 2 // 	const response = await api.post(endpoint,  data);
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};

/*
export const fetchData = async (endpoint) => {
    try {
        const response = await axiosInstance.get(endpoint);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('API Error', error);
        throw error;
    }
};
*/


// method 2 //
/*
const api = axios.create({
    baseURL: 'http://localhost:3001', // API URL
    timeout: 10000, // timeout in milliseconds
    headers: {
        'Content-type': 'application/Json',
    }
})
*/
// Create an axios instance with default headers
/*
const axiosInstance = axios.create({
    baseURL: API_URL,  // Set the base URL for all requests
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('api_token')}`, // Get the token from localStorage or context
        'Content-Type': 'application/json',
    }
});
*/

/*
// General function to handle responses and errors
const handleResponse = (response) => {
    console.log('API Response:', response); // Log the full response for debugging
    return response.data;
};

const handleError = (error) => {
    // Check for network errors
    if (error.message === 'Network Error') {
        console.error('Network Error: Please check your API server or network connection.');
    }

    // Log the detailed error
    console.error('API Error:', error);
    if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
    }

    throw error.response ? error.response.data : error.message;
};

// Authentication functions using axios.post directly
export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/`, {
            email,
            password,
        });
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
};

export const register = async (userData) => {
    try {
        const response = await axios.post(`${API_URL}/register`, userData);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
};

 
 
export const resetPassword = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/password/reset`, data);
        return handleResponse(response);
    } catch (error) {
        return handleError(error);
    }
};
*/
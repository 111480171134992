import React from 'react'; 
import CountIndicator from './CountIndicator'; 

const Summary_card = ({ summaryTitlte, backgroundColor, tooltipCodes, countForNumber, count, simbol, showCountIndicator, className, showInfoImg }) => {
    const location = window.location.origin;
   
    return (
        <> 
            <div className={`rounded-3 p-3 d-inline-block p-4 w-100 ${className}`} style={{ backgroundColor }} >
                <span className="fw-500 fs-14">
                    {summaryTitlte}
                    {showInfoImg ? (
                        <>
                            <img src={location+'/assets/img/svg/info-icon.svg'} className="ms-2" alt="info" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-html="true" title={tooltipCodes} style={{ cursor: 'pointer' }} />
                        </>
                    ) : ('') }
                </span>
                <h5 className="fw-500 h2 mt-2">{ countForNumber }</h5>
                <div className="fs-12 tc-111111">
                    {showCountIndicator ? (
                        <>
                            <CountIndicator count={count} simbol={simbol} className="fs-14" /> Compared to last week
                        </>
                    ) : ('') } 

                </div>
            </div>

        </>
    );
}

export default Summary_card;

import React from 'react';

const ItemsPerPageSelect = ({ itemsPerPage, setItemsPerPage, className }) => {
    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
    };

    return (
        <>
            {/*   <label htmlFor="itemsPerPage" className="me-2">Rows per page:</label> */}
            <select
                id="itemsPerPage"
                className="form-select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className={className}
            >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
            </select>
        </>
    );
};

export default ItemsPerPageSelect;

import React, { useState } from "react";
import searchIcon from "../assets/img/Icon.png";
import './styles/SearchBar.css';

const SearchBar = ({ onSearchChange, placeholder = "Search..." }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  // const [placeholder, setPlaceholder] = useState("Search...");

  const handleSearchChange = (event) => {
    const value = event.target.value || "";
    setSearchQuery(value);
    onSearchChange(value);
  };


  const handleFocus = () => {
    setIsFocused(true);
    // setPlaceholder("");
  };

  const handleBlur = () => {
    setIsFocused(false);
    // setPlaceholder("Search..."); 
  };

  const clearSearch = () => {
    setSearchQuery("");
    onSearchChange("");
  };

  return (
    <div style={{ position: 'relative', marginRight: '10px' }}>
      {/* <div> */}
      {/* <img
        src={searchIcon}
        alt="Search"
        className={`search-icon ${isFocused ? 'focused' : ''}`}
        style={{ position: 'absolute', left: '10px', width: '20px', height: '20px' }}
      /> */}
      <input
        type="text"
        // className="bg-F5F8FF border-0 w-100 p-3 rounded-2 search-input"
        className="border w-100 rounded-2 search-input mb-0"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleSearchChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {searchQuery && (
        <span
          className="position-absolute end-0 top-50 translate-middle-y me-3 text-muted"
          style={{ cursor: "pointer", fontSize: "18px" }}
          onClick={clearSearch}
        >
          ×
        </span>
      )}

    </div>
  );
};

export default SearchBar;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const api_url = process.env.REACT_APP_URL;
const token = localStorage.getItem('token'); 

export const fetchEmailAccounts = createAsyncThunk("email/fetchEmailAccounts", async () => {
     const response = await fetch(`${api_url}/api/email-index`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`, 
            "Content-Type": "application/json",
        },
    });
    if (!response.ok) throw new Error("Failed to fetch email accounts");

    const data = await response.json();

    return data.GsuiteAccount.map(account => ({
        id: account.id, 
        email: account.email,
        status: account.is_active === 1 ? "Active" : "Inactive",
        created_at: account.created_at,
        updated_at: account.updated_at
    }));
});

const emailSlice = createSlice({
    name: "email",
    initialState: {
        emailData: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmailAccounts.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchEmailAccounts.fulfilled, (state, action) => {
                state.loading = false;
                state.emailData = action.payload;
            })
            .addCase(fetchEmailAccounts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default emailSlice.reducer;



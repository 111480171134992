import { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import back_arrow from "../../../assets/img/chevronleft.png"

const EmailManualModal = ({ show, handleClose }) => {
  const [emails, setEmails] = useState("");

  const handleImportEmails = () => {
    console.log("Emails Imported:", emails);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered className="email-manual-modal">
      <Modal.Body className="p-4">
        <div className="d-flex align-items-center mb-3">
          <img
            src={back_arrow}
            alt="Back"
            className="me-2"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
          <span className="text-primary" style={{ cursor: "pointer" }} onClick={handleClose}>
            Choose another method
          </span>
        </div>

        <h5 className="fw-bold">Bulk Insert Manually</h5>

        <div className="p-3 bg-light rounded mb-3">
          <p className="mb-1">
            To add emails with name, you can use one of the following formats:
          </p>
          <pre className="mb-0">
            "John Doe" &lt;john@doe.com&gt; <br />
            "Jane Smith" jane@smith.com
          </pre>
        </div>

        <Form.Group className="mb-3">
          <Form.Label>Type or paste email addresses (one email per line)</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Add your emails here"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
          />
        </Form.Group>

        <Row className="mb-3">
          <Col>
            <Form.Check type="checkbox" label="Check for duplicates across all" defaultChecked />
          </Col>
          <Col>
            <Form.Check type="checkbox" label="Campaigns" defaultChecked />
          </Col>
          <Col>
            <Form.Check type="checkbox" label="Lists" defaultChecked />
          </Col>
          <Col>
            <Form.Check type="checkbox" label="The Workspace" defaultChecked />
          </Col>
        </Row>

        
        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" onClick={handleImportEmails}>
            Import emails
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmailManualModal;

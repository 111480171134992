import React from "react";  
import Summarycard from "../../../common/Summary_card";
import BarChart from "../../../common/BarChart";
import TabAnalytics from "./TabDetailsAnalytics";
import TabLeads from "./TabDetailsLeads";
import DetailsTabSequences from "./TabDetailsSequences";
import DetailsTabSchedule from "./TabDetailsSchedule";
import SendEmails from "../Email-send";
import CampaignDataImport from "./DataImport"; 
import CampaignButton from "./CampaignButton";
import CampaignseSeandEmailData from "./CampaignseSeandEmailDetails";

const DetailsTab = ({ emailIds = [] }) => {
   // console.log('DetailsTab received emailIds:', emailIds);
    return (  
        <div className="px-4 ff-inter py-3">
            <div className="d-flex w-100 justify-content-between border-bottom align-items-center ">
                <ul className="nav nav-tabs text-center border-0" id="myTab" role="tablist">
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link active border-0 border-bottom text-dark fs-14 tc-111111 p-3 d-inline-bolck" id="analytics-tab" data-bs-toggle="tab" href="#analytics" role="tab" aria-controls="analytics" aria-selected="true">
                            Analytics
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="leads-tab" data-bs-toggle="tab" href="#leads" role="tab" aria-controls="leads" aria-selected="false">
                            Leads
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="sequences-tab" data-bs-toggle="tab" href="#sequences" role="tab" aria-controls="sequences" aria-selected="false">
                            Sequences
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="schedule-tab" data-bs-toggle="tab" href="#schedule" role="tab" aria-controls="schedule" aria-selected="false">
                            Schedule
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="options-tab" data-bs-toggle="tab" href="#options" role="tab" aria-controls="options" aria-selected="false">
                            Details
                        </a>
                    </li>
                    <li className="nav-item  " role="presentation">
                        <a className="nav-link border-0 border-bottom  text-dark fs-14 tc-111111 p-3 d-inline-bolck " id="subsequences-tab" data-bs-toggle="tab" href="#subsequences" role="tab" aria-controls="subsequences" aria-selected="false">
                            Subsequences
                        </a>
                    </li>
                </ul>
                {/* Pass emailIds to CampaignButtonStart */}
                 
                    <CampaignButton emailIds={emailIds} />
                 
                
                
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                        <TabAnalytics />
                </div>
                <div className="tab-pane fade" id="leads" role="tabpanel" aria-labelledby="leads-tab">
                    <div className="p-3 p-md-4">
                        <TabLeads />
                    </div>
                </div>
                <div className="tab-pane fade" id="sequences" role="tabpanel" aria-labelledby="sequences-tab">
                    <div className="p-3 p-md-4">
                        <DetailsTabSequences />
                    </div>
                </div>
                <div className="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
                    <div className="p-3 p-md-4">
                        <DetailsTabSchedule />
                    </div>
                </div>
                <div className="tab-pane fade" id="options" role="tabpanel" aria-labelledby="options-tab">
                    <div className="p-3 p-md-4">
                        {/* <SendEmails/>*/}   Details tab
                        {/*<CampaignseSeandEmailData></CampaignseSeandEmailData> */}
                    </div>
                </div>
                <div className="tab-pane fade" id="subsequences" role="tabpanel" aria-labelledby="subsequences-tab">
                    <div className="p-3 p-md-4">
                        {/* <CampaignDataImport/> */}    subsequences tab
                    </div>
                </div>
            </div> 
        </div>
  );
};

export default DetailsTab;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmailAccounts } from "../../../../src/features/emailSlice";
import { addCampaignAccounts, clearAddEmailStatus } from "../../../../src/features/addEmailSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/TabOptionStyle.css";
import search_icon from "../../../assets/img/Search.png";
import { useParams } from 'react-router-dom';

const EmailSelectionModal = ({ showAddEmailModal, setShowAddEmailModal, selectedEmails, setSelectedEmails }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const dispatch = useDispatch();
    const { campaignId } = useParams();

    const { emailData, loading, error, addStatus } = useSelector((state) => state.email);

    const { addEmailStatus } = useSelector((state) => state.addEmail);
    const { addedEmails } = useSelector(state => state.selectedEmail);

    useEffect(() => {
        if (showAddEmailModal) {
            dispatch(fetchEmailAccounts());
        }
    }, [showAddEmailModal, dispatch]);

    useEffect(() => {
        if (addEmailStatus === "success") {
            setShowAddEmailModal(false);
            dispatch(clearAddEmailStatus());
        }
    }, [addEmailStatus, setShowAddEmailModal, setSelectedEmails, dispatch]);


    const toggleEmailSelection = (emailObj) => {
        setSelectedEmails((prev) =>
            prev.some((e) => e.id === emailObj.id)
                ? prev.filter((e) => e.id !== emailObj.id)
                : [...prev, emailObj]
        );
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedEmails(emailData.map((item) => ({ email: item.email, id: item.id })));
        } else {
            setSelectedEmails([]);
        }
    };

    const closeModal = () => {
        setShowAddEmailModal(false);
    };

    const handleAdd = () => {
        if (selectedEmails.length === 0) {
            alert("Please select at least one email.");
            return;
        }
        const gsuite_account_ids = selectedEmails.map((email) => email.id);
        console.log("gsuite_account_ids", gsuite_account_ids)
        dispatch(addCampaignAccounts({ campaign_id: campaignId, gsuite_account_ids }));
    };

    return (
        <div className={`modal ${showAddEmailModal ? "d-block" : "d-none"} custom-modal`} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-body mt-3">
                        <div className="search-container mb-2">
                            <input
                                type="text"
                                className="form-control custom-input"
                                placeholder="Search Email Address"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <img src={search_icon} alt="Search" className="search-icon" />
                        </div>

                        {loading && <p>Loading...</p>}
                        {error && <p style={{ color: "red" }}>Error: {error}</p>}

                        {!loading && !error && (
                            <div className="table-responsive" style={{ maxHeight: "400px", overflowY: "auto" }}>
                                <table className="table table-borderless">
                                    <thead style={{ borderBottom: "1px solid #E1E5E8" }}>
                                        <tr>
                                            <th style={{ fontWeight: "normal" }}>
                                                <input
                                                    type="checkbox"
                                                    onChange={handleSelectAll}
                                                    checked={selectedEmails.length === emailData.length && emailData.length > 0}
                                                /> Email Account
                                            </th>
                                            <th style={{ fontWeight: "normal", textAlign: "end" }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {emailData
                                            .filter((item) => item.email.toLowerCase().includes(searchTerm.toLowerCase()))
                                            .map((item) => {
                                                const isSelected = selectedEmails.some(email => email.id === item.id);
                                                const isAlreadyAdded = addedEmails.some(email => email.id === item.id);
                                                return (
                                                    <tr key={item.email}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                // checked={selectedEmails.includes(item.id)}
                                                                // onChange={() => toggleEmailSelection(item.email)}
                                                                checked={isSelected || isAlreadyAdded}
                                                                // checked={selectedEmails.some(email => email.id === item.id)}
                                                                onChange={() => toggleEmailSelection({ email: item.email, id: item.id })}
                                                            />{" "}
                                                            {item.email}
                                                        </td>
                                                        <td style={{ color: getStatusColor(item.status), textAlign: "end" }}>
                                                            {item.status}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )
                                        }
                                    </tbody>                                
                                </table>
                            </div>
                        )}
                    </div>

                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={handleAdd}>
                            Add
                        </button>
                        <button className="btn btn-secondary" onClick={closeModal}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const getStatusColor = (status) => {
    return status === "Active" ? "green" : "red";
};

export default EmailSelectionModal;

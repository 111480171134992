import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_URL;

const initialState = {
    campaignData: {
      company_name: "TechCorp",
      pain_points: "High software costs and complexity",
      value_proposition: "Affordable and easy-to-use software",
      call_to_action: "Get started today!",
      company_overview: "TechCorp provides innovative software solutions.",
      additional_context: "Additional",
      target_audience: "Startups and small businesses",
      tone: "Friendly and engaging",
    },
    sequences: [{ channel: "email", delay: "100" }, { channel: "sms", delay: "160" }, { channel: "linkedin", delay: "100" }],
    channelContent: [], 
    loading: false,
    error: null,
    isSuccess: false,
  };


export const generateEmailContent = createAsyncThunk(
    'campaignForm/generateEmailContent',
    async (_, { getState, rejectWithValue }) => {
      try {
        const { campaignData, sequences } = getState().campaignForm;
        const sequencePayload = {
          companyOrProductName: campaignData.company_name,
          customerPainPoints: campaignData.pain_points,
          valueProposition: campaignData.value_proposition,
          companyOverview: campaignData.company_overview,
          subjectLine: "Save Big on Software!",
          targetAudience: campaignData.target_audience,
          tone: campaignData.tone,
          callToAction: campaignData.call_to_action,
          keyContent: "Try our software for free for 30 days!",
          temperature: 0.7,
          contentTypes: sequences.map(seq => ({
            type: seq.channel.toLowerCase() === 'linkedIn' ? 'LinkedIn' : seq.channel,
            maxTokens: parseInt(seq.delay, 10),
          })),
        };
  
        console.log("Payload being sent:", sequencePayload);
        const jsonPayload = JSON.stringify(sequencePayload);
        console.log("JSON Payload:", jsonPayload);
  
        const url = `${API_URL}/api/generate-openai-email`;
  
        const token = localStorage.getItem('token');
  
        const response = await axios({
          method: 'POST',
          url: url,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: sequencePayload,
        });
  
        console.log("Axios Response:", response);
  
        const generatedEmails = response?.data; 
        console.log(generatedEmails,"generated email content");

        if (!response?.data) {
            return rejectWithValue("API returned null or undefined data.");
          }
    
          if (typeof response?.data !== 'object' || response?.data === null) {
            return rejectWithValue("API did not return an object.");
          }
  
        // Rearrange data by channel, maintaining sequence order
        
        // const rearrangedData = sequences.map(sequence => {
        //     const channel = sequence.channel;
        //     const subject = response.data.subject || ""; 
        //     const body = response.data.body || "";
           
        //     return { channel, subject, body};
        //   });
  
        // console.log("Rearranged Data:", rearrangedData);
  
        return generatedEmails; 
      } catch (error) {
        console.error("Axios Error:", error);
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
  );


const openAiCampaignFormSlice = createSlice({
    name: 'openAiCampaignFormSlice',
    initialState,
    reducers: {
      setCampaignData: (state, action) => {
        state.campaignData = { ...state.campaignData, ...action.payload };
      },
      setSequences: (state, action) => {
        state.sequences = action.payload;
      },
      handleAddSequence: (state) => {
        state.sequences.push({ channel: "", delay: "" });
      },
      handleRemoveSequence: (state, action) => {
        if (state.sequences.length > 1) {
          state.sequences = state.sequences.filter((_, i) => i !== action.payload);
        } else {
          alert("You must have at least one sequence.");
        }
      },
      // handleChange: (state, action) => {
      //   const { index, field, value } = action.payload;
      //   state.sequences = state.sequences.map((seq, i) =>
      //     i === index ? { ...seq, [field]: value } : seq
      //   );
      // },
      handleChange: (state, action) => {
        const { index, field, value } = action.payload;
        state.sequences = state.sequences.map((seq, i) =>
          i === index ? { ...seq, [field]: value } : seq
        );
      },
      
      resetCampaignForm: (state) => {
        state.campaignData = initialState.campaignData;
        state.sequences = initialState.sequences;
        state.channelContent = [];
        state.loading = false;
        state.error = null;
        state.isSuccess = false;
      },
      clearError: (state) => {
        state.error = null; 
      },
      setIsSuccessFalse: (state) => {  
        state.isSuccess = false
      },
      clearChannelContent: (state) => { 
        state.channelContent = [];
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(generateEmailContent.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.isSuccess = false;
        })
        .addCase(generateEmailContent.fulfilled, (state, action) => {
          state.loading = false;
          state.channelContent = action.payload;
          state.isSuccess = true;
        })
        .addCase(generateEmailContent.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
          state.isSuccess = false;
        });
    },
  });

export const {
  setCampaignData,
  setSequences,
  handleAddSequence,
  handleRemoveSequence,
  handleChange,
  resetCampaignForm,
  isSuccess,
  error,
  clearChannelContent,
  clearError,
  setIsSuccessFalse
  
} = openAiCampaignFormSlice.actions;

// Selector to access the channelContent (rearranged data)
export const selectChannelContent = (state) => state.openAiCampaignFormSlice.channelContent;


export default openAiCampaignFormSlice.reducer;
import React from 'react';

const AccordionItem = ({ AccordionImg, AccordionTitle, index, children }) => (
    <div className="accordion-item border-0">
        <h2 className="accordion-header">
            <button
                className="accordion-button collapsed px-0 py-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="true"
                aria-controls={`collapse${index}`}
            >
                <img src={AccordionImg} className="me-2" alt="" /> {AccordionTitle}
            </button>
        </h2>
        <div id={`collapse${index}`} className="accordion-collapse collapse">
            <div className="accordion-body mxh-320 overflow-auto">
                {children}
            </div>
        </div>
    </div>
);

const Accordion = ({ items }) => {
    return (
        <div className="accordion" id="accordionExample">
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    AccordionImg={item.AccordionImg}
                    AccordionTitle={item.AccordionTitle}
                    index={index}
                >
                    {item.content}
                </AccordionItem>
            ))}
        </div>
    );
}

export default Accordion;

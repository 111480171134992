// tabOptionsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_URL;

// Async thunk for fetching campaign settings
export const fetchCampaignSettings = createAsyncThunk(
    'tabOptions/fetchCampaignSettings',
    async (campaignId, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = `${API_URL}/api/campaign-settings/${campaignId}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                const allAccounts = response.data.allAccounts;
                const modifiedAccounts = response.data.data.accounts.map(account => {
                    const fullAccount = allAccounts.find(acc => acc.id === account.id);
                    return {
                        id: account.id,
                        email: account.email,
                        smtp_username: fullAccount ? fullAccount.smtp_username : '' // Add smtp_username
                    };
                });
                return { ...response.data, modifiedAccounts };
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
);

export const saveCampaignSettings = createAsyncThunk(
    'tabOptions/saveCampaignSettings',
    async ({ campaignId, payload }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = `${API_URL}/api/campaign-settings/${campaignId}`;

            // Modify the accounts in the payload to only include IDs
            const modifiedPayload = {
                ...payload,
                accounts: payload.accounts.map(account => ({ id: account.id })), 
            };

            const response = await axios.post(apiUrl, modifiedPayload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
);

const initialState = {
    dailyLimit: 25000,
    stopOnReply: false,
    openTracking: false,
    linkTracking: false,
    textOnly: false,
    owner: "Admin",
    minTime: '00',
    randomTime: '00',
    maxLeads: 'No limit',
    textOnlyPrioritize: false,
    winningMetric: '',
    providerMatching: false,
    companyReplyStop: false,
    autoReplyStop: false,
    unsubscribeLink: false,
    riskyEmails: false,
    disableBounceProtect: false,
    ccRecipients: '',
    bccRecipients: '',
    emailAccounts: [],
    selectedAccounts: [],
    showAdvanced: false,
    tagsInput: '',
    tags: [],
    apiError: null,
    apiSuccess: false,
    loading: 'idle', // 'idle' | 'pending' | 'succeeded' | 'failed'
    allAccounts: [],
};

const tabOptionsSlice = createSlice({
    name: 'tabOptions',
    initialState,
    reducers: {
        setDailyLimit: (state, action) => {
            state.dailyLimit = action.payload;
        },
        setStopOnReply: (state, action) => {
            state.stopOnReply = action.payload;
        },
        setOpenTracking: (state, action) => {
            state.openTracking = action.payload;
        },
        setLinkTracking: (state, action) => {
            state.linkTracking = action.payload;
        },
        setTextOnly: (state, action) => {
            state.textOnly = action.payload;
        },
        setOwner: (state, action) => {
            state.owner = action.payload;
        },
        setMinTime: (state, action) => {
            state.minTime = action.payload;
        },
        setRandomTime: (state, action) => {
            state.randomTime = action.payload;
        },
        setMaxLeads: (state, action) => {
            state.maxLeads = action.payload;
        },
        setTextOnlyPrioritize: (state, action) => {
            state.textOnlyPrioritize = action.payload;
        },
        setWinningMetric: (state, action) => {
            state.winningMetric = action.payload;
        },
        setProviderMatching: (state, action) => {
            state.providerMatching = action.payload;
        },
        setCompanyReplyStop: (state, action) => {
            state.companyReplyStop = action.payload;
        },
        setAutoReplyStop: (state, action) => {
            state.autoReplyStop = action.payload;
        },
        setUnsubscribeLink: (state, action) => {
            state.unsubscribeLink = action.payload;
        },
        setRiskyEmails: (state, action) => {
            state.riskyEmails = action.payload;
        },
        setDisableBounceProtect: (state, action) => {
            state.disableBounceProtect = action.payload;
        },
        setCcRecipients: (state, action) => {
            state.ccRecipients = action.payload;
        },
        setBccRecipients: (state, action) => {
            state.bccRecipients = action.payload;
        },
        setSelectedAccounts: (state, action) => {
            state.selectedAccounts = action.payload;
        },
        setShowAdvanced: (state, action) => {
            state.showAdvanced = action.payload;
        },
        setTagsInput: (state, action) => {
            state.tagsInput = action.payload;
            state.tags = action.payload.split(',').map(tag => tag.trim());
        },
        setTags: (state, action) => {
            state.tags = action.payload;
        },
        setApiError: (state, action) => {
            state.apiError = action.payload;
        },
        setApiSuccess: (state, action) => {
            state.apiSuccess = action.payload;
        },
        resetState: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaignSettings.pending, (state) => {
                state.loading = 'pending';
                state.apiError = null;
                state.apiSuccess = false;
            })
            .addCase(fetchCampaignSettings.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                // Update state with fetched settings
                const data = action.payload?.data; // Use optional chaining

                if (data) {
                    state.dailyLimit = data.daily_limit ?? initialState.dailyLimit;
                    state.stopOnReply = data.stop_on_reply ?? initialState.stopOnReply;
                    state.openTracking = data.open_tracking ?? initialState.openTracking;
                    state.linkTracking = data.link_tracking ?? initialState.linkTracking;
                    state.textOnly = data.text_only ?? initialState.textOnly;
                    state.owner = data.owner ?? initialState.owner;
                    state.minTime = data.min_time_gap?.toString() ?? initialState.minTime;
                    state.randomTime = data.random_additional_time?.toString() ?? initialState.randomTime;
                    state.maxLeads = data.max_new_leads ?? initialState.maxLeads;
                    state.textOnlyPrioritize = data.prioritize_new_leads ?? initialState.textOnlyPrioritize;
                    state.winningMetric = data.winning_metric ?? initialState.winningMetric;
                    state.providerMatching = data.provider_matching ?? initialState.providerMatching;
                    state.companyReplyStop = data.company_reply_stop ?? initialState.companyReplyStop;
                    state.autoReplyStop = data.stop_on_auto_reply ?? initialState.autoReplyStop;
                    state.unsubscribeLink = data.unsubscribe_link_header ?? initialState.unsubscribeLink;
                    state.riskyEmails = data.enable_risky_emails ?? initialState.riskyEmails;
                    state.disableBounceProtect = data.disable_bounce_protect ?? initialState.disableBounceProtect;
                    state.ccRecipients = data.cc ?? initialState.ccRecipients;
                    state.bccRecipients = data.bcc ?? initialState.bccRecipients;
                    state.selectedAccounts = action.payload.modifiedAccounts;
                    state.tags = data.tags ?? initialState.tags;
                    state.tagsInput = data.tags ? data.tags.join(', ') : initialState.tagsInput;
                    state.allAccounts = action.payload.allAccounts ?? initialState.allAccounts;
                }
               
            })
            .addCase(fetchCampaignSettings.rejected, (state, action) => {
                state.loading = 'failed';
                state.apiError = action.payload;
            })
            .addCase(saveCampaignSettings.pending, (state) => {
                state.loading = 'pending';
                state.apiError = null;
                state.apiSuccess = false;
            })
            .addCase(saveCampaignSettings.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.apiSuccess = true;
                alert("Settings Updated.");
            })
            .addCase(saveCampaignSettings.rejected, (state, action) => {
                state.loading = 'failed';
                state.apiError = action.payload;
                alert("Setting Updation Failed, Tray Again");
            });
    },
});

export const {
    setDailyLimit,
    setStopOnReply,
    setOpenTracking,
    setLinkTracking,
    setTextOnly,
    setOwner,
    setMinTime,
    setRandomTime,
    setMaxLeads,
    setTextOnlyPrioritize,
    setWinningMetric,
    setProviderMatching,
    setCompanyReplyStop,
    setAutoReplyStop,
    setUnsubscribeLink,
    setRiskyEmails,
    setDisableBounceProtect,
    setCcRecipients,
    setBccRecipients,
    setSelectedAccounts,
    setShowAdvanced,
    setTagsInput,
    setTags,
    setApiError,
    setApiSuccess,
    resetState,
} = tabOptionsSlice.actions;

export default tabOptionsSlice.reducer;


// export default DetailsTabAnalytics;
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchAnalyticsData, setDateRange, setTimeRange, clearInitialLoad, setSelectedMetrics, resetAnalyticsState } from '../../../features/analyticsSlice';
import { useParams } from 'react-router-dom';
import SummaryCard from "../../Campaigns/DetailsCommponents/analytics data/Summary_card";
import BarChart from "../../Campaigns/DetailsCommponents/analytics data/BarChart";
import SettingsIcon from './img/settings.png'
import DOWNLOAD_ICON from './img/download_btn.png'

const DetailsTabAnalytics = () => {
    const dispatch = useDispatch();
    const { data, loading, error, startDate, endDate, selectedTimeRange, initialLoad, selectedMetrics } = useSelector((state) => state.analytics);
    const { campaignId } = useParams();

    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [showCustomDateModal, setShowCustomDateModal] = useState(false);
    const [isApplyingCustomDates, setIsApplyingCustomDates] = useState(false);

    useEffect(() => {
        if (campaignId) {
            dispatch(fetchAnalyticsData({ campaignId, startDate, endDate }));
        }
      
    }, [ campaignId, startDate, endDate]);

    useEffect(() => {
        if (initialLoad && campaignId) {
            handleTimeRangeChange('All Data');
            dispatch(clearInitialLoad());
           
                dispatch(resetAnalyticsState());

        }
      
    }, [dispatch, campaignId, initialLoad]);

    const handleTimeRangeChange = (range) => {
        if (range === 'Custom') {
            dispatch(setTimeRange(range));
            setShowCustomDateModal(true); // Open the modal
            return;
        }

        let start, end;
        const today = new Date();

        switch (range) {
            case 'Yesterday':
                start = new Date(today);
                start.setDate(today.getDate() - 1);
                end = new Date(today);
                end.setDate(today.getDate() - 1);
                break;
            case 'This Week':
                start = new Date(today);
                start.setDate(today.getDate() - today.getDay());
                end = today;
                break;
            case 'This Month':
                start = new Date(today.getFullYear(), today.getMonth(), 1);
                end = today;
                break;
            case 'Last Month':
                start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                end = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case 'Year to Date':
                start = new Date(today.getFullYear(), 0, 1);
                end = today;
                break;
            case 'Last 12 Months':
                start = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
                end = today;
                break;
            case 'All Data':
                start = null;
                end = null;
                break;
            default:
                start = null;
                end = null;
                break;
        }

        const formatDate = (date) => {
            if (!date) return null;
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const formattedStartDate = formatDate(start);
        const formattedEndDate = formatDate(end);

        dispatch(setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate }));
        dispatch(setTimeRange(range));
    };

    const handleCustomDateChange = async () => {
        
        const formatDate = (date) => {
            if (!date) return null;
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        
        const formattedStartDate = customStartDate ? formatDate(new Date(customStartDate)) : null;
        const formattedEndDate = customEndDate ? formatDate(new Date(customEndDate)) : null;
        
        dispatch(setTimeRange('Custom'));
        dispatch(setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate }));
        setIsApplyingCustomDates(true); // Disable the Apply button and show loading state

        // Wait for a short delay to allow the data to load
        await new Promise(resolve => setTimeout(resolve, 500)); // Adjust the delay as needed

        setShowCustomDateModal(false); // Close the modal after applying
        setIsApplyingCustomDates(false); // Re-enable the Apply button
    };

    const handleSettingsChange = (metric, isChecked) => {
        let updatedMetrics = [...selectedMetrics];
        if (isChecked) {
            updatedMetrics.push(metric);
        } else {
            updatedMetrics = updatedMetrics.filter(m => m !== metric);
        }
        dispatch(setSelectedMetrics(updatedMetrics));
    };

    const metricsOptions = [
        { label: 'Emails Sent', value: 'emails_sent' },
        { label: 'Opened Emails', value: 'opened_emails' },
        { label: 'Clicks', value: 'clicks' },
        { label: 'Replies', value: 'replies' },
        { label: 'Booked Calls', value: 'booked_calls' },
        { label: 'Bounces', value: 'total_bounces' },
        { label: 'Positive Replies', value: 'positive_replies' },
        { label: 'Negative Replies', value: 'negative_replies' },
        { label: 'Neutral Replies', value: 'neutral_replies' },
        { label: 'DND', value: 'dnd' },
        { label: 'Out of office', value: 'total_ooo' },

    ];

    const handleCloseModal = () => {
        setShowCustomDateModal(false);
    };
    
    return (
        <div className="px-4 ff-inter py-3">
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="row">
                <div className="col-12 d-flex mb-3">
                    <div className="me-auto">
                        Status:
                        <span className="text-white py-1 px-3 rounded-pill bg-74C4FF d-inline-block mw-110 text-center fs-14 mx-3">{data.status.label}</span>
                        <div className="d-inline-block" style={{ width: "100px" }} >
                            <div className=" fs-12">{data.status.percentage}</div>
                            <div className="progress" style={{ height: " 6px" }} >
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: `${parseInt(data.status.percentage)}%` }} aria-valuenow={data.status.percentage} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button type="button" className="btn border me-3 fs-14"><img src="assets/img/svg/download.svg" alt="" /> {<img src={DOWNLOAD_ICON} />} </button>

                        <div className="dropdown me-2">
                            <button
                                className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2 h-100"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="text-start text-truncate w-200 px-1" id="timeDropdown">{selectedTimeRange}</div>
                            </button>
                            <ul className="dropdown-menu fs-14" aria-labelledby="timeDropdown">
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('All Data')}>All Data</button></li>
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('This Week')}> This Week</button></li>
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('Yesterday')}>  Yesterday</button></li>
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('This Month')}>This Month</button></li>
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('Last Month')}> Last Month</button></li>
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('Year to Date')}>Year to Date</button></li>
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('Last 12 Months')}>  Last 12 Month</button></li>
                                <li><button className="dropdown-item" type="button" onClick={() => handleTimeRangeChange('Custom')}> Custom</button></li>
                            </ul>
                        </div>
                        <div className="dropdown me-3">
                            <button className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2 " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="text-start text-truncate" id="statusDropdown">
                                    <img src={SettingsIcon} alt="Settings"></img>
                                </div>
                            </button>
                            <ul className="dropdown-menu fs-14 px-2" aria-labelledby="statusDropdown">
                                {metricsOptions.map(metric => (
                                    <li key={metric.value}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectedMetrics.includes(metric.value)}
                                                onChange={(e) => handleSettingsChange(metric.value, e.target.checked)}
                                            />
                                            {metric.label}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row g-3 row-cols-1 row-cols-md-3 row-cols-lg-5 mb-3 mb-mb-4 mb-lg-5">
                {data.summaryCards
                    .filter(card => selectedMetrics.includes(card.metricKey))
                    .map((card, index) => (
                        <div className="cols" key={index}>
                            <SummaryCard
                                summaryTitlte={card.title}
                                tooltipCodes={card.tooltipCodes}
                                countForNumber={card.countForNumber}
                                count={card.count}
                                simbol={true}
                                showCountIndicator={card.title !== "Emails Sent"}
                                showInfoImg={true}
                                className="border"
                                backgroundColor={card.bgColor}
                            />
                        </div>
                    ))}
            </div>

            <div className="row  mb-3 mb-mb-4 mb-lg-5">
                <div className="col-12 col-md-12">
                    <div className="bg-F5F8FF rounded-4 p-3">
                        <BarChart barChartData={data.barChartData}></BarChart>
                    </div>
                </div>
            </div>
            {showCustomDateModal && (
                <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Custom Date Range</h5>
                                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="customStartDate" className="form-label">Start Date:</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="customStartDate"
                                            value={customStartDate || ''}
                                            onChange={(e) => setCustomStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="customEndDate" className="form-label">End Date:</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="customEndDate"
                                            value={customEndDate || ''}
                                            onChange={(e) => setCustomEndDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleCustomDateChange}
                                    disabled={isApplyingCustomDates}
                                >
                                    {isApplyingCustomDates ? 'Applying...' : 'Apply'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailsTabAnalytics;
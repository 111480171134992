import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from "../../../common/SearchBar.js";
import { Form, InputGroup } from "react-bootstrap";
import Search from "../../../assets/img/Icon.png";
import HorizontalAccordion from '../../../common/HorizontalAccordion.jsx';
import {
    fetchCampaignDetails,
    openPopup,
    closePopup,
    stopAiReplyAction,
    resumeAiReplyAction,
    setSearchQuery,
    setSelectedFilters
} from '../../../features/campaignDetailsSlice';
import EmailDetailsPopup from './EmailDetailsPopup';
import CampaignDataTable from './CampaignDataTable';
import "./styles/SendEmailDetails.css";
import EmptyDetails from '../EmptyDetails.jsx';
import multi_user from "../../../assets/img/multiuser.png";
import user_correct from "../../../assets/img/usercorrect.png";
import open_email from "../../../assets/img/emailopen.png";
import thumbsup from "../../../assets/img/thumbsup.png";
import brokenheart from "../../../assets/img/brokenheart.png";
import sad from "../../../assets/img/sad.png";
import app_icon from "../../../assets/img/apps.png";

const status = [
    { title: "Email Open", data: "email_open", isVisible: false },
    { title: "Link Clicked", data: "link_clicked", isVisible: false },
    { title: "Replied", data: "replied", isVisible: false },
    { title: "Contacted", data: "contacted", isVisible: false },
    { title: "Interested", data: "interested", isVisible: false },
    { title: "Meeting Booked", data: "meeting_booked", isVisible: false },
    { title: "Meeting Completed | not closed", data: "meeting_completed_not_closed", isVisible: false },
    { title: "Won", data: "won", isVisible: false },
    { title: "Out of Office", data: "out_of_office", isVisible: false },
    { title: "Wrong Person", data: "wrong_person", isVisible: false },
    { title: "Not Interested", data: "not_interested", isVisible: false },
    { title: "Lost", data: "lost", isVisible: false },
    { title: "Do not Contact", data: "do_not_contact", isVisible: false },
    { title: "Unsubscribed", data: "unsubscribed", isVisible: false },
    { title: "Bounced", data: "bounced", isVisible: false },
];

const CampaignSendEmailData = () => {
    const { activeTab } = useSelector((state) => state.detailsTab);
    const [dndData, setDndData] = useState({ dndMatchedKeyword: null, dndCreatedDate: null });
    const [oooData, setOooData] = useState({ oooMatchedKeyword: null });
    console.log(oooData, 'ooolog data present')
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedValue, setSelectedValue] = useState(10);
    const [visibleRows, setVisibleRows] = useState(selectedValue);

    const { campaignId } = useParams();
    const dispatch = useDispatch();
    const {
        contacts,
        loading,
        error,
        popupData,
        isPopupOpen,
        popupEmail,
        stoppingAi,
        resumingAi,
        searchQuery,
        selectedFilters
    } = useSelector((state) => state.campaignDetails);

    useEffect(() => {
        if (campaignId) {
            dispatch(fetchCampaignDetails(campaignId));
        }
    }, [dispatch, campaignId]);

    const filteredContacts = contacts.filter(contact =>
        contact.recipient_email && contact.recipient_email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // const rowsToDisplay = contacts.slice(0, visibleRows);
    const rowsToDisplay = filteredContacts.slice(0, visibleRows);
    const hasMoreRows = contacts.length > visibleRows;

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleDateString('en-US');
    };

    const formatTime = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date) ? 'N/A' : date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    };

    const openPopupHandler = (emailGroup, dndMatchedKeyword = null, dndCreatedDate = null) => {
        setDndData({ dndMatchedKeyword, dndCreatedDate });
        dispatch(openPopup(emailGroup));
    };

    const closePopupHandler = () => {
        dispatch(closePopup());
    };

    const allColumns = useMemo(() => [
        { title: "", data: "checkbox", isVisible: true },
        { title: "Sr.No", data: "sr_no", isVisible: true },
        { title: "Sender Email", data: "sender_email", isVisible: true },
        { title: "Recipient Email", data: "recipient_email", isVisible: true },
        { title: "Stage", data: "stage", isVisible: true },
        { title: "Date", data: "date", isVisible: true },
        { title: "Time", data: "time", isVisible: true },
        { title: "Status", data: "status", isVisible: true },
        // { title: "Message", data: "message", isVisible: true },
        { title: "Sentiment", data: "sentiment", isVisible: true },
        { title: "View Details", data: "view_details", isVisible: true },
        { title: "Stop AI", data: "stop_ai", isVisible: true },
    ], []);

    const [selectedOptions, setSelectedOptions] = useState(allColumns.filter(col => col.isVisible).map(col => col.data));

    const handleCheckboxChange = (optionId) => {
        setSelectedOptions((prev) => {
            if (prev.includes(optionId)) {
                return prev.filter((id) => id !== optionId);
            } else {
                return [...prev, optionId];
            }
        });
    };

    const [selectedItems, setSelectedItems] = useState({ 0: [], 1: [], 2: [] });
    const [openAccordion, setOpenAccordion] = useState(null);

    // const handleSelect = (index, option, event) => {
    //     event.stopPropagation(); // Stop event propagation
    //     setSelectedItems((prev) => {
    //         const newSelections = prev[index].includes(option)
    //             ? prev[index].filter((item) => item !== option)
    //             : [...prev[index], option];
    //         return { ...prev, [index]: newSelections };
    //     });
    // };

    const handleSelect = (index, option, event) => {
        event.stopPropagation(); // Stop event propagation
        setSelectedItems((prev) => {
            const newSelections = prev[index].includes(option)
                ? prev[index].filter((item) => item !== option)
                : [...prev[index], option];
            const updatedSelectedItems = { ...prev, [index]: newSelections };

            // Directly dispatch updated filters on selection change
            const filters = {
                channel: updatedSelectedItems[0],
                status: updatedSelectedItems[1],
                sentiment: updatedSelectedItems[2],
            };
            dispatch(setSelectedFilters(filters));

            return updatedSelectedItems;
        });
    };

    const clearAll = () => {
        const filters = {
            channel: [],
            status: [],
            sentiment: [],
        };
        dispatch(setSelectedFilters(filters));
        setSelectedItems({ 0: [], 1: [], 2: [] });
        setOpenAccordion(null); // Close any open accordion
    };

    const applySelections = () => {
        const filters = {
            channel: selectedItems[0],
            status: selectedItems[1],
            sentiment: selectedItems[2],
        };
        dispatch(setSelectedFilters(filters));
        setOpenAccordion(null);
    };

    useEffect(() => {
        const filters = {
            channel: selectedItems[0],
            status: selectedItems[1],
            sentiment: selectedItems[2],
        };
        dispatch(setSelectedFilters(filters));
        setOpenAccordion(null);
    }, selectedItems)

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-danger">Error: {error}</div>;

    const getPlainTextMainTable = (htmlString) => {
        if (!htmlString) return 'N/A';
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || "N/A";
    }

    const showAlert = (message, type) => {
        const alertContainer = document.getElementById("alert-container");
        const alertDiv = document.createElement("div");
        alertDiv.classList.add("alert", `alert-${type}`, "alert-dismissible", "fade", "show", "fixed-top");
        alertDiv.setAttribute("role", "alert");
        alertDiv.innerHTML = `
            ${message}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        `;
        alertContainer.appendChild(alertDiv);
        setTimeout(() => {
            alertDiv.classList.remove("show");
            alertDiv.classList.add("fade");
            setTimeout(() => alertDiv.remove(), 500);
        }, 3000);
    };

    const handleStopAiReply = async (recipientEmail, index) => {
        dispatch(stopAiReplyAction({ recipientEmail, campaignId, index })).then(() => {
            showAlert("AI reply stopped successfully!", "success");
        }).catch((err) => {
            showAlert(err, "danger")
        })
    };

    const handleResumeAiReply = async (recipientEmail, index) => {
        console.log("recipientEmail", recipientEmail)
        dispatch(resumeAiReplyAction({ recipientEmail, campaignId, index })).then(() => {
            showAlert("AI reply resumed successfully!", "success");
        }).catch((err) => {
            showAlert(err, "danger")
        })
    };

    const handleViewMore = () => {
        const newVisibleRows = visibleRows + selectedValue;

        setVisibleRows(newVisibleRows);
        const availableOptions = [10, 25, 50, 100, 500];

        const closestOption = availableOptions.reduce((prev, curr) =>
            Math.abs(curr - newVisibleRows) < Math.abs(prev - newVisibleRows) ? curr : prev
        );

        setSelectedValue(closestOption);
        console.log("visibleRows", newVisibleRows, selectedValue)

    };

    // const handleDropdownChange = (event) => {
    //     console.log("newValue",event)
    //     const newValue = parseInt(event.target.value, 10);
    //     setSelectedValue(newValue);
    //     setVisibleRows(newValue); 
    // };
    const handleDropdownChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSelectedValue(newValue);
        setVisibleRows((prev) => (prev === newValue ? newValue + 1 : newValue));
    };


    const handleSearchChange = (query) => {
        dispatch(setSearchQuery(query));
    };

    console.log("rowsToDisplay", contacts)
    return (
        <>
            <div id="alert-container"></div>
            {contacts.length > 0 ? (
                <>
                    <div className="row ">
                        <div className="col-lg-9 col-md-6 d-flex align-items-center">
                            <p className="details-heading me-2 m-0">Details</p>
                            <span className="btn d-flex border fs-14 text-end align-items-center me-2">
                                <span className="me-4 fs-14 fc-grey"> <img src={multi_user} alt='user' />1k</span>
                                <span className="me-4 fs-14 fc-grey"> <img src={user_correct} alt='user' />1k</span>
                                <span className="me-4 fs-14 fc-grey"> <img src={open_email} alt='user' />475</span>
                                <span className="me-4 fs-14 fc-grey"> <img src={thumbsup} alt='user' />3</span>
                                <span className="me-4 fs-14 fc-grey"> <img src={brokenheart} alt='user' />7</span>
                                <span className="me-1 fs-14 fc-grey"> <img src={sad} alt='user' />48</span>
                            </span>
                            <button
                                className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2 me-2"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="text-start text-truncate w-200  " id="StatusDataDropdown">
                                    <img src={app_icon} alt='' /> All Status
                                </div>
                                <ul className="dropdown-menu fs-14 px-2" aria-labelledby="StatusDataDropdown">
                                    {status.map((column) => (
                                        <li className="mb-1" key={column.data}>
                                            <label>
                                                <input
                                                    className="me-1"
                                                    type="checkbox"
                                                    checked={selectedOptions.includes(column.data)}
                                                    onChange={() => handleCheckboxChange(column.data)}
                                                />
                                                {column.title}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </button>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div class="input-group mb-0 d-flex">

                                <SearchBar onSearchChange={handleSearchChange} placeholder="Search Email" className="me-3" />

                                {/* <span class="input-group-text" id="basic-addon1"><img src={Search}/></span>
                    <input type="text" class="form-control" placeholder="Search email" onChange={(e) => setSearchTerm(e.target.value)}  aria-label="Username" aria-describedby="basic-addon1" /> */}
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-md-12">
                            <hr />
                            <HorizontalAccordion
                                selectedOptions={selectedOptions}
                                handleCheckboxChange={handleCheckboxChange}
                                allColumns={allColumns}
                                selectedItems={selectedItems}
                                handleSelect={handleSelect}
                                openAccordion={openAccordion}
                                setOpenAccordion={setOpenAccordion}
                                applySelections={applySelections}
                                clearAll={clearAll}
                                selectedValue={selectedValue}
                                handleDropdownChange={handleDropdownChange}
                            />
                            <hr />

                        </div>
                    </div>

                    {rowsToDisplay.length > 0 ? (
                        <div className="row mt-3">
                            <div className="col-md-12">
                                {campaignId && activeTab === "details" &&
                                    <CampaignDataTable
                                        searchTerm={searchTerm}
                                        // contacts={contacts}
                                        contacts={rowsToDisplay}
                                        formatDate={formatDate}
                                        formatTime={formatTime}
                                        openPopup={openPopupHandler}
                                        handleStopAiReply={handleStopAiReply}
                                        handleResumeAiReply={handleResumeAiReply}
                                        stoppingAi={stoppingAi}
                                        resumingAi={resumingAi}
                                        getPlainTextMainTable={getPlainTextMainTable}
                                        selectedOptions={selectedOptions}
                                        allColumns={allColumns}
                                    />}
                                {hasMoreRows && (
                                    <div className="text-center mt-3">
                                        <button className="btn btn-outline-primary" onClick={handleViewMore}>
                                            View More
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ): (
                        <div style={{textAlign:"center",marginTop:"50px"}}>Search Result not Found.</div>
                    )}

                    {/* Popup */}
                    {popupData && (
                        <EmailDetailsPopup
                            isOpen={isPopupOpen}
                            onClose={closePopupHandler}
                            popupData={popupData}
                            popupEmail={popupEmail}
                            dndMatchedKeyword={dndData?.dndMatchedKeyword}
                            dndCreatedDate={dndData?.dndCreatedDate}
                        />
                    )}
                </>

            ) : (
                <EmptyDetails />
            )}


        </>
    );
};

export default CampaignSendEmailData;

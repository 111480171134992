import React, { useState, useEffect } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom'; 
import Tooltip from 'bootstrap/js/src/tooltip';
import logo from "../assets/img/Prospectly_logo.png"

const Header = () => {
    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl) => {
            return new Tooltip(tooltipTriggerEl); // Use the imported Tooltip
        });

        return () => {
            tooltipList.forEach((tooltip) => tooltip.dispose());
        };
    }, []);

    const navigate = useNavigate();
    const logout = () => {
        // Clear the token from localStorage
        // localStorage.removeItem('token'); 
        // Redirect to login page
        navigate('/logout');
    };

    const [userName, setUserName] = useState(localStorage.getItem('userName') || 'Login');
    // console.log(userName)
    const userNameSort = userName.slice(0, 2).toUpperCase(); 
    // Re-fetch the userName when the component mounts or when localStorage changes
    useEffect(() => {
        const storedUserName = localStorage.getItem('userName');
        if (storedUserName) {
            setUserName(storedUserName);
        }
    }, []);  

    const widthStyle = {
      width:'30px'
    }
    return (
        <div className="position-sticky  admin-left-menu bg-F5F8FF vh-100 h-100 overflow-x-hidden overflow-y-auto  d-flex flex-column top-0">
            <Link to="/" className="logo">
                <img src={logo} alt="" style={{background:'linear-gradient(-20deg, rgba(98,39,24,1) 0%, rgba(0,0,0,1) 100%)'}} />
                
            </Link>
            <ul className="nav flex-column mb-auto">
                <li className="nav-item">
                    <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} aria-current="page" to="/dashboard" data-bs-toggle="tooltip" data-bs-placement="right" title="Analytics">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 5V19C4 19.5523 4.44772 20 5 20H19M18 9L13 13.9999L10.5 11.4998L7 14.9998" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </NavLink>
                </li>
                {/* <li className="nav-item">
                    <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} to="/lead-manager" data-bs-toggle="tooltip" data-bs-placement="right" title="Lead Manager">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.79795 15.0999C3.75899 15.4033 3 16.363 3 17.5C3 18.8807 4.11929 20 5.5 20C6.31771 20 7.04373 19.6074 7.49985 19.0004M4.79795 15.0999C5.02067 15.0349 5.25626 15 5.5 15C6.88071 15 8 16.1193 8 17.5C8 18.063 7.8139 18.5825 7.49985 19.0004M4.79795 15.0999C4.60399 14.4336 4.5 13.7289 4.5 13C4.5 9.73406 6.58753 6.95568 9.50106 5.92639M7.49985 19.0004C8.75337 19.942 10.3115 20.5 12 20.5C13.6885 20.5 15.2466 19.942 16.5001 19.0004M9.50106 5.92639C9.50036 5.95084 9.5 5.97538 9.5 6C9.5 7.38071 10.6193 8.5 12 8.5C13.3807 8.5 14.5 7.38071 14.5 6C14.5 5.97538 14.4996 5.95084 14.4989 5.92639M9.50106 5.92639C9.54 4.57973 10.6439 3.5 12 3.5C13.3561 3.5 14.46 4.57972 14.4989 5.92639M16.5001 19.0004C16.1861 18.5825 16 18.063 16 17.5C16 16.1193 17.1193 15 18.5 15C18.7437 15 18.9793 15.0349 19.2021 15.0999M16.5001 19.0004C16.9563 19.6074 17.6823 20 18.5 20C19.8807 20 21 18.8807 21 17.5C21 16.363 20.241 15.4033 19.2021 15.0999M14.4989 5.92639C17.4125 6.95568 19.5 9.73406 19.5 13C19.5 13.7289 19.396 14.4336 19.2021 15.0999" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </NavLink>
                </li> */}
                <li className="nav-item">
                    <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} to="/email-accounts" data-bs-toggle="tooltip" data-bs-placement="right" title="Email Accounts">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.6875 6.75L11.3596 11.5403C11.7449 11.8168 12.2551 11.8168 12.6404 11.5403L19.3125 6.75M5.25 19H18.75C19.9926 19 21 17.9553 21 16.6667V7.33333C21 6.04467 19.9926 5 18.75 5H5.25C4.00736 5 3 6.04467 3 7.33333V16.6667C3 17.9553 4.00736 19 5.25 19Z" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} to="/campaigns" data-bs-toggle="tooltip" data-bs-placement="right" title="Campaigns">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 4L3 9.31372L10.5 13.5M20 4L14.5 21L10.5 13.5M20 4L10.5 13.5" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} to="/contact-books" data-bs-toggle="tooltip" data-bs-placement="right" title="Unibox">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 14L12 19L20 14M12 5L20 10L12 15L4 10L12 5Z" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </NavLink>
                </li>
            </ul>
            <hr />
            <ul className="nav flex-column  ">
                <li className="nav-item">
                    <Link className="nav-link  " aria-current="page" ot="" data-bs-toggle="tooltip" data-bs-placement="right" title="Notification">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 4C9.23858 4 7 6.23858 7 9V11.7564C7 12.604 6.40857 13.3195 5.82474 13.934C5.31363 14.472 5 15.1994 5 16C5 16.5523 5.44772 17 6 17H18C18.5523 17 19 16.5523 19 16C19 15.1994 18.6864 14.472 18.1753 13.934C17.5914 13.3195 17 12.604 17 11.7564V9C17 6.23858 14.7614 4 12 4ZM12 4V3M13.7976 19.8767C13.6312 20.2179 13.3712 20.5046 13.048 20.7035C12.7247 20.9023 12.3516 21.0051 11.9721 20.9998C11.5926 20.9945 11.2224 20.8813 10.9049 20.6735C10.5873 20.4657 10.3354 20.1718 10.1786 19.8262" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="" data-bs-toggle="tooltip" data-bs-placement="right" title="Settings">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 4C10 3.44772 10.4477 3 11 3H13C13.5523 3 14 3.44772 14 4V5.28988C14.4655 5.42843 14.9108 5.6141 15.3302 5.84137L16.2426 4.92891C16.6332 4.53838 17.2663 4.53838 17.6569 4.92891L19.0711 6.34312C19.4616 6.73365 19.4616 7.36681 19.0711 7.75734L18.1586 8.66978C18.3859 9.0892 18.5716 9.53445 18.7101 10H20C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H18.7101C18.5716 14.4655 18.3859 14.9108 18.1586 15.3302L19.0711 16.2427C19.4616 16.6332 19.4616 17.2664 19.0711 17.6569L17.6569 19.0711C17.2663 19.4616 16.6332 19.4616 16.2426 19.0711L15.3302 18.1586C14.9108 18.3859 14.4655 18.5716 14 18.7101V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V18.7101C9.53447 18.5716 9.08922 18.3859 8.6698 18.1586L7.75736 19.071C7.36684 19.4616 6.73367 19.4616 6.34315 19.071L4.92894 17.6568C4.53841 17.2663 4.53841 16.6331 4.92894 16.2426L5.84138 15.3302C5.61412 14.9108 5.42844 14.4655 5.28989 14H4C3.44772 14 3 13.5523 3 13V11C3 10.4477 3.44772 10 4 10H5.28989C5.42844 9.53447 5.61412 9.08923 5.84138 8.66982L4.92894 7.75738C4.53842 7.36686 4.53842 6.73369 4.92894 6.34317L6.34315 4.92895C6.73368 4.53843 7.36684 4.53843 7.75737 4.92895L8.6698 5.84139C9.08922 5.61411 9.53447 5.42843 10 5.28988V4Z" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3432 15 9.00002 13.6569 9.00002 12C9.00002 10.3431 10.3432 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#747474" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="" data-bs-toggle="tooltip" data-bs-placement="right" title={userName}>
                        <span className="bg-1A75FF rounded-circle d-inline-block text-white fs-14 text-center py-2 lh-1" style={widthStyle } >
                            {userNameSort}
                        </span>
                    </Link>
                </li>
                <li className="nav-item">
                    <button
                        className="nav-link btn-link"
                        onClick={logout}
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Logout"
                        style={{ background: 'none', border: 'none' }}
                    >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24px" width="24px" version="1.1"   viewBox="0 0 330 330"  > 
                                <path fill="#747474" d="M51.213,180h173.785c8.284,0,15-6.716,15-15s-6.716-15-15-15H51.213l19.394-19.393   c5.858-5.857,5.858-15.355,0-21.213c-5.856-5.858-15.354-5.858-21.213,0L4.397,154.391c-0.348,0.347-0.676,0.71-0.988,1.09   c-0.076,0.093-0.141,0.193-0.215,0.288c-0.229,0.291-0.454,0.583-0.66,0.891c-0.06,0.09-0.109,0.185-0.168,0.276   c-0.206,0.322-0.408,0.647-0.59,0.986c-0.035,0.067-0.064,0.138-0.099,0.205c-0.189,0.367-0.371,0.739-0.53,1.123   c-0.02,0.047-0.034,0.097-0.053,0.145c-0.163,0.404-0.314,0.813-0.442,1.234c-0.017,0.053-0.026,0.108-0.041,0.162   c-0.121,0.413-0.232,0.83-0.317,1.257c-0.025,0.127-0.036,0.258-0.059,0.386c-0.062,0.354-0.124,0.708-0.159,1.069   C0.025,163.998,0,164.498,0,165s0.025,1.002,0.076,1.498c0.035,0.366,0.099,0.723,0.16,1.08c0.022,0.124,0.033,0.251,0.058,0.374   c0.086,0.431,0.196,0.852,0.318,1.269c0.015,0.049,0.024,0.101,0.039,0.15c0.129,0.423,0.28,0.836,0.445,1.244   c0.018,0.044,0.031,0.091,0.05,0.135c0.16,0.387,0.343,0.761,0.534,1.13c0.033,0.065,0.061,0.133,0.095,0.198   c0.184,0.341,0.387,0.669,0.596,0.994c0.056,0.088,0.104,0.181,0.162,0.267c0.207,0.309,0.434,0.603,0.662,0.895   c0.073,0.094,0.138,0.193,0.213,0.285c0.313,0.379,0.641,0.743,0.988,1.09l44.997,44.997C52.322,223.536,56.161,225,60,225   s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L51.213,180z" />
                                <path fill="#747474" d="M207.299,42.299c-40.944,0-79.038,20.312-101.903,54.333c-4.62,6.875-2.792,16.195,4.083,20.816   c6.876,4.62,16.195,2.794,20.817-4.083c17.281-25.715,46.067-41.067,77.003-41.067C258.414,72.299,300,113.884,300,165   s-41.586,92.701-92.701,92.701c-30.845,0-59.584-15.283-76.878-40.881c-4.639-6.865-13.961-8.669-20.827-4.032   c-6.864,4.638-8.67,13.962-4.032,20.826c22.881,33.868,60.913,54.087,101.737,54.087C274.956,287.701,330,232.658,330,165   S274.956,42.299,207.299,42.299z" />
                                
                            </svg> 
                    </button>
                </li>
            </ul>
        </div>
  );
}

export default Header;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../common/Header.js";
import { CampaignData } from '../../services/authFunctions';
import DetailsHeader from './DetailsCommponents/Header.js';
import DetailsTab from './DetailsCommponents/TabDetails.js';

const CampaignDetails = () => {
    const { campaignId } = useParams(); // Get campaignId from URL
    const [campaign, setCampaign] = useState(null); // Store the single campaign object
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                // console.log("Fetching campaign data for ID:", campaignId);
                const campaignsData = await CampaignData(campaignId);
                // console.log("Fetched Campaign Data:", campaignsData);

                if (Array.isArray(campaignsData)) {
                    const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                    if (filteredCampaign) {
                        setCampaign(filteredCampaign);
                    } else {
                        setError('Campaign not found');
                    }
                } else {
                    console.error("Expected an array, but received:", campaignsData);
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaigns:", err);
                setError('Failed to fetch campaigns');
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchCampaigns();
        } else {
            setError('Campaign ID is missing');
            setLoading(false);
        }
    }, [campaignId]);

 

    return (
        <>
            <div className="w-100 d-flex">
                <Header />
                <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                    {loading ? (
                        <div className="loading-indicator">
                            <span>Loading campaign details...</span>
                            {/* You can add a spinner here */}
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <DetailsHeader CampaignsName={campaign.name} />
                            <DetailsTab />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CampaignDetails;


{/* 
            <div>
                <h1>Details for Campaign: {campaign.name}</h1>
                <p><strong>ID:</strong> {campaign.id}</p>
                <p><strong>User ID:</strong> {campaign.user_id}</p>
                <p><strong>Status:</strong> {campaign.status}</p>
                <p><strong>Scheduled At:</strong> {campaign.scheduled_at}</p>
                <p><strong>Start Date:</strong> {campaign.start_date}</p>
                <p><strong>End Date:</strong> {campaign.end_date}</p>
                <p><strong>Created At:</strong> {campaign.created_at}</p>
                <p><strong>Updated At:</strong> {campaign.updated_at}</p>

                <h3>Stages:</h3>
                {campaign.stages && campaign.stages.length > 0 ? (
                    <ul>
                        {campaign.stages.map((stage, stageIndex) => (
                            <li key={stage.id || stageIndex}>
                                <p><strong>Stage Number:</strong> {stage.stage_number}</p>
                                <p><strong>Subject:</strong> {stage.subject}</p>
                                <p><strong>Description:</strong> {stage.description}</p>
                                <p><strong>Created At:</strong> {stage.created_at}</p>
                                <p><strong>Updated At:</strong> {stage.updated_at}</p>
                                <p><strong>start date At:</strong> {stage.start_date}</p>
                                <p><strong>start time At:</strong> {stage.start_time}</p>

                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No stages available</p>
                )}
            </div>
            */}

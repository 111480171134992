// App.js

import React from 'react';
import {Routes, Route } from 'react-router-dom';
import Login from '../page/Login';
import ForgotPassword from '../page/ForgotPassword';
import Logout from '../page/Logout';
import Dashboard from '../page/Dashboard/Dashboard';
import ProtectedRoute from '../components/ProtectedRoute'; 
import ContactBooks from '../page/ContactBooks/ContactBooks';
import EmailAccounts from '../page/EmailAccounts/EmailAccounts';
import Campaigns from '../page/Campaigns/Campaigns';
import CampaignDetails from '../page/Campaigns/CampaignsDetails';
import LeadManager from '../page/LeadManager/LeadManager';

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />
        

        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contact-books" element={<ContactBooks />} />
            <Route path="/email-accounts" element={<EmailAccounts />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="/campaigns/:campaignId" element={<CampaignDetails />} />
            <Route path="/lead-manager" element={<LeadManager />} />
        </Route>
    </Routes>
);

export default AppRoutes;

import React, { useState, useEffect } from "react";
import Accordion from "../../common/Accordion";
import { ContactsData } from '../../services/authFunctions';

const ContactFilter = ({ onFiltersChange, selectedFilters, setSelectedFilters }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  
    const [localSelectedFilters, setLocalSelectedFilters] = useState(selectedFilters);

   
    useEffect(() => {
        setLocalSelectedFilters(selectedFilters);
    }, [selectedFilters]);  
    useEffect(() => {

        const featchData = async () => {
            try {
                const contacts = await ContactsData();
                const trimmedContacts = contacts.map(contact => {
                    const trimmedContact = {};
                    // Iterate over each key in the contact object
                    Object.keys(contact).forEach(key => {
                        // Check if the value is a string, and if so, trim it
                        if (typeof contact[key] === 'string') {
                            trimmedContact[key] = contact[key].trim();
                        } else {
                            // If it's not a string, just copy the value as is
                            trimmedContact[key] = contact[key];
                        }
                    });

                    return trimmedContact;
                });
                setData(trimmedContacts);
                setLoading(false); 
            } catch (error) {
                setError("Failed to feth Data");
                setLoading(false);
            }
        }
        featchData();

    }, []);

    const getUniqueValues = (field) => {
        const values = data.map(contact => contact[field]);
        return [...new Set(values)];
    };

    const handleCheckboxChange = (category, option) => {
        setLocalSelectedFilters((prev) => {
            const newSelection = new Set(prev[category]);
            if (newSelection.has(option)) {
                newSelection.delete(option);
            } else {
                newSelection.add(option);
            }
            return { ...prev, [category]: Array.from(newSelection) };
        });
    };

    const handleApplyFilters = () => {
        onFiltersChange(localSelectedFilters);
        setSelectedFilters(localSelectedFilters); 
    };

    const fields = ['city', 'country', 'gender', 'industry', 'state'];

    const filterItems = fields.map(field => ({
        title: field.charAt(0).toUpperCase() + field.slice(1),
        options: getUniqueValues(field)
    }));

    return (
        <>
             
            <Accordion
                items={filterItems.map(({ title, options }) => ({
                    AccordionTitle: title,
                    content: (
                        <>
                            {/* Filter out null, undefined, and empty options */}
                            {options
                                .filter(option => option !== null && option !== undefined && option !== '') // Filter out invalid options
                                .map((option) => (
                                    <label key={option} style={{ display: 'block' }} className="border-bottom py-1 fs-14">
                                        <input
                                            type="checkbox"
                                            checked={localSelectedFilters[title.toLowerCase()]?.includes(option) || false}
                                            onChange={() => handleCheckboxChange(title.toLowerCase(), option)}
                                            className="me-2"
                                        />
                                        {option}
                                    </label>
                                ))}
                        </>
                    ),
                }))}
            />

            <div className="d-flex justify-content-between mt-3">
                <button
                    onClick={handleApplyFilters}
                    className="btn btn-primary border fs-14 w-48 w-100"
                >
                    Apply Filters
                </button>
            </div>

            {/* Show applied filters 
            {Object.values(localSelectedFilters).some(filter => filter.length > 0) ? (
                <div>
                    <h3>Applied Filters:</h3>
                    <ul>
                        {Object.entries(localSelectedFilters).flatMap(([key, values]) =>
                            values.map(value => <li key={`${key}-${value}`}>{`${key}: ${value}`}</li>)
                        )}
                    </ul>
                </div>
            ) : (
                <p>No filters applied.</p>
            )}
            */}
        </>
    );
};

export default ContactFilter;

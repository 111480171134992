import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_URL;

export const fetchSelectedEmails = createAsyncThunk(
  'tabOptions/fetchSelectedEmails',
  async (campaignId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${API_URL}/api/campaigns/${campaignId}/accounts`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("response->",response)

      return response.data.data.map(account => ({
        id: account.gsuite_account.id,
        email: account.gsuite_account.email,
        userId : account.id,
      }));
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching emails');
    }
  }
);

const selectedEmailSlice = createSlice({
  name: 'tabOptions',
  initialState: {
    addedEmails: [],
    loading: false,
    error: null
  },
  reducers: {
    setAddedEmails: (state, action) => {
      state.addedEmails = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedEmails.pending, (state) => {
        console.log("pending")
        state.loading = true;
      })
      .addCase(fetchSelectedEmails.fulfilled, (state, action) => {
        console.log("fulfilled")

        state.loading = false;
        state.addedEmails = action.payload;
      })
      .addCase(fetchSelectedEmails.rejected, (state, action) => {
        console.log("rejected")

        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setAddedEmails } = selectedEmailSlice.actions;
export default selectedEmailSlice.reducer;

import React, { useState, useEffect } from 'react';
import DataTableComponent from '../../common/DataTableComponent';
import { ContactsData } from '../../services/authFunctions';
import SearchBar from "../../common/SearchBar.js";
import { fetchContacts, setFilters, setSelectedFilters, setSearchQuery } from '../../features/detailsTabLeadsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from "react-bootstrap";
import delete_icon from "../../assets/img/DeleteBtn.png";

const columns = [
    // { title: "Status", data: "status", isVisible: false },
    { title: "First Name", data: "first_name", isVisible: false },
    { title: "Last Name", data: "last_name", isVisible: false },
    { title: "Title", data: "title", isVisible: false },
    { title: "Company", data: "company", isVisible: false },
    { title: "Phone Number", data: "phone_number", isVisible: false },
    { title: "Email", data: "email", isVisible: false },
    { title: "City", data: "city", isVisible: false },
    { title: "State", data: "state", isVisible: false },
    { title: "Country", data: "country", isVisible: false },
    { title: "Gender", data: "gender", isVisible: false },
    { title: "Employees", data: "employees", isVisible: false },
    { title: "Industry", data: "industry", isVisible: false },
    { title: "Linkedin", data: "linkedin", isVisible: false },
    { title: "Website", data: "website", isVisible: false },
];



const ContactData = ({ filters,data, searchQuery, campaign_id }) => {

    const dispatch = useDispatch();
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([
        ...columns.map(col => col.data),
        // ...status.map(stat => stat.data)
    ]);
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [emailIds, setEmailIds] = useState([]);
    const [selectedValue, setSelectedValue] = useState(10);
    const [visibleRows, setVisibleRows] = useState(selectedValue);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeleteModalShow = () => setShowDeleteModal(true);
    const handleClose = () => setShowDeleteModal(false);
    const handleConfirmDelete = () => {
        setShowDeleteModal(false);
    };

    const rowsToDisplay = data?.slice(0, visibleRows);
    const hasMoreRows = data?.length > visibleRows;
 
    // useEffect(() => {
    //     dispatch(fetchContacts());  
    // }, [dispatch]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const contacts = await ContactsData();
    //             const trimmedContacts = contacts.map(contact => {
    //                 const trimmedContact = {};
    //                 Object.keys(contact).forEach(key => {
    //                     if (typeof contact[key] === 'string') {
    //                         trimmedContact[key] = contact[key].trim();
    //                     } else {
    //                         trimmedContact[key] = contact[key];
    //                     }
    //                 });
    //                 return trimmedContact;
    //             });
    //             setData(trimmedContacts);
    //             setLoading(false);
    //             setSelectedOptions(["first_name", "last_name", "email", "company"]);
    //         } catch (error) {
    //             setError("Failed to fetch Data");
    //             setLoading(false);
    //         }
    //     };
    //     fetchData();
    // }, []);

    // Store email IDs after filtering the data
    // useEffect(() => {
    //     if (data.length > 0) {
    //         const filteredData = filterData(data).filter((row) =>
    //             Object.keys(row).some((key) =>
    //                 String(row[key]).toLowerCase().includes(searchQuery.toLowerCase())
    //             )
    //         );
    //         const emails = filteredData.map(contact => contact.email).filter(Boolean); // Extract emails and remove any null/undefined values
    //         setEmailIds(emails);  // Store the emails in the state
    //         // Save email IDs to localStorage
    //         localStorage.setItem('emailIds', JSON.stringify(emails));
    //     }
    // }, [data, searchQuery, filters]);

    useEffect(() => {
        const storedEmails = localStorage.getItem('emailIds');
        if (storedEmails) {
            setEmailIds(JSON.parse(storedEmails));
        }
    }, []);

    const handleCheckboxChange = (optionId) => {
        setSelectedOptions((prev) => {
            if (prev.includes(optionId)) {
                return prev?.filter((id) => id !== optionId);
            } else {
                return [...prev, optionId];
            }
        });
    };

    const filterData = (data) => {
        return data?.filter((contact) => {
            if (!contact) return false;  
            const campaignIdMatch = contact.campaign_id && contact.campaign_id.toString() === campaign_id;
            const cityFilter = filters.city || [];
            const countryFilter = filters.country || [];
            const genderFilter = filters.gender || [];
            const industryFilter = filters.industry || [];
            const stateFilter = filters.state || [];
            const cityMatch = cityFilter.length === 0 || (contact.city && cityFilter.includes(contact.city));
            const countryMatch = countryFilter.length === 0 || (contact.country && countryFilter.includes(contact.country));
            const genderMatch = genderFilter.length === 0 || (contact.gender && genderFilter.includes(contact.gender));
            const industryMatch = industryFilter.length === 0 || (contact.industry && industryFilter.includes(contact.industry));
            const stateMatch = stateFilter.length === 0 || (contact.state && stateFilter.includes(contact.state));
            return campaignIdMatch && cityMatch && countryMatch && genderMatch && industryMatch && stateMatch;
        });
    };

    const filteredColumns = columns?.filter(col => selectedOptions.includes(col.data));
    // const filteredData = filterData(data).filter((row) =>
    //     Object.keys(row).some((key) =>
    //         String(row[key]).toLowerCase().includes(searchQuery.toLowerCase())
    //     )
    // );

    const handleClearFilters = () => {
        dispatch(setFilters({}));
        dispatch(setSelectedFilters({}));
    };

    const handleSearchChange = (query) => {
        dispatch(setSearchQuery(query));
    };

    const filteredData = rowsToDisplay?.filter(contact =>
        !searchQuery || (contact?.email && contact?.email.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const handleViewMore = () => {
        const newVisibleRows = visibleRows + selectedValue;
    
        setVisibleRows(newVisibleRows);

        const availableOptions = [10, 25, 50, 100, 500];

        const closestOption = availableOptions.reduce((prev, curr) => 
            Math.abs(curr - newVisibleRows) < Math.abs(prev - newVisibleRows) ? curr : prev
        );

        setSelectedValue(closestOption);
        console.log("visibleRows",newVisibleRows,selectedValue)
    };

    const handleViewMoreDropdown = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setSelectedValue(newValue);
        setVisibleRows(newValue);
    };

    console.log("data-->",data,rowsToDisplay)
    return (
        <>
            <hr />
            <div >
                <div className="dropdown me-3 pos position-relative w-auto">                    
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex align-items-start justify-content-start">
                            <SearchBar onSearchChange={handleSearchChange} placeholder="Search Email" className="me-3" />
                            {/* <button
                                className="btn d-flex border fs-14 text-end align-items-center py-2 me-2"
                                type="button"
                            >
                                <span className="me-3 fs-14 fc-grey"> <img src={multi_user} alt='user' />1k</span>
                                <span className="me-3 fs-14 fc-grey"> <img src={user_correct} alt='user' />1k</span>
                                <span className="me-3 fs-14 fc-grey"> <img src={open_email} alt='user' />475</span>
                                <span className="me-3 fs-14 fc-grey"> <img src={thumbsup} alt='user' />3</span>
                                <span className="me-3 fs-14 fc-grey"> <img src={brokenheart} alt='user' />7</span>
                                <span className="me-3 fs-14 fc-grey"> <img src={sad} alt='user' />48</span>
                            </button> */}
                        </div>

                        <div className="d-flex align-items-center">
                            {/* <button
                                className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2 me-2"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="text-start text-truncate w-200 " id="StatusDataDropdown">
                                    <img src={app_icon} alt='' /> All Status
                                </div>
                            </button>

                            <ul className="dropdown-menu fs-14 px-2" aria-labelledby="StatusDataDropdown">
                                {status.map((column) => (
                                    <li className="mb-1" key={column.data}>
                                        <label>
                                            <input
                                                className="me-1"
                                                type="checkbox"
                                                checked={selectedOptions.includes(column.data)}
                                                onChange={() => handleCheckboxChange(column.data)}
                                            />
                                            {column.title}
                                        </label>
                                    </li>
                                ))}
                            </ul> */}

                            <button
                                className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="text-start text-truncate w-200" id="ContDataDropdown">
                                    Table Structure
                                </div>
                            </button>

                            <ul className="dropdown-menu fs-14 px-3" aria-labelledby="ContDataDropdown">
                                {columns.map((column) => (
                                    <li className="mb-1" key={column.data}>
                                        <label>
                                            <input
                                                className="me-1"
                                                type="checkbox"
                                                checked={selectedOptions.includes(column.data)}
                                                onChange={() => handleCheckboxChange(column.data)}
                                            />
                                            {column.title}
                                        </label>
                                    </li>
                                ))}
                            </ul>

                            <div className="dropdown mx-2">
                                <select
                                    className="form-select"
                                    value={selectedValue}
                                    onChange={handleViewMoreDropdown}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                </select>
                            </div>

                            <div className="dropdown mx-2">
                                <button className="btn border d-flex align-items-center" onClick={handleDeleteModalShow}>
                                    <img src={delete_icon} alt='delete' />
                                </button>
                            </div>

                            <Modal show={showDeleteModal} onHide={handleClose} centered  dialogClassName="custom-delete-modal">
                                <Modal.Body>
                                    <h2 className="text-center" style={{ color: "#595959" }}>Are you sure?</h2>
                                    <p className="text-center">This can not be undone! Are you sure you want to delete the selected leads?</p>
                                </Modal.Body>
                                <Modal.Footer className="d-flex justify-content-center">
                                    <Button variant="danger" onClick={handleConfirmDelete}>
                                        Delete
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="px-3">
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>{error}</div>
                ) : (
                    <>
                        <DataTableComponent
                            tableData={filteredData}
                            // tableData={rowsToDisplay}
                            columns={filteredColumns}
                            showCheckboxes={true}
                            // showEditButton={true}
                            showEditIcon={true}
                        />

                        {hasMoreRows && (
                            <div className="text-center mt-3">
                                <button className="btn btn-outline-primary" onClick={handleViewMore}>
                                    View More
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default ContactData;

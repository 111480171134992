import React, { useState } from "react"; 

const PageHeader = () => {
    const [status, setStatus] = useState({ text: 'All Status', image: 'assets/img/svg/ata-icon-1.svg' });
    const [time, setTime] = useState('This Week');

    const changeDropdown = (imageSrc, text, type) => {
        if (type === 'status') {
            setStatus({ text, image: imageSrc });
        } else if (type === 'time') {
            setTime(text);
        }
    };

 return ( 
     <header className="border-bottom px-4 ff-inter d-flex py-3 align-items-center position-sticky top-0 bg-white  zindex-1">
                <h1 className="ff-inter h4 w-auto d-inline-block me-auto mb-0">Analytics</h1>
                <button type="button" className="btn border me-3 fs-14"><img src="assets/img/svg/download.svg" alt="" /> Download </button>
                <div className="dropdown me-3">
                    <button className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="text-start text-truncate w-200" id="statusDropdown">
                            <img src={status.image} alt="" /> {status.text}
                        </div>
                    </button>
                    <ul className="dropdown-menu fs-14" aria-labelledby="statusDropdown">
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-1.svg', ' All Status', 'status')}><img src="assets/img/svg/ata-icon-1.svg" /> All Status</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-2.svg', ' Paused', 'status')}><img src="assets/img/svg/ata-icon-2.svg" /> Paused</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-3.svg', ' Has errors', 'status')}><img src="assets/img/svg/ata-icon-3.svg" /> Has errors</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-4.svg', ' No custom tracking domain', 'status')}><img src="assets/img/svg/ata-icon-4.svg" /> No custom tracking domain</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-5.svg', ' Warmup active', 'status')}><img src="assets/img/svg/ata-icon-5.svg" /> Warmup active</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-6.svg', ' Warmup paused', 'status')}><img src="assets/img/svg/ata-icon-6.svg" /> Warmup paused</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-7.svg', ' Warmup has errors', 'status')}><img src="assets/img/svg/ata-icon-7.svg" /> Warmup has errors</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown('assets/img/svg/ata-icon-8.svg', ' No Tag', 'status')}><img src="assets/img/svg/ata-icon-8.svg" /> No Tag</button></li>
                    </ul>
                </div>
                <div className="dropdown">
                    <button className="btn d-flex border dropdown-toggle fs-14 text-end align-items-center py-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="text-start text-truncate w-200" id="timeDropdown">{time}</div>
                    </button>
                    <ul className="dropdown-menu fs-14" aria-labelledby="timeDropdown">
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' This Week', 'time')}> This Week</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' Yesterday', 'time')}>  Yesterday</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' This Week', 'time')}>This Week</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' This Month', 'time')}>This Month</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' Last Month', 'time')}> Last Month</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' Year to Date', 'time')}>Year to Date</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' Last 12 Month', 'time')}>  Last 12 Month</button></li>
                        <li><button className="dropdown-item" type="button" onClick={() => changeDropdown(null,  ' Custom', 'time')}> Custom</button></li>
                    </ul>
                </div>
            </header>

  );
};

export default PageHeader;

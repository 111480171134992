/* eslint-disable no-script-url */
import React from "react"; 
import { Link } from  'react-router-dom';


const CampaignsAdd = ({ closeModal, openAI_AssistedModal, openuser_campaign_SequenceModalModal  }) => {
    

 return ( 
     <>
         
         <div className="w-100 bg-white ff-inter offcanvas offcanvas-end show" id="addNewAccount">

             <div className="w-100 py-2 py-md-4 px-3 border-bottom">
                 <Link to="javascript:void(0)" className="h4 tc-111111 text-decoration-none backWindow" onClick={closeModal}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                         <path d="M15.5 19L9.20711 12.7071C8.81658 12.3166 8.81658 11.6834 9.20711 11.2929L15.5 5" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                     Back
                 </Link>
             </div>

             <div className="justify-content-evenly py-2 py-md-5 mt-md-3 mt-lg-5 overflow-x-auto" id="connectAccounts" style={{ display: "flex" }} >
                 <div className="row">
                     <div className="col-12 text-center w-100 mb-3 mb-md-4">
                         <h3 className="h3">Let's create a new sequence</h3>
                         <p className="fs-20 tc-979797">are a series of automated or manual touchpoints and activities, designed to drive deeper engagement with your contacts.</p>
                     </div>

                     <div className="col-12 col-md-4 text-center offset-md-2 mb-3">
                         <Link to="javascript:void(0)" className="text-decoration-none w-100 mxw-420 d-inline-block h-100"   onClick={openAI_AssistedModal} >
                             <div className="border rounded-3 p-2 p-md-4 text-center h-100">
                                 <img src="assets/img/ai-assisted.png" alt="" />
                                 <h3 className="h4 fw-700 text-dark">AI-assisted</h3>
                                     <p className="fs-20 tc-979797">Create a simple outbound sequence with one click.</p>
                             </div>
                         </Link>
                     </div>

                     <div className="col-12 col-md-4 text-center mb-3">
                         <Link to="javascript:void(0)" className="text-decoration-none w-100 mxw-420 d-inline-block h-100" onClick={openuser_campaign_SequenceModalModal}>
                             <div className="border rounded-3 p-2 p-md-4 text-center h-100">
                                 <img src="assets/img/from-scratch.png" alt="" />
                                 <h3 className="h4 fw-700 text-dark">Use previous campaign Sequence</h3>
                                     <p className="fs-20 tc-979797">Create a new sequence from scratch.</p>
                             </div>
                         </Link>
                     </div>


                 </div>
             </div>

         </div>
     </>
  );
};

export default CampaignsAdd;

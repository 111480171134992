import React, { useEffect } from 'react';
import Header from "../../common/Header.js";
import PageHeader from "./Header.js";
import DataTableComponent from '../../common/DataTableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampaigns, selectAllCampaigns, selectCampaignLoading, selectCampaignError } from '../../features/campaignSlice.js';
import { setCurrentPage, setItemsPerPage, setSelectedItems, setPopoverRow } from '../../features/dataTableSlice';

const Campaigns = () => {
    const dispatch = useDispatch();
    const campaigns = useSelector(selectAllCampaigns);
    const loading = useSelector(selectCampaignLoading);
    const error = useSelector(selectCampaignError);


    useEffect(() => {
        dispatch(fetchCampaigns());
    //    dispatch(setCurrentPage(1)); // reset page
    //     dispatch(setItemsPerPage(10)); // set items per page
    //     dispatch(setSelectedItems([])) // set selected item to empty array
    //      dispatch(setPopoverRow(null));  // set pop over row to null
    }, [dispatch]);

    const columns = [
        { title: "Name", data: "name" },
        { title: "Status", data: "status" }
    ];

    return (
        <div className="w-100 d-flex">
            <Header />
            <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                <PageHeader />
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>{error}</div>
                ) : (
                    <div className="p-4">
                        <DataTableComponent tableData={campaigns} columns={columns} showCheckboxes={true} showButtonAction={true} addLink={true} basePath="campaigns" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Campaigns;
import React, { useEffect, useState } from 'react';  
import DataTableComponent from '../../../common/DataTableComponent';
import { ScheduleTaskShowdata } from '../../../services/authFunctions';

const CampaignseSeandEmailData = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the campaigns data
    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const campaigns = await ScheduleTaskShowdata();  // Fetch data from the API
                console.log('Fetched Campaigns:', campaigns);

                if (Array.isArray(campaigns.details)) {
                    setContacts(campaigns.details.reverse()); // Reverse order before setting state
                } else {
                    console.error("Expected an array, but received:", campaigns.details);
                    setContacts([]);  // Set empty array if the data isn't in the expected format
                }
                setLoading(false); // Stop loading after data is fetched
            } catch (err) {
                console.error('Error fetching campaigns:', err);
                setError('Failed to fetch campaigns');
                setLoading(false); // Stop loading even if there's an error
            }
        };

        fetchCampaigns();  // Trigger the fetch
    }, []);

    // Columns definition
    const columns = [
        { title: "Stage", data: "stage" },
        { title: "Email", data: "email" },
        { title: "Subject", data: "subject" },
        { title: "Message", data: "body" },
    ];

    // Render loading or error state
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    // Render the table
    return (
        <div>
            <h3>Campaign Email Data</h3>
            <table border="1" cellPadding="10" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index}>{col.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {contacts.map((contact, index) => (
                        <tr key={index}>
                            {columns.map((col, colIndex) => (
                                <td key={colIndex}>
                                    {contact[col.data] !== null && contact[col.data] !== undefined
                                        ? contact[col.data]
                                        : 'N/A'  // Display 'N/A' for null/undefined values
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CampaignseSeandEmailData;





/*


import axios from 'axios';

const Campaigns = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  // State to store the error

    useEffect(() => {
        const storedUserName = localStorage.getItem('token');
        // const token = 'YOUR_API_TOKEN'; // Replace with your actual token
        console.log(storedUserName);
        axios
            .get('https://emailcamp.flowzdigital.com/api/campaigns', {
                headers: {
                    'Authorization': `Bearer ${storedUserName}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((err) => {
                // Log the full error object for debugging
                console.error('Error fetching data:', err.response ? err.response : err);

                // Set a user-friendly error message to show on the UI
                if (err.response) {
                    if (err.response.status === 401) {
                        setError('Unauthorized: You do not have permission to access this resource.');
                    } else {
                        setError('An error occurred: ' + err.response.statusText);
                    }
                } else {
                    setError('Network error or the server is down.');
                }

                setLoading(false); // Set loading to false after error occurs
            });
    }, []);

    */
// import React, { useState, useEffect } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import { useNavigate } from "react-router-dom";
// import { CampaignDataSave } from "../../services/authFunctions";
// import CampaignForm from "../../components/Campaign Components/OpenAiEmailContentGenerator";
// import CampaignName from "../../components/Campaign Components/CampaignName";
// import FinalizeContent from "../../components/Campaign Components/FinalizeSequences";

// const AI_Assisted = ({ closeModal, campaignName }) => {
//   const [steps, setSteps] = useState([{ day: 1, subject: "", message: "" }]);
//   const navigate = useNavigate();
//   const [isCampaignNameSubmitted, setIsCampaignNameSubmitted] = useState(false);
//   const [showForm, setShowForm] = useState(false);
//   const [showFirstSection, setShowFirstSection] = useState(true);
//   const [generatedContent, setGeneratedContent] = useState([]);
//   const [subjectLine, setSubjectLine] = useState("");
//   const [showFinalizeContent, setShowFinalizeContent] = useState(false);
//   const [showFinalizeModal, setShowFinalizeModal] = useState(false); 

// // console.log(showFinalizeContent);
//   useEffect(() => {
//     console.log("Generated Content", generatedContent);
//   }, [generatedContent]);

//   useEffect(() => {
//     if (campaignName) {
//       console.log("Campaign Name:", campaignName);
//       setIsCampaignNameSubmitted(true);
//     }
//   }, [campaignName]);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowFirstSection(false);
//     }, 300);

//     return () => clearTimeout(timer);
//   }, []);

//   const handleInputChange = (index, field, value) => {
//     const newSteps = [...steps];
//     newSteps[index][field] = value;
//     setSteps(newSteps);
//   };

//   const addSequence = () => {
//     const lastDay = steps.length > 0 ? steps[steps.length - 1].day + 3 : 1;

//     setSteps([
//       ...steps,
//       {
//         day: lastDay,
//         subject: "",
//         message: "",
//       },
//     ]);
//   };

//   const removeSequence = (indexToRemove) => {
//     setSteps(steps.filter((_, index) => index !== indexToRemove));
//   };

//   const handleSaveSequence = async () => {
//     if (!campaignName || steps.some((step) => !step.subject || !step.message)) {
//       alert("Please fill in all the required fields.");
//       return;
//     }

//     const currentDate = new Date();
//     const endDate = new Date(currentDate);
//     endDate.setDate(currentDate.getDate() + (steps.length - 1) * 3);

//     const stages = steps.map((step, index) => {
//       const stageStartDate = new Date(currentDate);
//       stageStartDate.setDate(currentDate.getDate() + index * 3);
//       stageStartDate.setHours(
//         currentDate.getHours(),
//         currentDate.getMinutes(),
//         currentDate.getSeconds()
//       );

//       const start_date = stageStartDate.toISOString().split("T")[0];
//       const start_time = stageStartDate
//         .toISOString()
//         .split("T")[1]
//         .split(".")[0];

//       return {
//         stage_number: index + 1,
//         subject: step.subject,
//         description: step.message,
//         start_date: start_date,
//         start_time: start_time,
//       };
//     });

//     const campaignData = {
//       user_id: "1",
//       name: campaignName,
//       status: "scheduled",
//       scheduled_at: currentDate.toISOString().split("T")[0],
//       start_date: currentDate.toISOString().split("T")[0],
//       end_date: endDate.toISOString().split("T")[0],
//       stages: stages,
//     };

//     try {
//       console.log("Sending request to API..." + campaignData);
//       const response = await CampaignDataSave(campaignData);
//       console.log(response);
//       if (response && response.id) {
//         const campaignId = response.id;
//         console.log(`Campaign created successfully with ID: ${campaignId}`);
//         navigate(`/campaigns/${campaignId}`);
//       } else {
//         throw new Error("Campaign ID is missing in the response.");
//       }

//       closeModal();
//     } catch (error) {
//       console.error("Error saving campaign:", error);
//       alert("There was an error saving the campaign. Please try again.");
//     }
//   };

//   const deleteLastSequence = () => {
//     if (steps.length > 1) {
//       setSteps(steps.slice(0, -1));
//     } else {
//       alert("You must have at least one sequence.");
//     }
//   };

//   const handleContentGenerated = (content) => {
//     setGeneratedContent(content);
//     if (content && Array.isArray(content)) {
//       const newSteps = content.map((item, index) => ({
//         day: index + 1 * 3,
//         subject: `Email ${index + 1} - ${item.channel}`,
//         message: item.email,
//       }));
//       setSteps(newSteps);
//     }
//     setShowFinalizeContent(true);
//     setShowFinalizeModal(true)
//   };

//   const handleCloseFinalizeModal = () => {
//     setShowFinalizeModal(false) // Show the modal
//   }

//   return (
//     <>
//       <div className="modal-backdrop fade show" onClick={closeModal}></div>

//       <div className="modal fade show d-block" id="ai-assisted" tabIndex="-1">
//         <div className="modal-dialog modal-xl overflow-auto modal-dialog-centered ">
//           <div className="modal-content h-100 w-100">
//             {/* First Section: AI Working (Visible for 3 seconds) */}
//             {showFirstSection && (
//               <div className="modal-body h-100">
//                 <div className="d-flex w-100 h-100 align-items-center justify-content-center ff-inter">
//                   <div className="bg-white rounded-3 text-center">
//                     <img src="assets/img/ai-email-creating.png" alt="" />
//                     <p className="fs-14 text-center pb-2">
//                       OB AI is hard at work. <br />
//                       Creating an email just for you...
//                     </p>
//                     <p className="fs-14 text-center">
//                       <strong>Please note:</strong> it can take up to a minute
//                       to generate a sequence.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             )}

//             {/* Second Section: Campaign Name Input (Visible after 3 seconds) */}
//             {!showFirstSection && isCampaignNameSubmitted && (
//               <CampaignForm setGeneratedContent={handleContentGenerated}  />
//             )}

//               {showFinalizeModal &&(
//                      <div className="modal fade show d-block" tabIndex="-1" role="dialog">
//                      <div className="modal-dialog modal-xl overflow-auto modal-dialog-centered">
//                          <div className="modal-content h-100 w-100">
//                              <FinalizeContent
//                                  steps={steps}
//                                  handleInputChange={handleInputChange}
//                                  removeSequence={removeSequence}
//                                  addSequence={addSequence}
//                                  deleteLastSequence={deleteLastSequence}
//                                  handleSaveSequence={handleSaveSequence}
//                                  closeModal={handleCloseFinalizeModal} // Close the modal when save sequence is clicked
//                                  generatedContent={generatedContent}
//                              />
//                          </div>
//                      </div>
//                  </div>
//               )}
//             {/* Form for Editing Steps (Visible after campaign name is submitted and content is generated) */}
//             {/* {!showFirstSection &&
//               isCampaignNameSubmitted &&
//               showFinalizeContent &&
//               generatedContent && (
//                 <FinalizeContent
//                   steps={steps}
//                   handleInputChange={handleInputChange}
//                   removeSequence={removeSequence}
//                   addSequence={addSequence}
//                   deleteLastSequence={deleteLastSequence}
//                   handleSaveSequence={handleSaveSequence}
//                   closeModal={closeModal}
//                   generatedContent={generatedContent}
//                 />
//               )} */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AI_Assisted;
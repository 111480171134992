import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../page/Login';
import ForgotPassword from '../page/ForgotPassword';
import Logout from '../page/Logout';
import Dashboard from '../page/Dashboard/Dashboard';
import ContactBooks from '../page/ContactBooks/ContactBooks';
import EmailAccounts from '../page/EmailAccounts/EmailAccounts';
import Campaigns from '../page/Campaigns/Campaigns';
import CampaignDetails from '../page/Campaigns/CampaignsDetails';
// import LeadManager from '../page/LeadManager/LeadManager';
import CampaignForm from '../components/Campaign Components/OpenAiEmailContentGenerator';
import CampaignsAdd from '../page/Campaigns/CampaignsAdd';
import AI_Assisted from '../page/Campaigns/AI_Assisted';
import CampaignName from '../components/Campaign Components/CampaignName';
import FinalizeContent from '../components/Campaign Components/FinalizeSequences';
import EmailVerificationSuccess from '../page/EmailAccounts/EmailVerificationSuccess';

const AppRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

    return(
        <Routes>
            <Route path="/" element={ isAuth ?
                <Dashboard /> : <Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contact-books" element={<ContactBooks />} />
            <Route path="/email-accounts" element={<EmailAccounts />} />
            <Route path="/campaigns/:campaignId" element={<CampaignDetails />} />
            <Route path="/campaigns" element={<Campaigns />} />
            {/* <Route path="/lead-manager" element={<LeadManager />} /> */}
            <Route path="/campaigns/add/AI-assisted" element={<CampaignsAdd />} />
            <Route path="/AI-assisted-generated-content" element={<AI_Assisted />} />
            <Route path="/new-campaign-name" element={<CampaignName />} />
            <Route path="/finalize-Content-sequences" element={<FinalizeContent />} />
            <Route path="/openai_email_content_generator" element={<CampaignForm />} />
            <Route path="/email-verification-success" element={<EmailVerificationSuccess />} />

        </Routes>
    )
};

export default AppRoutes;


import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Pagination from './Pagination';
import ItemsPerPageSelect from './ItemsPerPageSelect';

const DataTableComponent = ({ tableData = [], columns, showCheckboxes, showEditButton, showButtonAction, addLink, basePath }) => { // Add addLink as prop
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedItems, setSelectedItems] = useState([]);

    // Calculate pagination
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle checkbox selection for "select all"
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allSelected = currentItems.map((item) => item.id);
            setSelectedItems(allSelected);
        } else {
            setSelectedItems([]);
        }
    };

    // Handle checkbox selection for individual row
    const handleSelectUser = (id) => {
        setSelectedItems((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((item) => item !== id)
                : [...prevSelected, id]
        );
    };

    // Handle edit click (to simulate editing functionality)
    const handleEditClick = (row) => {
        console.log('Edit row data:', row);
    };

    return (
        <>
            <div className="table-responsive">
                <table className="table fs-14">
                    <thead>
                        <tr>
                            {/* Conditionally render checkbox column */}
                            {showCheckboxes && (
                                <th key="checkbox">
                                    <input
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={selectedItems.length === currentItems.length && currentItems.length > 0}
                                    />
                                </th>
                            )}

                            {/* Conditionally render edit button column */}
                            {showEditButton && <th key="edit">Edit</th>}

                            {/* Render dynamic columns */}
                            {columns.map((col) => (
                                <th className="text-nowrap" key={col.data}>{col.title}</th>
                            ))}

                            {showButtonAction && (
                                <th key="actions">Actions</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.length > 0 ? (
                            currentItems.map((row) => (
                                <tr key={row.id}> {/* Use row.id as the unique key */}
                                    {/* Conditionally render checkbox for individual row */}
                                    {showCheckboxes && (
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(row.id)}
                                                onChange={() => handleSelectUser(row.id)}
                                            />
                                        </td>
                                    )}

                                    {/* Conditionally render edit button for individual row */}
                                    {showEditButton && (
                                        <td>
                                            <button
                                                onClick={() => handleEditClick(row)}
                                                className="border-0 bg-transparent"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M7.99967 1.33345H3.33301C2.22844 1.33345 1.33301 2.22888 1.33301 3.33345V12.6668C1.33301 13.7714 2.22844 14.6668 3.33301 14.6668H12.6663C13.7709 14.6668 14.6663 13.7714 14.6663 12.6668V8.00012M13.3449 5.01195L14.2496 4.10716C14.9005 3.45628 14.9005 2.40101 14.2496 1.75014C13.5988 1.09927 12.5435 1.09927 11.8926 1.75015L10.9878 2.65493M13.3449 5.01195L8.31463 10.0423C8.08196 10.2749 7.78563 10.4335 7.46297 10.498L5.01153 10.9883L5.50182 8.53688C5.56635 8.21423 5.72494 7.9179 5.9576 7.68524L10.9878 2.65493M13.3449 5.01195L10.9878 2.65493"
                                                        stroke="#747474"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </td>
                                    )}

                                    {/* Render dynamic columns */}
                                    {columns.map((col) => (
                                        <td key={`${row.id}-${col.data}`}>
                                            {/* Handle different cases for name column */}
                                            {col.data === "name" ? (
                                                // Conditionally render link or plain text
                                                addLink ? (
                                                    <Link to={`/${basePath}/${row.id}`} className="text-decoration-none text-dark">
                                                        {row[col.data]} 
                                                    </Link>
                                                ) : (
                                                    <span>{row[col.data]}</span> // Just show the plain text without a link
                                                )
                                            ) : (
                                                row[col.data]
                                            )}
                                        </td>
                                    ))}

                                    {showButtonAction && (
                                        <td>
                                            <img src="assets/img/svg/play-green.svg" width="24" alt="" />
                                            {/* <div className="btn-group">
                                                <a href="javascript:void(0)" className="text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="assets/img/svg/three-points-vertical.svg" width="24" alt="" />
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-end fs-12">
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            <img src="assets/img/svg/play-gray.svg" alt="" />
                                                            Resume
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            <img src="assets/img/svg/delete.svg" alt="" />
                                                            Delete
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            <img src="assets/img/svg/copy.svg" alt="" />
                                                            Duplicate campaign
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            <img src="assets/img/svg/download.svg" alt="" width="16" />
                                                            Download analytics CSV
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            <img src="assets/img/svg/share.svg" alt="" />
                                                            Share Campaign
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={columns.length + (showCheckboxes ? 1 : 0) + (showEditButton ? 1 : 0)}>
                                    No results found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="d-flex justify-content-between mb-3">
                <div className="w-200">
                    Rows per page
                    <ItemsPerPageSelect
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        className="mxw-110"
                    />
                </div>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                />
            </div>
        </>
    );
};

export default DataTableComponent;

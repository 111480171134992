import React, { useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import SearchBar from "../../../common/SearchBar.js";
import ContactFilter from "../../ContactBooks/ContactFilter.js";
import ContactData from "../../ContactBooks/ContactData.js";
import { fetchContacts, fetchCampaignDetails, setFilters, setSelectedFilters, setSearchQuery } from '../../../features/detailsTabLeadsSlice';
import CampaignButton from "./CampaignButton.js";
import CampaignDataImport from "./DataImport";
import { useDispatch, useSelector } from 'react-redux';
import AddLeadsModal from "./AddLeadsModal.js";
import export_icon from "../../../assets/img/export_icon.png";
import EmptyLeads from "../EmptyLeads.jsx";
import view_more from "../../../assets/img/icon/view-more.png";

const DetailsTabLeadsComponent = () => {
    const { campaignId } = useParams();
    const dispatch = useDispatch();
    const { filters, selectedFilters, searchQuery } = useSelector((state) => state.detailsTabLeads);
    const [downloadUrl, setDownloadUrl] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);

    const campaign = useSelector((state) => state.detailsTabLeads.campaign);
    const loading = useSelector((state) => state.detailsTabLeads.loading);
    const error = useSelector((state) => state.detailsTabLeads.error)
    const url = process.env.REACT_APP_URL;

    const contacts = useSelector(state => state.detailsTabLeads.contacts);
    useEffect(() => {
        // if (!contacts.length) {
            dispatch(fetchContacts(campaignId));
        // }
    }, [dispatch,campaignId]);

    const handleFiltersChange = (newFilters) => {
        dispatch(setFilters(newFilters));
    };

    const handleClearFilters = () => {
        dispatch(setFilters({}));
        dispatch(setSelectedFilters({}));
    };

    const handleSearchChange = (query) => {
        dispatch(setSearchQuery(query));
    };

    useEffect(() => {
        if (campaignId) {
            dispatch(fetchCampaignDetails(campaignId));
            const apiUrl = process.env.REACT_APP_URL;
            setDownloadUrl(`${apiUrl}/api/download-excel`);
        }
    }, [campaignId]);

    const handleImportSuccess = () => {
        dispatch(fetchCampaignDetails(campaignId));
    };

    const handleDownloadClick = () => {
        window.open(downloadUrl, '_blank');
    };

    const handleSelectedFilters = (filters) => {
        dispatch(setSelectedFilters(filters));
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const openShareModal = () => {
        setShowShareModal(true);
        setShowDropdown(false);
    };
    const closeShareModal = () => { setShowShareModal(false); setShowDropdown(false); }

    console.log("searchQuery",searchQuery)
    return (
        <div className="ff-inter h-100">
            <div className="row h-100 g-0">        
                {/* Object.keys(campaign).length > 0 ? ( */}
                {campaignId && !loading && !error &&
                    // (Object.keys(campaign || {}).length > 0 ? (
                    (contacts && contacts?.length > 0 ? (
                        <div className="col-lg-12 col-md-12">
                            <div className="d-flex py-3 align-items-center px-3">
                                <h3 className="ff-inter h5 w-auto d-inline-block me-auto mb-0">Leads</h3>                        
                                {/* {campaignId && campaign && !loading && !error && (
                            <button type="button" className="btn border me-3 fs-14" onClick={handleDownloadClick}><img src="assets/img/svg/download.svg" alt="" /> Download </button>
                                )} */}
                                {campaignId && campaign && !loading && !error && (
                                    <button type="button" className="btn border me-3 fs-14" onClick={handleDownloadClick}><img src="assets/img/svg/download.svg" alt="" /><img src={export_icon} alt="export" /> Export </button>
                                )}
                                {campaignId && campaign && !loading && !error && (
                                    <CampaignDataImport onImportSuccess={handleImportSuccess} />
                                )}
                                <button type="button" className="btn btn-primary ms-3" onClick={() => setShowModal(true)}>
                                    + Add Leads
                                </button>
                                <button type="button" className="btn border ms-3" onClick={toggleDropdown}>
                                    <img src={view_more} alt="view-more" />
                                </button>
                            </div>
                            {showDropdown && (
                                <div className="dropdown-menu show position-absolute" style={{ right: 0 }} onClick={toggleDropdown}>
                                    <button className="dropdown-item" onClick={openShareModal} >Share campaign</button>
                                </div>
                            )}

                            {showShareModal && (
                                <div className="modal fade show d-block" tabIndex="-1">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Share campaign</h5>
                                                <button type="button" className="btn-close" onClick={closeShareModal}></button>
                                            </div>
                                            <div className="modal-body">
                                                <p>Copy the link below or export as a file to share this campaign</p>
                                                <p className="text-primary"><b>Note:</b> The link will stop working after seven days.</p>
                                                <input type="text" className="form-control" value={url} readOnly />

                                                <div className="d-flex mt-3">
                                                    <button className="btn btn-outline-primary me-2" onClick={() => {
                                                        navigator.clipboard.writeText(url);
                                                        alert("Link copied to clipboard!");
                                                    }}>
                                                        📋 Copy link
                                                    </button>
                                                    <button className="btn btn-outline-primary" onClick={handleDownloadClick}>
                                                        📥 Export file
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showModal && <AddLeadsModal onClose={() => setShowModal(false)} />}         

                            <div className="w-100">
                                {campaignId && !loading && !error && campaign && (
                                    <ContactData
                                        filters={filters}
                                        data={contacts}
                                        searchQuery={searchQuery}
                                        campaign_id={campaignId}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <EmptyLeads />
                    ))}
            </div>
        </div>
    );
};

const DetailsTabLeads = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <DetailsTabLeadsComponent />
        </Suspense>
    );
};

export default DetailsTabLeads;
import React, { useCallback, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// import { openAiAssistedModal, closeAiAssistedModal } from '../../features/AIcampaignSlice';
import OpenAiEmailContentGenerator from "../../components/Campaign Components/OpenAiEmailContentGenerator";
import AI_Assisted_IMG from "../../assets/img/ai-assisted.png"
import FROM_SCRATCH_IMG from "../../assets/img/from-scratch.png"
import USE_TEMPLETE from "../../assets/img/use_Templete1.png"
import FinalizeContent from "../../components/Campaign Components/FinalizeSequences";
import { selectCampaignName, closeAiAssistedModal, closeFinalizeContentModal, } from "../../features/AIcampaignSlice"

const CampaignsAdd = () => {
    const dispatch = useDispatch();
    // const { aiAssistedModalOpen } = useSelector((state) => state.campaign);
    // const [aiAssistedModalOpen, setAiAssistedModalOpen] = useState(false); // Use local state
//    console.log(aiAssistedModalOpen, "aiAssistedModalOpen")
const [showModal, setShowModal] = useState(null);

    const handleOpenAIModal = () => {
        // dispatch(closeFinalizeContentModal());
        // setAiAssistedModalOpen(true); // Open modal using local state
        // dispatch(openAiAssistedModal());
        setShowModal("first");
    };

    const handleCloseAIModal = () => {
        // setAiAssistedModalOpen(false); // Close modal using local state
        // dispatch(closeAiAssistedModal());
        setShowModal(null);
    };

    return (
        <>
            {/* Main Section */}
            <div className="w-100 bg-white ff-inter offcanvas offcanvas-end show" id="addNewAccount">
                <div className="w-100 py-2 py-md-4 px-3 border-bottom">
                    <Link to="/campaigns" className="h4 tc-111111 text-decoration-none backWindow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15.5 19L9.20711 12.7071C8.81658 12.3166 8.81658 11.6834 9.20711 11.2929L15.5 5" stroke="#111111" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        Back
                    </Link>
                </div>

                <div className="justify-content-evenly py-2 py-md-5 mt-md-3 mt-lg-5 " id="connectAccounts" style={{ display: "flex" }}>
                    <div className="row justify-content-center">
                        <div className="col-12 text-center w-100 mb-3 mb-md-4">
                            <h3 className="h3">Let's create a new sequence</h3>
                            <p className="fs-20 tc-979797">
                                A series of automated or manual touchpoints and activities, designed to drive deeper engagement with your contacts.
                            </p>
                        </div>

                        {/* AI Assisted */}
                        <div className="col-12 col-md-4 text-center mb-3">
                            <Link to="#" className="text-decoration-none w-100 mxw-420 d-inline-block h-100" onClick={handleOpenAIModal}>
                                <div className="border rounded-3 p-2 p-md-4 text-center h-100">
                                    <img src={AI_Assisted_IMG} alt="AI Assisted" />
                                    <h3 className="h4 fw-700 text-dark">AI-assisted</h3>
                                    <p className="fs-20 tc-979797">Create a simple outbound sequence with one click.</p>
                                </div>
                            </Link>
                        </div>

                        {/* From Scratch */}
                        <div className="col-12 col-md-4 text-center mb-3">
                            <Link to="#" className="text-decoration-none w-100 mxw-420 d-inline-block h-100">
                                <div className="border rounded-3 p-2 p-md-4 text-center h-100">
                                    <img src={FROM_SCRATCH_IMG} alt="From Scratch" />
                                    <h3 className="h4 fw-700 text-dark">From scratch</h3>
                                    <p className="fs-20 tc-979797">Create a new sequence from scratch.</p>
                                </div>
                            </Link>
                        </div>

                        {/* From Scratch */}
                        <div className="col-12 col-md-4 text-center mb-3">
                            <Link to="#" className="text-decoration-none w-100 mxw-420 d-inline-block h-100">
                                <div className="border rounded-3  p-2 p-md-4 text-center h-100">
                                    <img src={USE_TEMPLETE} alt="From  Scratch" />
                                    <h3 className="h4 fw-700 mt-4 text-dark ">Use Sequence Tempete</h3>
                                    <p className="fs-20 tc-979797">Start with one of our sequence templates.</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* AI Assisted Modal */}
            { showModal == "first" && (
                // <div className="modal-overlay">
                //     <div className="modal-content">
                        // <button className="close-button" onClick={handleCloseAIModal}>X</button>
                        <OpenAiEmailContentGenerator showModal={showModal} setShowModal={setShowModal}/>
                //     </div>
                // </div>
            )}
             { showModal == "second" && (
                
                <FinalizeContent showModal={showModal} setShowModal={setShowModal}/>
           
    )}
        </>
    );
};

export default CampaignsAdd;
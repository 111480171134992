import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { gsuiteDataSave } from '../../services/authFunctions';

const EmailSetupModal = ({ onClose }) => {
    const [formData, setFormData] = useState({
        email: "",
        full_name: ""
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Basic Email Format Validation (Regex)
    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Handle save button click
    const handleSave = async () => {
        const { email, full_name } = formData;

        if (!email || !full_name) {
            alert("Please enter both email and full name.");
            return;
        }

        if (!isValidEmail(email)) {
            setError("Please enter a valid email address.");
            return;
        }

        setLoading(true);
        setError(null);
        console.log("window.API_URL", window.API_URL)

        try {
            const requestData = {
                email,
                full_name,
                limit: 60,
                delay_time: 7,
                status: 0,        // Set status to 0
                is_active: true  // Assuming 'false' for "is_active", set to true if you want it active
            };
            const response = await gsuiteDataSave(requestData);


            if (response) {
                // Optionally, handle any additional success actions here
                setFormData({ email: "", full_name: "" });
                // window.open(`${window.API_URL}/api/gsuite/auth/${email}`, '_blank');
                // const openURLnewtab = `${window.API_URL}/api/gsuite/auth/${email}`;
                // window.open(openURLnewtab, '_blank');
                // Open new window (popup)
                window.location.href = `${window.API_URL}/api/gsuite/auth/${email}`;
                // const authWindow = window.open(
                //     `${window.API_URL}/api/gsuite/auth/${email}`,
                //     'GoogleAuthWindow',
                //     'width=600,height=700,left=300,top=100'
                // );

                console.log("authWindow", window.location.href)

                // if (!authWindow) {
                //     alert("Popup blocked! Please allow popups and try again.");
                //     return;
                // }
                //  authWindow.close();

                // Listen for message from popup
                // authWindow.addEventListener("message", function(event) {
                //     console.log("event",event)
                //     // if (event.origin.includes("prospectly.com") && event.data.success) {
                //         // authWindow.close();
                //         // authWindow.location.href = "http://localhost:3000/email-accounts";
                //         // console.log("authWindow.location.href",authWindow.location.href)
                //         // window.location.reload();
                //     // }
                // });


            } else {
                throw new Error("Campaign ID is missing in the response.");
            }

            onClose(); // Close the modal if save is successful
        } catch (err) {
            // const errorMessage = err?.message || 'Failed to save email. Please try again.';
            // setError(errorMessage);

            if (err.response && err.response.data) {
                const errorMessage = err.response.data.message || "Something went wrong!";
                setError(errorMessage);
            } else {
                setError('Failed to save email. Please try again.');
            }
        } finally {
            setLoading(false); // Stop the loading state
        }
    };

    return (
        <div className="w-100 bg-white ff-inter offcanvas offcanvas-end show" id="addNewAccount">
            <div className="w-100 py-2 py-md-4 px-3 border-bottom">
                <Link
                    className="h4 tc-111111 text-decoration-none backWindow"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M15.5 19L9.20711 12.7071C8.81658 12.3166 8.81658 11.6834 9.20711 11.2929L15.5 5"
                            stroke="#111111"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    Back
                </Link>
            </div>

            <div className="justify-content-evenly py-2 py-md-5 mt-md-3 mt-lg-5" id="connectAccounts" style={{ display: "flex" }}>
                <div className="w-100 mxw-420">
                    <h4 className="fw-500 mb-3 mb-md-4">Done for you email setup</h4>
                    <div className="text-decoration-none shadow d-flex rounded-3 px-4 py-3 align-items-center w-100 mb-3 mb-md-4 addemailaccount">
                        <span className="border-end p-2 pe-3 me-3">
                            <img src="assets/img/svg/google.svg" width="48" alt="" />
                        </span>
                        <div className="w-auto">
                            <p className="tc-979797 fs-14 mb-0">Google</p>
                            <p className="tc-111111 h4">Gmail / G-suite</p>
                        </div>
                    </div>
                    <input
                        className="form-control form-control-lg fs-14 my-2"
                        type="text"
                        name="email"
                        placeholder="Enter your Email address"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={loading}  // Disable the input while loading
                    />

                    <input
                        className="form-control form-control-lg fs-14"
                        type="text"
                        name="full_name"
                        placeholder="Enter your Full Name"
                        value={formData.full_name}
                        onChange={handleChange}
                        disabled={loading}  // Disable the input while loading
                    />
                    {error && <p className="text-danger mt-2">{error}</p>} {/* Display error message */}

                    <div className="text-center mt-3">
                        <button
                            type="button"
                            className="btn btn-primary border mx-3 fs-14 mw-110"
                            onClick={handleSave}
                            disabled={loading} // Disable the button when loading
                        >
                            {loading ? "Saving..." : "Save"} {/* Change text to 'Saving...' when loading */}
                        </button>
                    </div>
                </div>
                {/* <div className="w-100 mxw-420">
                    <h4 className="fw-500 mb-3 mb-md-4">Connect a new email account</h4>
                    <div className="text-decoration-none shadow d-flex rounded-3 px-4 py-3 align-items-center w-100 mb-3 mb-md-4 addemailaccount">
                        <span className="border-end p-2 pe-3 me-3">
                            <img src="assets/img/svg/google.svg" width="48" alt="" />
                        </span>
                        <div className="w-auto">
                            <p className="tc-979797 fs-14 mb-0">Google</p>
                            <p className="tc-111111 h4">Gmail / G-suite</p>
                        </div>
                    </div>
                    <input
                        className="form-control form-control-lg fs-14 my-2"
                        type="text"
                        name="email"
                        placeholder="Enter your Email address"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={loading}  
                    />

                    <input
                        className="form-control form-control-lg fs-14"
                        type="text"
                        name="full_name"
                        placeholder="Enter your Full Name"
                        value={formData.full_name}
                        onChange={handleChange}
                        disabled={loading}  
                    />
                    {error && <p className="text-danger mt-2">{error}</p>} 

                    <div className="text-center mt-3">
                        <button
                            type="button"
                            className="btn btn-primary border mx-3 fs-14 mw-110"
                            onClick={handleSave}
                            disabled={loading} 
                        >
                            {loading ? "Saving..." : "Save"} 
                        </button>
                    </div>
                </div> */}
                
            </div>
            {/* <div className="justify-content-evenly py-2 py-md-5 mt-md-3 mt-lg-5" id="connectAccounts" style={{ display: "flex" }}>
                <div className="w-100 mxw-420">
                    <h4 className="fw-500 mb-3 mb-md-4">Done for you email setup</h4>
                    <div className="text-decoration-none shadow d-flex rounded-3 px-4 py-3 align-items-center w-100 mb-3 mb-md-4 addemailaccount">
                        <span className="border-end p-2 pe-3 me-3">
                            <img src="assets/img/svg/google.svg" width="48" alt="" />
                        </span>
                        <div className="w-auto">
                            <p className="tc-979797 fs-14 mb-0">Microsoft (Coming Soon)</p>
                            <p className="tc-111111 h4">Office 365 / Outlook</p>
                        </div>
                    </div>
                 
                </div>
                <div className="w-100 mxw-420">
                    <h4 className="fw-500 mb-3 mb-md-4">Connect a new email account</h4>
                    <div className="text-decoration-none shadow d-flex rounded-3 px-4 py-3 align-items-center w-100 mb-3 mb-md-4 addemailaccount">
                        <span className="border-end p-2 pe-3 me-3">
                            <img src="assets/img/svg/google.svg" width="48" alt="" />
                        </span>
                        <div className="w-auto">
                            <p className="tc-979797 fs-14 mb-0">Microsoft</p>
                            <p className="tc-111111 h4">Office 365 / Outlook</p>
                        </div>
                    </div>
                    

                  
                </div>
                
            </div> */}
        </div>
    );
};

export default EmailSetupModal;

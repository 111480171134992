// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lead-modal .modal-content {
    background-color: #fff !important;
    border-radius: 24px;
    padding: 20px;
    width: 70%;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .lead-modal .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  
  .lead-modal .modal-body {
    max-height: 400px;
    /* overflow-y: auto; */
  }
  
  .lead-modal .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .lead-modal .close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .lead-modal .modal-title{
    font-size: 24px;
  }
  
  
  .form-group {
    display: flex;
    align-items: center;
    gap: 10px; 
    margin: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/page/Campaigns/DetailsCommponents/styles/AddLeadsModalStyle.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,oBAAoB;EACtB;;EAEA;IACE,iBAAiB;IACjB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;;EAGA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,WAAW;EACb","sourcesContent":[".lead-modal .modal-content {\n    background-color: #fff !important;\n    border-radius: 24px;\n    padding: 20px;\n    width: 70%;\n    border-radius: 8px;\n    font-size: 14px;\n    font-weight: 500;\n  }\n  \n  .lead-modal .modal-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-bottom: 10px;\n  }\n  \n  .lead-modal .modal-body {\n    max-height: 400px;\n    /* overflow-y: auto; */\n  }\n  \n  .lead-modal .modal-footer {\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n  }\n  \n  .lead-modal .close-btn {\n    background: none;\n    border: none;\n    font-size: 20px;\n    cursor: pointer;\n  }\n  \n  .lead-modal .modal-title{\n    font-size: 24px;\n  }\n  \n  \n  .form-group {\n    display: flex;\n    align-items: center;\n    gap: 10px; \n    margin: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

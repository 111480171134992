import React, { useState } from "react";
import Header from "../../common/Header.js";
import PageHeader from "./Header.js";
import ContactData from "./ContactData.js";
import ContactFilter from "./ContactFilter.js";
import SearchBar from "../../common/SearchBar.js";

const ContactBooks = () => {
    const [filters, setFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    
    const handleFiltersChange = (newFilters) => {
        setFilters(newFilters);
    };

    // Clear all filters (reset both filters and selectedFilters)
    const handleClearFilters = () => {
        setFilters({}); 
        setSelectedFilters({}); 
    };
    const handleSearchChange = (query) => {
        setSearchQuery(query);  
    };

    return (
        <div className="w-100 d-flex">
            <Header />
            <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                <PageHeader/>
                <div className="ff-inter h-100">
                    <div className="row h-100 g-0">
                        <div className="col-lg-2 col-md-3 h-100 pe-0 border-end">
                            <div className="border-bottom p-3">
                                <label className="fw-700 w-100 pb-3">Search</label> 
                                <SearchBar onSearchChange={handleSearchChange} />
                            </div>

                            <div className="w-100">
                                <div className="d-flex px-3 py-2 border-bottom justify-content-between">
                                    <label className="fw-700">Filter</label>
                                    <button
                                        className="border-0 bg-transparent fs-14 tc-979797"
                                        onClick={handleClearFilters} 
                                    >
                                        x Clear All
                                    </button>
                                </div>
                                <div className="p-3">
                                    <ContactFilter
                                        onFiltersChange={handleFiltersChange}
                                        selectedFilters={selectedFilters} 
                                        setSelectedFilters={setSelectedFilters} 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-10 col-md-9">
                            <div className="d-flex py-3 align-items-center px-3">
                                <h3 className="ff-inter h5 w-auto d-inline-block me-auto mb-0">Contact Details</h3>
                                <button type="button" className="btn border me-3 fs-14"><img src="assets/img/svg/upload.svg" alt="" /> Export </button>
                                <button type="button" className="btn btn-primary border mx-3 fs-14" id="addNewMember"> <img src="assets/img/svg/download-white.svg" alt="" /> Import </button>
                            </div>
                            <div className="w-100">
                                <ContactData filters={filters} searchQuery={searchQuery} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactBooks;

import React, { useState } from 'react';
import axios from 'axios';

const SendEmails = () => {
    const [emailDetails, setEmailDetails] = useState({
        subject: '',
        message: '',
        recipients: ['siddanagouda.patil@officebeacon.com', /* add 10 emails here */]
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmailDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const sendEmails = async () => {
        try {
            const response = await axios.post('http://localhost:5000/send-email', emailDetails);
            alert(response.data);  // Success message
        } catch (error) {
            alert('Error sending emails: ' + error.message);
        }
    };

    return (
        <div>
            <h2>Send Emails</h2>
            <div>
                <input
                    type="text"
                    name="subject"
                    value={emailDetails.subject}
                    onChange={handleChange}
                    placeholder="Subject"
                />
            </div>
            <div>
                <textarea
                    name="message"
                    value={emailDetails.message}
                    onChange={handleChange}
                    placeholder="Message"
                />
            </div>
            <button onClick={sendEmails}>Send Emails</button>
        </div>
    );
};

export default SendEmails;

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignsAdd from "./CampaignsAdd";
import OpenaiEmailContentGenerator from "../../components/Campaign Components/OpenAiEmailContentGenerator";
import UserCampaignSequence from "./UserCampaignSequence";
import CampaignName from "../../components/Campaign Components/CampaignName";
import { openCampaignNameModal } from "../../features/AIcampaignSlice";
import FinalizeContent from "../../components/Campaign Components/FinalizeSequences";

const PageHeader = () => {
    const dispatch = useDispatch();
    
    // Destructure the state in a cleaner way
    const { campaignNameModalOpen, aiAssistedModalOpen, finalizeContentModalOpen } = useSelector((state) => state.campaign);
    
    // const [showAddCampaign, setShowAddCampaign] = React.useState(false);

    // Use useCallback to prevent unnecessary re-renders of the handler functions
    const openCampaignName = useCallback(() => dispatch(openCampaignNameModal()), [dispatch]);
    // const toggleCampaignAdd = useCallback(() => setShowAddCampaign(prevState => !prevState), []);

    return (
        <header className="border-bottom px-4 ff-inter d-flex py-3 align-items-center position-sticky top-0 bg-white zindex-1">
            <h1 className="ff-inter h4 w-auto d-inline-block me-auto mb-0">Campaigns</h1>
            <button
                type="button"
                className="btn btn-primary border mx-3 fs-14"
                id="addNewMember"
                onClick={openCampaignName}
            >
                <img src="assets/img/svg/plus.svg" alt="Icon" /> Add new
            </button>

            {campaignNameModalOpen && <CampaignName />}
            {aiAssistedModalOpen && <OpenaiEmailContentGenerator />}
            {/* {finalizeContentModalOpen && <FinalizeContent />} */}
             
            {/* {showAddCampaign && <CampaignsAdd closeModal={toggleCampaignAdd} />} */}
        </header>
    );
};

export default PageHeader;

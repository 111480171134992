import React from "react"; 

const tabBarDasdh = () => {
 

 return ( 
     <>
     <div className="bg-F5F8FF p-2 rounded-3 d-inline-block ">
                    <ul className="nav nav-tabs text-center tab-blue-white border-0" id="myTab" role="tablist">
                        <li className="nav-item   me-2 " role="presentation">
                            <a className="nav-link active  fs-14 tc-111111  " id="log_in-tab" data-bs-toggle="tab" href="#log_in" role="tab" aria-controls="log_in" aria-selected="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path d="M1.33337 1.13037V12.797C1.33337 13.2573 1.70647 13.6304 2.16671 13.6304H13.8334M13 4.4637L8.83337 8.63028L6.75004 6.54687L3.83337 9.46353" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Campaign analytics
                            </a>
                        </li>
                        <li className="nav-item  " role="presentation">
                            <a className="nav-link fs-14 tc-111111  " id="sign_up-tab" data-bs-toggle="tab" href="#sign_up" role="tab" aria-controls="sign_up" aria-selected="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path d="M1.33337 1.13037V12.797C1.33337 13.2573 1.70647 13.6304 2.16671 13.6304H13.8334M13 4.4637L8.83337 8.63028L6.75004 6.54687L3.83337 9.46353" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Account analytics
                            </a>
                        </li>
                    </ul> 
                </div>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="log_in" role="tabpanel" aria-labelledby="log_in-tab">
                        <table className="table table-striped example w-100"  >
                            <thead>
                                <tr>
                                    <th>Campaign</th>
                                    <th>Status</th>
                                    <th>Started Sequence</th>
                                    <th>Opened</th>
                                    <th>Replied</th>
                                    <th>Opportunities</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Email-1 Sep Usher Mix</td>
                                    <td><span className="text-white py-1 px-3 rounded-pill bg-74C4FF d-inline-block mw-110 text-center fs-14">Active</span></td>
                                    <td>2,408</td>
                                    <td>163  |  6.8%</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>
                                        <a href="" className="text-decoration-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                <path d="M1 2.5C1 1.94772 1.44772 1.5 2 1.5H4C4.55228 1.5 5 1.94772 5 2.5V12.5C5 13.0523 4.55228 13.5 4 13.5H2C1.44772 13.5 1 13.0523 1 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 2.5C9 1.94772 9.44772 1.5 10 1.5H12C12.5523 1.5 13 1.94772 13 2.5V12.5C13 13.0523 12.5523 13.5 12 13.5H10C9.44772 13.5 9 13.0523 9 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>

                                    </td>
                                </tr>
                                <tr>
                                    <td>Email-1 (Master) Sep 18, 2024 L&L HTML</td>
                                    <td><span className="text-white py-1 px-3 rounded-pill bg-63C888 d-inline-block mw-110 text-center fs-14">Completed</span></td>
                                    <td>2,408</td>
                                    <td>163  |  6.8%</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>
                                        <a href="" className="text-decoration-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M8 17.6783V7.32167C8 6.53258 8.87115 6.05437 9.53688 6.47801L17.6742 11.6563C18.2917 12.0493 18.2917 12.9507 17.6742 13.3437L9.53688 18.522C8.87115 18.9456 8 18.4674 8 17.6783Z" stroke="#63C888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Email-1 Sep 18, 2024 L&L Dipesh sir</td>
                                    <td><span className="text-white py-1 px-3 rounded-pill bg-74C4FF d-inline-block mw-110 text-center fs-14">Active</span></td>
                                    <td>2,408</td>
                                    <td>163  |  6.8%</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>
                                        <a href="" className="text-decoration-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                <path d="M1 2.5C1 1.94772 1.44772 1.5 2 1.5H4C4.55228 1.5 5 1.94772 5 2.5V12.5C5 13.0523 4.55228 13.5 4 13.5H2C1.44772 13.5 1 13.0523 1 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 2.5C9 1.94772 9.44772 1.5 10 1.5H12C12.5523 1.5 13 1.94772 13 2.5V12.5C13 13.0523 12.5523 13.5 12 13.5H10C9.44772 13.5 9 13.0523 9 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>

                                    </td>
                                </tr>
                                <tr>
                                    <td>Email-1 Sep Usher Mix</td>
                                    <td><span className="text-white py-1 px-3 rounded-pill bg-AEB2BC d-inline-block mw-110 text-center  fs-14">Paused</span></td>
                                    <td>2,408</td>
                                    <td>163  |  6.8%</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>
                                        <a href="" className="text-decoration-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                <path d="M1 2.5C1 1.94772 1.44772 1.5 2 1.5H4C4.55228 1.5 5 1.94772 5 2.5V12.5C5 13.0523 4.55228 13.5 4 13.5H2C1.44772 13.5 1 13.0523 1 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 2.5C9 1.94772 9.44772 1.5 10 1.5H12C12.5523 1.5 13 1.94772 13 2.5V12.5C13 13.0523 12.5523 13.5 12 13.5H10C9.44772 13.5 9 13.0523 9 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>

                                    </td>
                                </tr>
                                <tr>
                                    <td>Email-1 Sep Usher Mix</td>
                                    <td><span className="text-white py-1 px-3 rounded-pill bg-AEB2BC d-inline-block mw-110 text-center  fs-14">Paused</span></td>
                                    <td>2,408</td>
                                    <td>163  |  6.8%</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>
                                        <a href="" className="text-decoration-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                <path d="M1 2.5C1 1.94772 1.44772 1.5 2 1.5H4C4.55228 1.5 5 1.94772 5 2.5V12.5C5 13.0523 4.55228 13.5 4 13.5H2C1.44772 13.5 1 13.0523 1 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9 2.5C9 1.94772 9.44772 1.5 10 1.5H12C12.5523 1.5 13 1.94772 13 2.5V12.5C13 13.0523 12.5523 13.5 12 13.5H10C9.44772 13.5 9 13.0523 9 12.5V2.5Z" stroke="#BBBBBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </a>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane fade" id="sign_up" role="tabpanel" aria-labelledby="sign_up-tab">
                        <table className="table table-striped example w-100" >
                            <thead>
                                <tr>
                                    <th>Sending account</th>
                                    <th>Contacted</th>
                                    <th>Opened</th>
                                    <th>Replied</th>
                                    <th>Combined score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>victor@officebeacon.tech</td>
                                    <td>3</td>
                                    <td>3</td>
                                    <td>1</td>
                                    <td> 66.07  </td>
                                </tr>
                                <tr>
                                    <td>nick@officebeacon.tech</td>
                                    <td>2</td>
                                    <td>2</td>
                                    <td>0</td>
                                    <td>50.07  </td>
                                </tr>
                                <tr>
                                    <td>sara@ob-services.com</td>
                                    <td>95</td>
                                    <td>32</td>
                                    <td>0</td>
                                    <td>16.07  </td>
                                </tr>
                                <tr>
                                    <td>dave@theofficebeacon.net</td>
                                    <td>364</td>
                                    <td>96</td>
                                    <td>1</td>
                                    <td>13.3</td>
                                </tr>
                                <tr>
                                    <td>taylor@officebeaconllc.services</td>
                                    <td>319</td>
                                    <td>88</td>
                                    <td>1</td>
                                    <td>13.8</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
     </>

  );
};

export default tabBarDasdh;

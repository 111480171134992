import { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import back_arrow from "../../../assets/img/chevronleft.png"

const CSVUploadModal = ({ show, handleClose }) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const allowedFileTypes = [
    "text/csv",
    "application/xml",
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "copy";
};

const handleDrop = (event) => {
  event.preventDefault();
  const droppedFile = event.dataTransfer.files[0];

  if (droppedFile && allowedFileTypes.includes(droppedFile.type)) {
    setFile(droppedFile);
  } else {
    alert("Please upload a valid CSV, XML, XLS, or XLSX file.");
  }
};
  return (
    <Modal show={show} onHide={handleClose} centered className="email-manual-modal">
      <Modal.Body className="p-5">
        <button className="fc-blue flex items-center mb-3 border-0 bg-transparent" onClick={handleClose}>
          <span className="mr-2 text-lg"><img src={back_arrow} alt="backarrow" /></span> Choose another method
        </button>

        <h4 className="fw-500 mb-4">Upload CSV File</h4>

        <div className="d-flex justify-content-center">
          <div
           onDragOver={handleDragOver}
           onDrop={handleDrop}
            onClick={handleLabelClick}
            style={{ width: "250px", height: "100px" }}
            className="d-flex align-items-center flex-col justify-content-center border border-dashed border-gray-400 rounded-lg p-10 text-gray-500 cursor-pointer hover:border-blue-500 text-center"
          // className="w-full border-2 border-dashed border-gray-300 rounded-lg p-10 flex flex-col items-center justify-center text-gray-500 cursor-pointer hover:border-blue-500 text-center"
          >
            {file ? (
              <span className="text-gray-800 font-medium">{file.name}</span>
            ) : (
              <>
                {/* Upload Icon */}
                <div style={{ width: "50px", height: "50px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-[50px] w-[50px] text-gray-400 mb-2" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 3L12 16M12 16L8 12M12 16L16 12M4 19H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="text-sm font-medium">CLICK TO UPLOAD</div>
              </>
            )}
            <input
              type="file"
              ref={fileInputRef}
              accept=".csv"
              onChange={handleFileChange}
              className="d-none"
            />
          </div>
        </div>

        {file && (
          <div className="mt-4">
            <label className="d-flex align-items-center">
              <input type="checkbox" className="mr-2" /> Check for duplicates
            </label>
            <div className="d-flex justify-content-center">
              <button className="bg-green-500 px-4 py-2 mt-3 border-0">UPLOAD ALL</button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CSVUploadModal;

import { configureStore } from '@reduxjs/toolkit';
import campaignReducer from '../features/campaignSlice';
import campaignButtonReducer from '../features/campaignButtonSlice';
import navigationReducer from '../features/navigationSlice';
import { persistMiddleware } from './Middleware/persistMiddleware';
import authReducer from '../features/authSlice';
import detailsTabReducer from '../features/detailsTabSlice';
import campaignDetailsReducer from '../features/campaignDetailsSlice'
import detailsTabScheduleReducer from '../features/detailsTabScheduleSlice'
import detailsTabLeadsReducer from '../features/detailsTabLeadsSlice'
import dataTableReducer from '../features/dataTableSlice'
import detailsTabSequencesReducer from '../features/detailsTabSequencesSlice'
import campaignDetailsPageReducer from '../features/campaignDetailsPageSlice'
import AIcampaignReducer from '../features/AIcampaignSlice';
import openAiCampaignFormReducer from '../features/openAiCampaignFormSlice';
import finalizeContentReducer from '../features/finalizeContentSlice';
import analyticsReducer from '../features/analyticsSlice';
import tabOptionsReducer from '../features/tabOptionsSlice';
import emailReducer from "../features/emailSlice";
import addEmailReducer from "../features/addEmailSlice";
import selectedEmailReducer from '../features/selectedEmailSlice';


const storedNavigationState = localStorage.getItem('navigationState');
const initialState = {
    navigation: storedNavigationState ? JSON.parse(storedNavigationState) : { currentPath: "/" },
}
export const store = configureStore({
    reducer: {
        campaigns: campaignReducer,
        campaignButton: campaignButtonReducer,
        navigation: navigationReducer,
        auth: authReducer,
        detailsTab: detailsTabReducer,
        campaignDetails: campaignDetailsReducer,
        detailsTabSchedule: detailsTabScheduleReducer,
        detailsTabLeads: detailsTabLeadsReducer,
        dataTable: dataTableReducer,
        detailsTabSequences: detailsTabSequencesReducer,
        campaignDetailsPage: campaignDetailsPageReducer,
        campaign: AIcampaignReducer,
        campaignForm: openAiCampaignFormReducer,
        finalizeContent: finalizeContentReducer,
        analytics: analyticsReducer,
        tabOptions: tabOptionsReducer,
        email: emailReducer,
        addEmail: addEmailReducer,
        selectedEmail: selectedEmailReducer, 
        // contacts: detailsTabLeadsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(persistMiddleware),
    preloadedState: initialState
});

export default store;

// src/features/AIcampaignSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campaignName: '',
  steps: [{ day: 1, subject: '', message: '' }],
  isCampaignNameSubmitted: false,
  showForm: false,
  showFirstSection: true,
  aiAssistedModalOpen: false,
  campaignNameModalOpen: false,
  finalizeContentModalOpen: false,
};

const AIcampaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    handleInputChange: (state, action) => {
      const { index, field, value } = action.payload;
      state.steps[index][field] = value;
    },
    addSequence: (state) => {
      const lastDay = state.steps.length > 0 ? state.steps[state.steps.length - 1].day + 3 : 1;
      state.steps.push({ day: lastDay, subject: '', message: '' });
    },
    removeSequence: (state, action) => {
      const indexToRemove = action.payload;
      state.steps = state.steps.filter((_, index) => index !== indexToRemove);
    },
    setIsCampaignNameSubmitted: (state, action) => {
      state.isCampaignNameSubmitted = action.payload;
    },
    setShowForm: (state, action) => {
      state.showForm = action.payload;
    },
    setShowFirstSection: (state, action) => {
      state.showFirstSection = action.payload;
    },
    openAiAssistedModal: (state) => {
      state.aiAssistedModalOpen = true;
    },
    closeAiAssistedModal: (state) => {
      state.aiAssistedModalOpen = false;
    },
    openCampaignNameModal: (state) => {
      state.campaignNameModalOpen = true;
    },
    closeCampaignNameModal: (state) => {
      state.campaignNameModalOpen = false;
    },
    openFinalizeContentModal: (state) => {
      state.finalizeContentModalOpen = true;
    },
    closeFinalizeContentModal: (state) => {
      state.finalizeContentModalOpen = false;
    },
    resetCampaignState: (state) => {
      return initialState; 
    },
  },
});

export const {
  setCampaignName,
  setSteps,
  handleInputChange,
  addSequence,
  removeSequence,
  setIsCampaignNameSubmitted,
  setShowForm,
  setShowFirstSection,
  openAiAssistedModal,
  closeAiAssistedModal,
  openCampaignNameModal,
  closeCampaignNameModal,
  openFinalizeContentModal,
  closeFinalizeContentModal,
  resetCampaignState,
} = AIcampaignSlice.actions;
export const selectCampaignName = (state) => state.campaign.campaignName;

export default AIcampaignSlice.reducer;
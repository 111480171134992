import React, { useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import "./styles/AccordianStyle.css";
import { setSelectedFilters } from "../features/campaignDetailsSlice";
import { useDispatch } from "react-redux";

const HorizontalDropdownAccordion = ({ selectedOptions, handleCheckboxChange, allColumns, clearAll, applySelections, selectedItems, handleSelect, openAccordion, setOpenAccordion, handleDropdownChange, selectedValue }) => {
  const dispatch = useDispatch();

  // Separate dropdown lists for each accordion
  const dropdownOptions = {
    0: ["Email", "SMS", "LinkedIn", "Whatsapp", "Twitter"],
    1: ["Received", "Sent", "Bounce", "Clicked", "Open"],
    2: ["Positive", "Negative", "Neutral", "DND", "Out of Office"],
  };

  return (
    <div className="d-flex pt-2 gap-2 align-items-start acc-main">
      {/* Accordion acting as Dropdown */}
      <div className="left-accordian d-flex">
        <Accordion className="d-flex">
          {[0, 1, 2]?.map((index) => (
            <Accordion.Item
              eventKey={String(index)}
              key={index}
              className="custom-accordion-item"
            >
              <Accordion.Header
                className="custom-accordion-header"
                onClick={() =>
                  setOpenAccordion(openAccordion === index ? null : index)
                }
              >
                {selectedItems[index].length > 0
                  ? selectedItems[index].join(", ")
                  :
                  <span className="select-text">
                    {index === 0 ? "Channel" : index === 1 ? "Status" : "Sentiment"}
                  </span>}
              </Accordion.Header>
              <Accordion.Body
                className={`dropdown-body ${openAccordion === index ? "open" : ""}`}
              >
                {dropdownOptions[index]?.map((option, idx) => (
                  <div key={idx} className="dropdown-option">
                    <Form.Check
                      type="checkbox"
                      id={`filter-checkbox-${index}-${idx}`}
                      checked={selectedItems[index].includes(option)}
                      onChange={(event) => handleSelect(index, option, event)}
                      label={option} // Directly use the option as the label
                    />
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>

        {/* Buttons */}
        {Object.values(selectedItems).some((items) => items.length > 0) && (
        <div className="">
          {/* <Button variant="primary" onClick={applySelections}>
          Apply
        </Button> */}
          <Button variant="" className="text-secondary fs-14 clear-btn" onClick={clearAll}>
            X Clear All
          </Button>
        </div>
        )}
      </div>
      <div className="right-accordian">
        <div className="table-structure  ">
          <div className="dropdown me-3 pos position-relative w-auto d-inline-block">
            <button
              className="btn table-btn d-flex border dropdown-toggle fs-14 text-end align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="text-start text-truncate w-200" id="ContDataDropdown">
                Table Structure
              </div>
            </button>

            <ul className="dropdown-menu fs-14  w-100" aria-labelledby="ContDataDropdown">
              {allColumns?.map((column) => (
                <li className="" key={column.data}>
                  <Form.Check
                    type="checkbox"
                    id={`table-structure-${column.data}`}
                    label={column.title}
                    checked={selectedOptions.includes(column.data)}
                    onChange={() => handleCheckboxChange(column.data)}
                  />
                </li>
              ))}
            </ul>

          </div>
          <div className="dropdown">
            <select
              className="form-select"
              value={selectedValue}
              onChange={handleDropdownChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalDropdownAccordion;








// import React, { useState } from "react";
// import { Accordion, Button, Form } from "react-bootstrap";
// import "./styles/AccordianStyle.css";
// import { setSelectedFilters } from "../features/campaignDetailsSlice";
// import { useDispatch } from "react-redux";

// const HorizontalDropdownAccordion = () => {
//   const dispatch = useDispatch();
//   const [selectedItems, setSelectedItems] = useState({ 0: [], 1: [], 2: [] });
//   const [openAccordion, setOpenAccordion] = useState(null);
//   console.log(openAccordion,'openaccorsion');

//   // Separate dropdown lists for each accordion
//   const dropdownOptions = {
//     0: ["Email", "SMS", "LinkedIn", "Whatsapp", "Twitter"],
//     1: ["Received", "Sent", "Bounce"],
//     2: ["Positive", "Negative", "Neutral", "DND", "Out of Office"],
//   };

//   // Function to handle multi-selection
//   const handleSelect = (index, option, event) => {
//     event.stopPropagation(); // Stop event propagation
//     setSelectedItems((prev) => {
//       const newSelections = prev[index].includes(option)
//         ? prev[index].filter((item) => item !== option)
//         : [...prev[index], option];
//       return { ...prev, [index]: newSelections };
//     });
//   };

//   // Function to clear all selections
//   const clearAll = () => {
//     const filters = {
//       channel: [],
//       status: [],
//       sentiment: [],
//     };
//     dispatch(setSelectedFilters(filters));
//     setSelectedItems({ 0: [], 1: [], 2: [] });
//     setOpenAccordion(null); // Close any open accordion
//   };

//   // Function to apply selections
//   const applySelections = () => {
//     const filters = {
//       channel: selectedItems[0],
//       status: selectedItems[1],
//       sentiment: selectedItems[2],
//     };
//     dispatch(setSelectedFilters(filters));
//     setOpenAccordion(null); // Close any open accordion
//   };

//   return (
//     <div className="d-flex gap-2 align-items-start">
//       {/* Accordion acting as Dropdown */}
//       <Accordion className="d-flex">
//         {[0, 1, 2].map((index) => (
//           <Accordion.Item
//             eventKey={String(index)}
//             key={index}
//             className="custom-accordion-item"
//           >
//             <Accordion.Header
//               className="custom-accordion-header"
//               onClick={() =>
//                 setOpenAccordion(openAccordion === index ? null : index)
//               }
//             >
//               {selectedItems[index].length > 0
//                 ? selectedItems[index].join(", ")
//                 : `Select ${
//                     index === 0 ? "Channel" : index === 1 ? "Status" : "Sentiment"
//                   }`}
//             </Accordion.Header>
//             <Accordion.Body
//               className={`dropdown-body ${openAccordion === index ? "open" : ""}`}
//             >
//               {dropdownOptions[index].map((option, idx) => (
//                 <div key={idx} className="dropdown-option">
//                   <Form.Check
//                     type="checkbox"
//                     label={option}
//                     checked={selectedItems[index].includes(option)}
//                     onChange={(event) => handleSelect(index, option, event)}
//                   />
//                 </div>
//               ))}
//             </Accordion.Body>
//           </Accordion.Item>
//         ))}
//       </Accordion>

//       {/* Buttons */}
//       <div className="d-flex pt-2 flex-row gap-2">
//         <Button variant="primary" onClick={applySelections}>
//           Apply
//         </Button>
//         <Button variant="" className="text-secondary " onClick={clearAll}>
//           X Clear All
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default HorizontalDropdownAccordion;
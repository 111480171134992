import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CampaignData, ContactsData, LeadContactsData, LeadsContactData } from '../services/authFunctions';

export const fetchCampaignDetails = createAsyncThunk(
    'detailsTabLeads/fetchCampaignDetails',
    async (campaignId, { rejectWithValue }) => {
        console.log('sliece');
        try {
            const campaignsData = await CampaignData(campaignId);
            if (Array.isArray(campaignsData)) {
                const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                if (filteredCampaign) {
                    return filteredCampaign;
                } else {
                    return rejectWithValue('Campaign not found');
                }
            } else {
                return rejectWithValue('Campaign data is not in the expected format.');
            }
        } catch (err) {
            return rejectWithValue(err.message || 'Failed to fetch campaign data');
        }
    }
);

export const fetchContacts = createAsyncThunk(
    'detailsTabLeads/fetchContacts',
    async (campaignId, { rejectWithValue }) => {
        try {
            const contacts = await LeadsContactData(campaignId);
            if (!Array.isArray(contacts)) {
                return rejectWithValue("Invalid data format: Expected an array.");
            }
            console.log("contactsAPI", contacts);
            return contacts?.map(contact => {
                const trimmedContact = {};
                Object.keys(contact).forEach(key => {
                    trimmedContact[key] = typeof contact[key] === 'string' ? contact[key].trim() : contact[key];
                });
                return trimmedContact;
            });
        } catch (error) {
            return rejectWithValue("Failed to fetch Data");
        }
    }
);


const initialState = {
    campaign: null,
    contacts: [],
    loading: false,
    error: null,
    filters: {},
    selectedFilters: {},
    searchQuery: '',
    emailIds: [],
};

const detailsTabLeadsSlice = createSlice({
    name: 'detailsTabLeads',
    initialState,
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setSelectedFilters: (state, action) => {
            state.selectedFilters = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCampaignDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCampaignDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.campaign = action.payload;
                state.emailIds = Array.isArray(action.payload.email) ? action.payload.email : [action.payload.email || ""];
            })
            .addCase(fetchCampaignDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchContacts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchContacts.fulfilled, (state, action) => {
                state.loading = false;
                state.contacts = action.payload;
            })
            .addCase(fetchContacts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});


export const { setFilters, setSelectedFilters, setSearchQuery } = detailsTabLeadsSlice.actions;
export default detailsTabLeadsSlice.reducer;
 
import React from 'react';

const Pagination = ({ currentPage, totalPages, paginate }) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            paginate(pageNumber);
        }
    };

   

    const getPageRange = () => {
        if (totalPages <= 5) {
            return pageNumbers;
        }

        const start = Math.max(currentPage - 2, 1);
        const end = Math.min(currentPage + 2, totalPages);

        const range = [];
        if (start > 1) range.push(1);
        if (start > 2) range.push('...');
        range.push(...pageNumbers.slice(start - 1, end));
        if (end < totalPages - 1) range.push('...');
        if (end < totalPages) range.push(totalPages);

        return range;
    };

    const pageRange = getPageRange();

    return (
        <nav aria-label="Page navigation example" className=" position-relative zindex-0">
            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" href="#" onClick={() => handlePageClick(currentPage - 1)}>
                        Previous
                    </a>
                </li>

                {/* Page numbers */}
                {pageRange.map((page, index) => (
                    <li
                        key={index}
                        className={`page-item ${page === currentPage ? 'active' : ''} ${page === '...' ? 'disabled' : ''}`}
                    >
                        {page === '...' ? (
                            <span className="page-link">...</span>
                        ) : (
                            <a
                                className="page-link"
                                href="#"
                                onClick={() => handlePageClick(page)}
                            >
                                {page}
                            </a>
                        )}
                    </li>
                ))}

                {/* Next button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" href="#" onClick={() => handlePageClick(currentPage + 1)}>
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { isAuthenticated } from '../utils/auth';
import { login, register } from '../services/authFunctions';


const Login = () => {
    const navigate = useNavigate();
    useEffect(() => { 
        if (isAuthenticated()) {
            navigate('/dashboard', { replace: true });
        }
    }, [navigate]);

    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });

    const [registerData, setRegisterData] = useState({
        name: '',
        email: '',
        password: '',
        c_password: '',
        agreeToTerms: false
    });

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({}); // State for validation errors

    // Handle login form changes
    const handleLoginChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        });
    };

    // Handle register form changes
    const handleRegisterChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setRegisterData({
            ...registerData,
            [e.target.name]: value
        });
    };

    // Handle login submission
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');

        
        try {
            const response = await login(loginData);  
            // Check if the response indicates success
            if (response.success === false) {
                setMessage('Login failed. Please try again.');
                setIsLoading(false);
                return false;
            } else { 
                setMessage('Login Successful!'); 
                
                if (response.token) {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('userName', response.name);
                }
            };
            localStorage.setItem('userName', response.name);
            localStorage.setItem('token', response.token); 
            navigate('/dashboard');
            // console.log(response.name); 
            
        } catch (error) {
            
            setMessage(error.response?.data?.message || 'Login failed. Please try again.');
            console.error('Login Error:', error);
        } finally {
            setIsLoading(false);
        }
        

/*
        // Static login details
        const staticEmail = 'ab@gmail.com';
        const staticPassword = '123456';

        // Check if login data matches static details
        if (loginData.email === staticEmail && loginData.password === staticPassword) {
            setMessage('Login Successful!');

            // Example token for testing purposes
            const testToken = 'dummyToken123';
            localStorage.setItem('token', testToken);

            navigate('/dashboard');
        } else {
            setMessage('Login failed. Please try again.');
        }
        */
    };

    // Handle registration submission
    const handleSignupSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');
        setErrors({}); // Reset errors

        // Validate passwords match
        if (registerData.password !== registerData.c_password) {
            setErrors({ c_password: 'Passwords do not match.' });
            setIsLoading(false);
            return;
        }

        // Validate terms agreement
        if (!registerData.agreeToTerms) {
            setMessage('Please agree to the Terms of Use and Privacy Policy');
            setIsLoading(false);
            return;
        }

        try { 
            const response = await register(registerData);  
            setMessage('Registration Successful!');

            if (response.token) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('userName', response.name);  
                navigate('/dashboard');
            }
           
        } catch (error) {
            if (error.response && error.response.data.data) {
                // Populate errors state with validation errors
                setErrors(error.response.data.data);
            } else {
                setMessage(error.response?.data?.message || 'Registration failed. Please try again.');
            }
            console.error('Registration Error:', error);
        } finally {
            setIsLoading(false);
        }

       

    };

    return (
        <div className="login-bg vh-100 w-100 d-flex align-items-center justify-content-center">
            <div className="w-390 bg-white">
                {message && (
                    <div className={`alert ${message.includes('Success') ? 'alert-success' : 'alert-danger'}`}>
                        {message}
                    </div>
                )}
                
                <ul className="nav nav-tabs justify-content-evenly text-center" id="myTab" role="tablist">
                    <li className="nav-item w-50" role="presentation">
                        <a className="nav-link active border-0 border-bottom text-dark fs-14 tc-111111 p-3 d-inline-block"
                            id="log_in-tab" data-bs-toggle="tab" href="#log_in" role="tab">
                            Log in
                        </a>
                    </li>
                    <li className="nav-item w-50" role="presentation">
                        <a className="nav-link border-0 border-bottom text-dark fs-14 tc-111111 p-3 d-inline-block"
                            id="sign_up-tab" data-bs-toggle="tab" href="#sign_up" role="tab">
                            Sign up <img src="../assets/img/svg/sign-up.svg" alt="sign-up" />
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="log_in" role="tabpanel">
                        <div className="p-3 p-md-4">
                        <form className="row g-3" onSubmit={handleLoginSubmit}>
                                <div className="col-12 mb-2"> 
                                    <label htmlFor="loginEmail" className="form-label fs-14">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="loginEmail"
                                        name="email"
                                        value={loginData.email}
                                        onChange={handleLoginChange}
                                        required
                                    />
                                </div>
                                <div className="col-12 mb-2 position-relative">
                                    <label htmlFor="loginPassword" className="form-label fs-14">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="loginPassword"
                                        name="password"
                                        value={loginData.password}
                                        onChange={handleLoginChange}
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary w-100"
                                        disabled={isLoading}
                                    >
                                      Log in
                                    </button>
                                </div>
                            </form>

                            <div className="position-relative my-4 py-1">
                                <hr />
                                <span className="bg-white position-absolute top-50 start-50 translate-middle px-3 pt-0 fs-14 tc-111111">
                                    or
                                </span>
                            </div>

                            <button className="btn text-center border p-2 text-center w-100 fs-14 mb-3">
                                <img src="./assets/img/svg/google.svg" className="me-2" alt="Google" />
                                Log in with Google
                            </button>
                            <button className="btn text-center border p-2 text-center w-100 fs-14 mb-3">
                                <img src="./assets/img/svg/microsoft.svg" className="me-2" alt="Microsoft" />
                                Log in with Microsoft
                            </button>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="sign_up" role="tabpanel">
                        <div className="p-3 p-md-4">
                            <form className="row g-3" onSubmit={handleSignupSubmit}>
                                <div className="col-md-12 col-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        value={registerData.name}
                                        onChange={handleRegisterChange}
                                        required
                                    />
                                </div>
                                {errors.name && (
                                        <div className="text-danger">{errors.name}</div>
                                    )}
                                <div className="col-12 mb-2">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        name="email"
                                        value={registerData.email}
                                        onChange={handleRegisterChange}
                                        required
                                    />
                                </div>
                                {errors.email && (
                                        <div className="text-danger">{errors.email}</div>
                                    )}
                                <div className="col-12 mb-2">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        name="password"
                                        value={registerData.password}
                                        onChange={handleRegisterChange}
                                        required
                                    />
                                </div>
                                {errors.password && (
                                        <div className="text-danger">{errors.password}</div>
                                    )}
                                <div className="col-12 mb-2">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        name="c_password"
                                        value={registerData.c_password}
                                        onChange={handleRegisterChange}
                                        required
                                    />
                                    {errors.c_password && (
                                        <div className="text-danger">{errors.c_password}</div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="agreeTerms"
                                            name="agreeToTerms"
                                            checked={registerData.agreeToTerms}
                                            onChange={handleRegisterChange}
                                        />
                                        <label className="form-check-label fs-12 tc-111111" htmlFor="agreeTerms">
                                            I agree to the Instantly <a href="/terms" className="text-dark">Terms of Use</a> and{' '}
                                            <a href="/privacy" className="text-dark">Privacy policy</a>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary w-100"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Signing up...' : 'Sign Up'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
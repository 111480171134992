import React, { useCallback, useState } from "react";
import styles from "./styles/CampaignName.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCampaignName,
  closeCampaignNameModal,
  setIsCampaignNameSubmitted,
  setShowForm,
  openCampaignNameModal 
} from "../../features/AIcampaignSlice";

const CampaignName = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { campaignName, campaignNameModalOpen } = useSelector(
    (state) => state.campaign
  );

  const [localCampaignName, setLocalCampaignName] = useState(""); 

  const handleContinue = useCallback(() => {
    if (!localCampaignName) {
      alert("Campaign name is required.");
      return;
    }

    // Dispatch Redux action with local state value
    dispatch(setCampaignName(localCampaignName));
    dispatch(setIsCampaignNameSubmitted(true));
    dispatch(setShowForm(true));
    dispatch(closeCampaignNameModal());

   
    setLocalCampaignName("");

    navigate("/campaigns/add/AI-assisted");
  }, [localCampaignName, dispatch, navigate]);

  const handleCancel = useCallback(() => {
    dispatch(closeCampaignNameModal());
    setLocalCampaignName(""); 
  }, [dispatch]);

  if (!campaignNameModalOpen) {
    return null;
  }

  return (
    <>
      <div
        className="modal-backdrop fade show"
        onClick={handleCancel}
        aria-hidden="true"
      ></div>

      <div
        className="modal fade show d-block"
        id="campaign-name"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="campaign-name-modal"
        aria-hidden={!campaignNameModalOpen}
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered h-75">
          <div className="modal-content h-50 d-flex flex-row align-items-center px-5">
            <button
              type="button"
              className="btn-close position-absolute end-0 top-0 mt-2 me-2"
              onClick={handleCancel}
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <div className={styles["campaign-content"]}>
                <h2 className={styles["campaign-title"]}>Let's create a new campaign</h2>
                <p className={styles["campaign-subtitle"]}>What would you like to name it?</p>
                <div className={styles["campaign-input-wrapper"]}>
                  <input
                    type="text"
                    className={styles["campaign-input"]}
                    value={localCampaignName} 
                    onChange={(e) => setLocalCampaignName(e.target.value)} 
                    placeholder=" "
                    required
                    aria-label="Campaign Name"
                  />
                  <label className={styles["campaign-label"]}>Campaign Name</label>
                </div>
                <div className={styles["campaign-buttons"]}>
                  <button className={styles["btn-cancel"]} onClick={handleCancel}>
                    Cancel
                  </button>
                  <button className={styles["btn-continue"]} onClick={handleContinue}>
                    Continue <span className={styles["continue-arrow"]}>›</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignName;
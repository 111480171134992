// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input::placeholder {
    padding-left: 20px;
  }

  .search-input::value{
    padding-left: 20px;
  }


  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 20px;
    height: 20px;
  }

  .search-icon.focused {
    z-index: -1;
  }`, "",{"version":3,"sources":["webpack://./src/common/styles/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;;EAGA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,UAAU;IACV,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;EACb","sourcesContent":[".search-input::placeholder {\n    padding-left: 20px;\n  }\n\n  .search-input::value{\n    padding-left: 20px;\n  }\n\n\n  .search-icon {\n    position: absolute;\n    left: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    z-index: 1;\n    width: 20px;\n    height: 20px;\n  }\n\n  .search-icon.focused {\n    z-index: -1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

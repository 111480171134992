import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  steps: [{ day: 1, subject: "", message: "" }],
  currentDate: new Date(),
  endDate: new Date(),
  loading: false,
  alertMessage: "",
  alertType: "",
  isModalOpen: true,
};

const finalizeContentSlice = createSlice({
  name: 'finalizeContent',
  initialState,
  reducers: {
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    setCurrentDate: (state, action) => {
      state.currentDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
    setAlertType: (state, action) => {
      state.alertType = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    // Reducer for handling input change
    handleInputChange: (state, action) => {
      const { index, field, value } = action.payload;
      state.steps = state.steps.map((step, i) =>
        i === index ? { ...step, [field]: value } : step
      );
    },
    // Reducer for adding a new sequence
    addSequence: (state) => {
      if (state.steps.length > 0) {
        const lastStep = state.steps[state.steps.length - 1];
        if (!lastStep.subject || !lastStep.message) {
          state.alertMessage = "Please fill in subject and message for the previous sequence before adding a new one.";
          state.alertType = "danger";
          return; // Stop adding if validation fails
        }
      }
      state.alertMessage = ""; // Clear previous alert
      state.alertType = "";

      const lastDay = state.steps.length > 0 ? state.steps[state.steps.length - 1].day + 3 : 1;

      state.steps = [
        ...state.steps,
        {
          day: lastDay,
          subject: "",
          message: "",
        },
      ];
    },
    // Reducer for removing a sequence
    removeSequence: (state, action) => {
      state.steps = state.steps.filter((_, index) => index !== action.payload);
    },
  },
});

export const {
  setSteps,
  setCurrentDate,
  setEndDate,
  setLoading,
  setAlertMessage,
  setAlertType,
  setIsModalOpen,
  handleInputChange,
  addSequence,
  removeSequence,
} = finalizeContentSlice.actions;

// Selectors
export const selectSteps = (state) => state.finalizeContent.steps;
export const selectCurrentDate = (state) => state.finalizeContent.currentDate;
export const selectEndDate = (state) => state.finalizeContent.endDate;
export const selectLoading = (state) => state.finalizeContent.loading;
export const selectAlertMessage = (state) => state.finalizeContent.alertMessage;
export const selectAlertType = (state) => state.finalizeContent.alertType;
export const selectIsModalOpen = (state) => state.finalizeContent.isModalOpen;


export default finalizeContentSlice.reducer;
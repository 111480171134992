import axios from 'axios';
import { postData, fetchData, putData, importData } from '../api/api';

// Function to reset the password (POST request)
export const resetPassword = async (email) => {
    try {
        const response = await postData('api/password/reset', { email });
        return response;
    } catch (error) {
        console.error('Error in resetPassword function:', error);
        throw error || 'An error occurred while resetting the password.';
    }
};

// Function to handle login API call
export const login = async (loginData) => {
    try {
        const response = await postData('api/login', loginData);
        const token = response.data.token;

        if (token) {
            localStorage.setItem('token', token);
            return response.data;
        } else {
            throw new Error('No token received');
        }
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

// Function to handle registration API call
export const register = async (registerData) => {
    try {
         const response = await postData('api/register', registerData);
          const token = response.data.token;
          if (token) {
              localStorage.setItem('token', token);
              return response.data;
            } else {
            throw new Error('No token received');
        }
    } catch (error) {
        throw error;
    }
};

// Function to handle contact data featch API call
export const ContactsData = async () => {
    try {
        const response = await fetchData('api/contacts');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const LeadsContactData = async (campaignId) => {
    try {
        const response = await fetchData(`api/contacts/${campaignId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const CampaignData = async () => {
    
    try {
        const response = await fetchData('api/campaigns');
        // console.log('Campaigns Data:', response); // Log the fetched campaigns
        return response; // Make sure this is an array or modify logic accordingly
    } catch (error) {
        console.error('Error fetching campaign data:', error);
        throw error;
    }
};
export const CampaignDataSave = async (campaignData) => {
    try {
        const response = await postData('api/campaigns', campaignData);
        console.log('post data', response);

        // If the response doesn't have the expected data or ID, throw an error
        if (!response || !response.id) {
            throw new Error('Unexpected response format: No ID returned');
        }

        // If there are validation errors in the response
        if (response.success === false) {
            console.error('Validation Errors:', response.errors);
            throw new Error('Validation Errors: ' + JSON.stringify(response.errors));
        }

        return response;  // Returning the entire response as it contains the campaign data
    } catch (error) {
        console.error('Error in saving campaign:', error);

        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }

        throw error;  // Let the error propagate to be handled in the calling function
    }
};

export const CampaignDataSaveEdit = async (requestData) => {
    try {
        const response = await putData(`api/campaigns/${requestData.id}`, requestData);
        console.log("API Response from save:", response); // Log the response from putData

        // Ensure response contains expected structure
        if (!response || !response.id) {
            throw new Error('API did not return expected data');
        }

        return response; // Return the response properly
    } catch (error) {
        console.error("Error saving campaign data:", error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
            alert('Error: ' + error.response.data.message);
        } else {
            alert('An unknown error occurred. Please try again later.');
        }
        throw error; // Rethrow error to propagate it
    }
};


export const CampaignContactDataSave = async (campaignContactData) => {
    try {
        const response = await importData('api/contacts/import', campaignContactData);

        // Log the response to check its structure
        console.log('API Response:', response);

        if (!response) {
            throw new Error('Unexpected response format: No ID returned');
        }

        // If there are validation errors in the response, throw an error
        if (response.success === false) {
            console.error('Validation Errors:', response.errors);
            throw new Error('Validation Errors: ' + JSON.stringify(response.errors));
        }

        return response;
    } catch (error) {
        console.error('Error in saving campaign:', error);
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }
        throw error;
    }
};


export const ScheduleTaskData = async (ScheduleTask) => {
    try {
        const response = await postData('api/schedule-task', ScheduleTask);
       

        return response.message;  


    } catch (error) {
        console.error('Error in saving campaign:', error);

        
        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }

        throw error;  // Let the error propagate to be handled in the calling function
    }
};

export const ScheduleTaskShowdata = async () => {
    try {
        const response = await fetchData('api/campaign-details');
        console.log('Campaigns Data:', response); // Log the fetched campaigns
        return response; // Make sure this is an array or modify logic accordingly
    } catch (error) {
        console.error('Error fetching campaign data:', error);
        throw error;
    }
};

export const gsuiteDataSave = async (gsuiteData) => {
    try {
        const response = await postData('api/gsuite/add', gsuiteData);

        // Handling response as before...
        if (!response) {
            throw new Error('Unexpected response format: No ID returned');
        }

        return response;
    } catch (error) {
        console.error('Error in saving campaign:', error);

        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }

        throw error;
    }
};

export const gsuiteDataShowdata = async () => {
    try {
        const response = await fetchData('api/email-index');
        console.log('Campaigns Data:', response); // Log the fetched campaigns
        return response; // Make sure this is an array or modify logic accordingly
    } catch (error) {
        console.error('Error fetching campaign data:', error);
        throw error;
    }
};

export const importCMPEmailDataSave = async (ICEData) => {
    try {
        const response = await postData('api/getDetailsEmail', ICEData);
        console.log(response)
        // Handling response as before...
        if (!response) {
            throw new Error('Unexpected response format: No ID returned');
        }

        return response;
    } catch (error) {
        console.error('Error in saving campaign:', error);

        if (error.response) {
            console.error('API Error Response:', error.response.data);
            console.error('API Error Status:', error.response.status);
        }

        throw error;
    }
};
import React from "react";  

const DetailsHeader = ({CampaignsName}) => {
   
    return (  
     <header className="border-bottom px-4 ff-inter d-flex py-3 align-items-center position-sticky top-0 bg-white  zindex-1">
         <h1 className="ff-inter h4 w-auto d-inline-block me-auto mb-0">{CampaignsName}</h1>  
     </header>

  );
};

export default DetailsHeader;

import { React, useState, useRef } from 'react'
import NoLeadsImg from '../../assets/img/NoLeadsIllustration.png'


const EmptyDetails = () => {

  
  return (
    <>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center align-items-center flex-column p-4  ">
                <img src={NoLeadsImg} alt="" />
                <div className='mt-4'>
                  <p className='fw-bold'>📄 No details available. Add some to get started!</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>

  
      </div>
    </>
  )
}

export default EmptyDetails;
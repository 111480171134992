import React, { useEffect } from 'react';
// import { logout } from '../api/api';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
   
    const navigate = useNavigate();

    useEffect(() => {
        // Clear the user session or token
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.clear();
        // Optionally, show a success message (you can also remove the alert)
       // alert('Logged out successfully');

        // Redirect to the login page
        navigate('/ ');
    }, [navigate]);

    return (
        <div>
            <h1>Logged out successfully</h1> 
        </div>
    );
};

export default Logout;
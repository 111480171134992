import React, { useState, useEffect, useRef } from 'react';
import "./styles/OptionPageStyle.css"
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    setDailyLimit,
    setStopOnReply,
    setOpenTracking,
    setLinkTracking,
    setTextOnly,
    setOwner,
    setMinTime,
    setRandomTime,
    setMaxLeads,
    setTextOnlyPrioritize,
    setWinningMetric,
    setProviderMatching,
    setCompanyReplyStop,
    setAutoReplyStop,
    setUnsubscribeLink,
    setRiskyEmails,
    setDisableBounceProtect,
    setCcRecipients,
    setBccRecipients,
    setSelectedAccounts,
    setShowAdvanced,
    setTagsInput,
    setTags,
    setApiError,
    setApiSuccess,
    fetchCampaignSettings,
    saveCampaignSettings,
} from '../../../features/tabOptionsSlice';
import "./styles/TabOptionStyle.css";
import EmailSelectionModal from './EmailSelectionModal';
import { fetchSelectedEmails } from "../../../features/selectedEmailSlice";
import { removeSelectedEmail } from '../../../features/removeSelectedEmailSlice';

const EmailSettings = () => {
    const { campaignId } = useParams();
    const dispatch = useDispatch();
    const {
        dailyLimit,
        stopOnReply,
        openTracking,
        linkTracking,
        textOnly,
        owner,
        minTime,
        randomTime,
        maxLeads,
        textOnlyPrioritize,
        winningMetric,
        providerMatching,
        companyReplyStop,
        autoReplyStop,
        unsubscribeLink,
        riskyEmails,
        disableBounceProtect,
        ccRecipients,
        bccRecipients,
        selectedAccounts,
        showAdvanced,
        tagsInput,
        tags,
        apiError,
        apiSuccess,
        loading,
        allAccounts
    } = useSelector((state) => state.tabOptions);

    const { addedEmails, error } = useSelector(state => state.selectedEmail);


    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);
    const [noEmailFound, setNoEmailFound] = useState(false);
    const [showAddEmailModal, setShowAddEmailModal] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const visibleEmails = showAll ? addedEmails : addedEmails.slice(0, 9);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef?.current && !inputRef?.current?.contains(event.target) &&
                !(event.target.classList.contains('dropdown-item') ||
                    (event.target.parentNode && event.target.parentNode.classList?.contains('dropdown-item')))) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

    useEffect(() => {
        if (campaignId) {
            dispatch(fetchCampaignSettings(campaignId));
        }
        if (campaignId) {
            dispatch(fetchSelectedEmails(campaignId));
        }
        console.log("fetchSelectedEmails call")

    }, [dispatch, campaignId]);

    const handleAccountChange = (account) => {
        const isSelected = selectedAccounts?.some(selectedAccount => selectedAccount.id === account.id);
        let newSelection;

        if (isSelected) {
            newSelection = selectedAccounts?.filter(selectedAccount => selectedAccount.id !== account.id);
        } else {
            newSelection = [...selectedAccounts, account];
        }

        dispatch(setSelectedAccounts(newSelection));
    };

    const toggleAdvanced = () => {
        dispatch(setShowAdvanced(!showAdvanced));
    };

    const handleTagsInputChange = (e) => {
        dispatch(setTagsInput(e.target.value));
    };

    const handleSave = async () => {
        const payload = {
            // accounts: selectedAccounts,
            accounts: selectedEmails.map(emailObj => ({ id: emailObj.id })),
            stop_on_reply: stopOnReply,
            open_tracking: openTracking,
            link_tracking: linkTracking,
            delivery_optimization: textOnly,
            text_only_emails: textOnly,
            daily_limit: dailyLimit,
            owner: owner,
            min_time_gap: parseInt(minTime),
            random_additional_time: parseInt(randomTime),
            max_new_leads: maxLeads,
            prioritize_new_leads: textOnlyPrioritize,
            auto_optimize_ab_testing: true,
            // winning_metric: winningMetric,
            providerMatching: providerMatching,
            company_reply_stop: companyReplyStop,
            stop_on_auto_reply: autoReplyStop,
            unsubscribe_link_header: unsubscribeLink,
            enable_risky_emails: riskyEmails,
            disableBounceProtect: disableBounceProtect,
            cc: ccRecipients,
            tags: tags,
        };

        try {
            await dispatch(saveCampaignSettings({ campaignId, payload }));
            dispatch(fetchCampaignSettings(campaignId));

        } catch (error) {
            console.error("Error saving or fetching campaign settings:", error);
        }
    };

    const handleFetchSettings = () => {
        dispatch(fetchCampaignSettings(campaignId));
    };

    const handleLaunch = () => {
        console.log('Campaign launched');
    };

    const removeSelectedAccount = (accountToRemove) => {
        const updatedAccounts = selectedAccounts.filter(account => account.id !== accountToRemove.id);
        dispatch(setSelectedAccounts(updatedAccounts));
    };

    // const removeSelectedEmail = (id) => {
    //     setSelectedEmails(prev => prev.filter(emailObj => emailObj.id !== id));
    // };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value.length > 0) {
            const filteredSuggestions = allAccounts
                .filter(account => account.is_active === 1)
                .filter(account =>
                    account.smtp_username.toLowerCase().includes(value.toLowerCase())
                );
            setSuggestions(filteredSuggestions);

        } else {
            setSuggestions(allAccounts.filter(account => account.is_active === 1)); // Show all active accounts when input is empty
        }
        setShowSuggestions(true);
    };

    const handleSuggestionClick = (email) => {
        handleAccountChange(email);
        setInputValue('');
        setSuggestions([]);
        setShowSuggestions(false);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleFocusOut = () => {
        setTimeout(() => {
            setShowSuggestions(false);
        }, 100); // Delay to allow click event to fire
    };

    const areAllAccountsSelected = () => {
        return selectedAccounts.length === allAccounts.filter(account => account.is_active === 1).length;
    };

    const isAccountSelected = (account) => {
        return selectedAccounts.some(selectedAccount => selectedAccount.id === account.id);
    };

    const handleSelectAll = () => {
        if (areAllAccountsSelected()) {
            dispatch(setSelectedAccounts([]));
        } else {
            const activeAccounts = allAccounts.filter(account => account.is_active === 1);
            dispatch(setSelectedAccounts(activeAccounts));
        }
    };


    const openModal = () => {
        setShowAddEmailModal(true);
    };

    const closeModal = () => {
        setShowAddEmailModal(false);
    };

    const handleEmailSelect = (email) => {
        setSelectedEmails((prev) =>
            prev.includes(email)
                ? prev.filter((e) => e !== email)
                : [...prev, email]
        );
    };

    console.log("addedEmails", selectedEmails, addedEmails, campaignId)

    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-body ">
                    <div className="row">
                        <div className="col-md-3">
                            <h6 className="card-title">Accounts to use</h6>
                            <small className="text-muted">Select one or more accounts to send emails from</small>
                            <div className="position-relative mt-3">
                                {/* <input
                                    style={{ width: "320px" }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Select Accounts"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onFocus={() => {
                                        setSuggestions(allAccounts.filter(account => account.is_active === 1));
                                        setShowSuggestions(true);
                                        // setNoEmailFound(false);
                                    }}

                                    ref={inputRef}
                                /> */}
                                <button className="btn btn-primary" onClick={openModal}>
                                    + Add Email
                                </button>
                                {(selectedEmails.length > 0 || addedEmails.length > 0 )&& (
                                    <span className='fs-12 mx-3'>{addedEmails.length} email added </span>
                                )}
                                {/* <button className="add-email-btn" onClick={openModal}>
                                 Add Email
                                </button> */}

                                {/* {showSuggestions && (
                                    <ul className="dropdown-menu fs-14 px-3 position-absolute" style={{ display: 'block', zIndex: 1 }}>
                                       
                                        <li>
                                            <a className="dropdown-item" onClick={handleSelectAll}>
                                                Select All
                                            </a>
                                        </li>
                                        {noEmailFound ? (
                                            <li>
                                                <a className="dropdown-item">No email found</a>
                                            </li>
                                        ) : (
                                            suggestions.map(account => (
                                                <li key={account.id} className="mt-1 rounded-3">
                                                    <a
                                                        className={`dropdown-item ${isAccountSelected(account) ? 'selected-account' : ''}`}
                                                        onClick={() => handleSuggestionClick(account)}>
                                                        {account.smtp_username}
                                                    </a>
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                )} */}


                            </div>
                        </div>
                        {/* <div className="col-md-8">
                            <div className="d-flex flex-wrap gap-2 mb-2 align-items-center">
                                {selectedEmails.length > 0 &&
                                    visibleEmails.map(email => (
                                        <div key={email} className="selected-account-box">
                                            {email}
                                            <button
                                                type="button"
                                                className="remove-account-button"
                                                onClick={() => removeSelectedEmail(email)}
                                            >
                                                ×
                                            </button>
                                        </div>
                                    ))}
                                {selectedEmails.length > 2 && !showAll && (
                                    <a
                                        className="view-more-button link-more fs-12 "
                                        onClick={() => setShowAll(true)}
                                    >
                                        View more
                                    </a>
                                )}
                            </div>
                        </div> */}

                        <div className="col-md-9" style={{
                            maxHeight: "100px",
                            overflowY: showAll ? "auto" : "hidden",
                            paddingRight: showAll ? "5px" : "0px"
                        }}>
                            <div className="d-flex flex-wrap gap-2 mb-2 align-items-center">
                                {addedEmails.length > 0 &&
                                    visibleEmails.map(emailObj => (
                                        <div key={emailObj.id} className="selected-account-box">
                                            {emailObj.email} {/* Extract email string */}
                                            <button
                                                type="button"
                                                className="remove-account-button"
                                                // onClick={() => removeSelectedEmail(emailObj.id)}
                                                onClick={() => dispatch(removeSelectedEmail({ id: emailObj.userId, campaignId }))}
                                            >
                                                ×
                                            </button>
                                        </div>
                                    ))}
                                {addedEmails.length > 9 && !showAll && (
                                    <a
                                        className="view-more-button link-more fs-12"
                                        onClick={() => setShowAll(true)}
                                    >
                                        View more
                                    </a>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h6 className="card-title">Stop sending emails on reply</h6>
                            <small className="text-muted">Stop sending emails to a lead if a response has been received</small>
                        </div>
                        <div className="col-md-4">
                            <div className='d-flex justify-content-end'>
                                <div className="btn-group">
                                    <button
                                        className={`btn ${stopOnReply ? 'success-color' : 'btn-outline-secondary'}`}
                                        onClick={() => dispatch(setStopOnReply(true))}
                                    >
                                        Enable
                                    </button>
                                    <button
                                        className={`btn ${!stopOnReply ? 'success-color' : 'btn-outline-secondary'}`}
                                        onClick={() => dispatch(setStopOnReply(false))}
                                    >
                                        Disable
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <h6 className="card-title">Open Tracking</h6>
                            <small className="text-muted">Track email opens</small>
                        </div>
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-4 d-flex align-items-center d-flex justify-content-end">
                            <div className="mt-2">
                                <div className="form-check me-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={linkTracking}
                                        onChange={(e) => dispatch(setLinkTracking(e.target.checked))}
                                        id="linkTracking"
                                    />
                                    <label className="form-check-label" htmlFor="linkTracking">
                                        link tracking
                                    </label>
                                </div>
                            </div>
                            <div className="btn-group">
                                <button
                                    className={`btn ${openTracking ? 'success-color' : 'btn-outline-secondary'}`}
                                    onClick={() => dispatch(setOpenTracking(true))}
                                >
                                    Enable
                                </button>
                                <button
                                    className={`btn ${!openTracking ? 'success-color' : 'btn-outline-secondary'}`}
                                    onClick={() => dispatch(setOpenTracking(false))}
                                >
                                    Disable
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center">
                                <h6 className="card-title mb-0">Delivery Optimization</h6>
                                <span className="badge success-color ms-2">Recommended</span>
                            </div>
                            <small className="text-muted d-block">Disables open tracking</small>
                        </div>

                        <div className="col-md-6">
                            <div className="mt-2">

                                <div className="form-check d-flex justify-content-end">
                                    <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        checked={textOnly}
                                        onChange={(e) => dispatch(setTextOnly(e.target.checked))}
                                        id="textOnly"
                                    />
                                    <label className="form-check-label" htmlFor="textOnly">
                                        Send emails as text-only (no HTML)
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="card-title">Daily Limit</h6>
                            <small className="text-muted">Max number of emails to send per day for this campaign</small>
                        </div>

                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="mt-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={dailyLimit}
                                    onChange={(e) => dispatch(setDailyLimit(e.target.value))}
                                    style={{ maxWidth: '200px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=''>
                <button
                    className="btn btn-link d-flex align-items-center mx-auto mb-4 border p-3 advance-button"
                    onClick={toggleAdvanced}
                >
                    {showAdvanced ? 'Hide' : 'Show'} advanced options
                    <span className="ms-2">{showAdvanced ? "▲" : "▼"}</span>
                </button>
            </div>

            {showAdvanced && (
                <div className="advanced-settings">
                    <h6>CRM</h6>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">Owner</h6>
                                    <small className="text-muted">Select the owner of this campaign</small>
                                </div>
                                <div className="col-md-4">
                                    <select
                                        className="form-select"
                                        value={owner}
                                        onChange={(e) => dispatch(setOwner(e.target.value))}
                                    >
                                        <option value="Admin">Admin</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="card-title">Custom Tags</h6>
                    <small className="text-muted">Tags are used to group your campaigns</small>
                    <div className="card mb-3 mt-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter comma-separated tags"
                                        value={tagsInput}
                                        onChange={(e) => dispatch(setTagsInput(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h6 className="card-title">Sending pattern</h6>
                    <small className="text-muted">Specify how you want your emails to go</small>
                    <div className="card mb-3 mt-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="row align-items-center mt-3">
                                    <div className="col-md-4">
                                        <h6 className="card-title">Time gap between emails</h6>
                                    </div>

                                    <div className="col-md-4 ">
                                        <small className="text-muted">Minimum time</small>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="00"
                                                value={minTime}
                                                onChange={(e) => dispatch(setMinTime(e.target.value))}
                                            />
                                            <span className="input-group-text">minutes</span>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <small className="text-muted">Random additional time</small>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="00"
                                                value={randomTime}
                                                onChange={(e) => dispatch(setRandomTime(e.target.value))}
                                            />
                                            <span className="input-group-text">minutes</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">Max new leads</h6>
                                </div>
                                <div className="col-md-4">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={maxLeads}
                                            onChange={(e) => dispatch(setMaxLeads(e.target.value))}
                                        />
                                        <span className="input-group-text">per day</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8 d-flex align-item-center">
                                    <div className="d-flex align-items-center">
                                        <h6 className="card-title">Prioritize new leads</h6>
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex justify-content-end align-item-center">
                                    <div className="mt-2">
                                        <div className="form-check ">
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                checked={textOnlyPrioritize}
                                                onChange={(e) => dispatch(setTextOnlyPrioritize(e.target.checked))}
                                                id="textOnly"
                                            />
                                            <label className="form-check-label" htmlFor="textOnly">
                                                Send emails as text-only (no HTML)
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="d-flex align-items-center mb-2">
                                        <h6 className="card-title">Auto optimize A/2 testing</h6>
                                        <span className="badge success-color ms-2">New</span>
                                    </div>
                                    <small className="text-muted d-block mb-3">
                                        When using A/2 testing, the Instantly algorithm will automatically select the best performing variant after a certain number of emails have been sent.
                                    </small>
                                </div>

                                <div className="col-md-4">
                                    <select
                                        className="form-select"
                                        value={winningMetric}
                                        onChange={(e) => dispatch(setWinningMetric(e.target.value))}
                                    >
                                        <option value="">Choose winning metric</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">Provider Matching</h6>
                                    <small className="text-muted d-block mb-3">
                                        Matches your lead's email provider with your mailbox provider for boosted deliverability. (Outlook to Outlook, Google to Google, etc.)
                                    </small>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="providerMatching"
                                            checked={providerMatching}
                                            onChange={(e) => dispatch(setProviderMatching(e.target.checked))}
                                        />
                                        <label className="form-check-label" htmlFor="providerMatching">
                                            Enable provider matching for deliverability boost
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">Stop campaign for company on reply</h6>
                                    <small className="text-muted d-block mb-3">
                                        Stops the campaign additionally for all leads from a company if a reply is received from any of them
                                    </small>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="companyReplyStop"
                                            checked={companyReplyStop}
                                            onChange={(e) => dispatch(setCompanyReplyStop(e.target.checked))}
                                        />
                                        <label className="form-check-label" htmlFor="companyReplyStop">
                                            Enable company reply stop
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">Stop sending emails on Auto-reply</h6>
                                    <small className="text-muted">
                                        Stop sending emails to a lead if an automatic response has been received, for example for out-of-office replies.
                                    </small>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="autoReplyStop"
                                            checked={autoReplyStop}
                                            onChange={(e) => dispatch(setAutoReplyStop(e.target.checked))}
                                        />
                                        <label className="form-check-label" htmlFor="autoReplyStop">
                                            Stop on auto-reply
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">Insert unsubscribe link header</h6>
                                    <small className="text-muted">
                                        Automatically adds an unsubscribe link to email headers for one-click unsubscription by supported email providers
                                    </small>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="unsubscribeLink"
                                            checked={unsubscribeLink}
                                            onChange={(e) => dispatch(setUnsubscribeLink(e.target.checked))}
                                        />
                                        <label className="form-check-label" htmlFor="unsubscribeLink">
                                            Insert unsubscribe link header
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">Allow risky emails</h6>
                                    <small className="text-muted d-block mb-3">
                                        When using verification, allow emails that are marked as risky to be contacted, or disable BounceProtect to allow known risky emails to be contacted.
                                    </small>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="riskyEmails"
                                                checked={riskyEmails}
                                                onChange={(e) => dispatch(setRiskyEmails(e.target.checked))}
                                            />
                                            <label className="form-check-label" htmlFor="riskyEmails">
                                                Enable risky emails
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="bounceProtect"
                                                checked={disableBounceProtect}
                                                onChange={(e) => dispatch(setDisableBounceProtect(e.target.checked))}
                                            />
                                            <label className="form-check-label" htmlFor="bounceProtect">
                                                Disable BounceProtect
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <h6 className="card-title">CC and BCC</h6>
                    <small className="text-muted ">
                        Add cc and bcc recipients to your emails.
                    </small> */}
                    <h6 className="card-title">CC</h6>
                    <small className="text-muted ">
                        Add cc recipients to your emails.
                    </small>
                    <div className="card mb-3 mt-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6 className="card-title">CC</h6>
                                    <small className="text-muted d-block mb-2">
                                        Send a copy of the email to the addresses listed in the field
                                    </small>
                                </div>
                                <div className="col-md-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={ccRecipients}
                                        // onChange={(e) => setCcRecipients(e.target.value)}
                                        onChange={(e) => {
                                            dispatch(setCcRecipients(e.target.value));
                                            // setCcRecipients(e.target.value);
                                        }}
                                        placeholder="Separate emails with a comma"
                                        style={{ height: '100px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className="card-title">BCC</h6>
                                     <small className="text-muted d-block mb-3">
                                        Send a copy of the email to certain recipients without the other recipients knowing about it
                                     </small>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={bccRecipients}
                                        onChange={(e) => setBccRecipients(e.target.value)}
                                        placeholder="Separate emails with a comma"
                                        style={{ height: '100px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}


                </div>

            )}

            <div className="d-flex justify-content-center gap-3">
                <button
                    className="btn p-2 btn-outline-secondary"
                    onClick={handleSave}
                >
                    {/* <i className="bi bi-save me-2"></i> */}
                    Save Settings
                </button>
                {/* <button onClick={handleFetchSettings}>Fetch Campaign Settings</button> */}

                {/* <button
                    className="btn btn-primary d-flex align-items-center"
                    onClick={handleLaunch}
                >
                    <i className="bi bi-rocket-takeoff me-2"></i>
                    Launch
                </button> */}
            </div>

            {showAddEmailModal && <EmailSelectionModal showAddEmailModal={showAddEmailModal} setShowAddEmailModal={setShowAddEmailModal} selectedEmails={selectedEmails} setSelectedEmails={setSelectedEmails}
            />}
        </div >

    );
};



export default EmailSettings;
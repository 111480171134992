import React, { useEffect, lazy, Suspense, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampaignDetails, saveCampaignDetails, setStages, updateStage } from '../../../features/detailsTabScheduleSlice';


const DetailsTabScheduleComponent = () => {
    const { campaignId } = useParams();
    const dispatch = useDispatch();
    const { campaign, loading, error, stages } = useSelector((state) => state.detailsTabSchedule);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertType, setAlertType] = useState(null);

    useEffect(() => {
        if (campaignId) {
            dispatch(fetchCampaignDetails(campaignId));
        }
    }, [dispatch, campaignId]);

     useEffect(() => {
         if (campaign && campaign.stages) {
           const initialStages =  campaign.stages.map(stage => ({
                ...stage,
                subject: stage.subject || '',
                start_date: stage.start_date || '',
                start_time: stage.start_time || '',
            }))
               dispatch(setStages(initialStages))
        }
    }, [campaign, dispatch])

     const handleChange = (index, field, value) => {
        dispatch(updateStage({ index, field, value }));
     };

     const handleSave = async () => {
        if (!campaignId || !stages.length) {
              showAlert('Campaign ID or Stage data is missing', "danger");
              return;
        }
          try {
            const updatedStagesData = stages.map(stage => ({
                ...stage,
                subject: stage.subject || '',
                start_date: stage.start_date || '',
                start_time: stage.start_time || '',
            }));

            const formatDate = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().slice(0, 19).replace('T', ' ');
            };


            const requestData = {
                user_id: "1",
                id: campaign.id,
                name: campaign.name,
                status: campaign.status,
                status: "Scheduled",

                // scheduled_at: campaign.scheduled_at,
                start_date: formatDate(campaign.start_date),
                end_date: formatDate(campaign.end_date),
                stages: updatedStagesData,
            };
             const response =  await  dispatch(saveCampaignDetails(requestData));
            if (response.payload && response.payload.id) {
                   showAlert('Stage updated successfully!', 'success');
                    await  dispatch(fetchCampaignDetails(campaignId))
                 }
                  else {
                     showAlert("Unexpected error occurred while saving stage.", "danger");
                }
         }
         catch (error) {
               showAlert("Error updating stage", "danger");
         }
    };

    const showAlert = (message, type) => {
        setAlertMessage(message);
        setAlertType(type);

        setTimeout(() => {
            setAlertMessage(null);
            setAlertType(null)
        }, 3000);

    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="text-danger">Error: {error}</div>;
    return (
        <div className="ff-inter h-100">
             {alertMessage && (
                <div
                    className={`alert alert-${alertType} alert-dismissible fade show fixed-top`}
                    role="alert"
                >
                    {alertMessage}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            <div className="row mb-3">
                {stages.map((stage, index) => (
                    <div key={stage.id} className="col-12 col-md-4 col-xl-3">
                        <div className="bg-F5F8FF p-3 rounded-3">
                            <div className="text-dark">
                                Stage {stage.stage_number}
                            </div>
                            <hr />
                            <div className="mb-3">
                                <label className="form-label">Subject</label>
                                <textarea
                                    className="form-control"
                                    value={stage.subject}
                                    onChange={(e) => handleChange(index, 'subject', e.target.value)}
                                    rows="2"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Start Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={stage.start_date}
                                    onChange={(e) => handleChange(index, 'start_date', e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Time</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    value={stage.start_time}
                                     onChange={(e) => handleChange(index, 'start_time', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={handleSave}>Save</button>
            </div>
        </div>
    );
};

const DetailsTabSchedule = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <DetailsTabScheduleComponent />
        </Suspense>
    );
};

export default DetailsTabSchedule;
import React, { useEffect, useState } from 'react';
import Header from "../../common/Header.js";
import PageHeader from "./Header.js";
import DataTableComponent from '../../common/DataTableComponent';
import { CampaignData } from '../../services/authFunctions';

const Campaigns = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const campaigns = await CampaignData();
                //console.log('Fetched Campaigns:', campaigns);

                if (Array.isArray(campaigns)) {
                     
                     setContacts(campaigns.reverse()); // Reverse the order before setting the state
                      
                } else {
                    console.error("Expected an array, but received:", campaigns);
                    setContacts([]); // Set an empty array if data isn't in the expected format
                }
                setLoading(false); // Stop loading after the data is fetched
            } catch (err) {
                console.error('Error fetching campaigns:', err);
                setError('Failed to fetch campaigns');
                setLoading(false); // Stop loading even if there's an error
            }
        };

        fetchCampaigns();
    }, []);

    const columns = [
        { title: "Name", data: "name" },
        { title: "Status", data: "status" }
    ];

    return (
        <div className="w-100 d-flex">
            <Header />
            <div className="w-100 mb-3 mb-mb-4 mb-lg-5">
                <PageHeader />
                {loading ? (
                    <div>Loading...</div>  // Display loading message when fetching data
                ) : error ? (
                    <div>{error}</div>  // Display error message if there was an issue
                    ) : (
                        <div className="p-4">
                                <DataTableComponent tableData={contacts} columns={columns} showCheckboxes={false} showButtonAction={true} addLink={true} basePath="campaigns" />
                            </div>
                )}
            </div>
        </div>
    );
};

export default Campaigns;





/*


import axios from 'axios';

const Campaigns = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  // State to store the error

    useEffect(() => {
        const storedUserName = localStorage.getItem('token');
        // const token = 'YOUR_API_TOKEN'; // Replace with your actual token
        console.log(storedUserName);
        axios
            .get('https://emailcamp.flowzdigital.com/api/campaigns', {
                headers: {
                    'Authorization': `Bearer ${storedUserName}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((err) => {
                // Log the full error object for debugging
                console.error('Error fetching data:', err.response ? err.response : err);

                // Set a user-friendly error message to show on the UI
                if (err.response) {
                    if (err.response.status === 401) {
                        setError('Unauthorized: You do not have permission to access this resource.');
                    } else {
                        setError('An error occurred: ' + err.response.statusText);
                    }
                } else {
                    setError('Network error or the server is down.');
                }

                setLoading(false); // Set loading to false after error occurs
            });
    }, []);

    */
// import React, { useState } from 'react';
// import DeleteBtn from '../../../assets/img/DeleteBtn.png';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import StepSelectionModal from "./StepSelectionModal";

// const StageList = ({ campaign, handleAddStage, handleDeleteStage, handleDaysChange, handleDeleteVariant, handleAddVariant, handleVariantChange, selectedVariantIndex, handleVariantSelect }) => {

//     const [showModal, setShowModal] = useState(false);

//     const handleOpenModal = () => setShowModal(true);
//     const handleCloseModal = () => setShowModal(false);

//     const handleSelectStep = (stepType) => {
//         handleAddStage(stepType);
//         handleCloseModal();
//     };
//     return (
//         <div className="p-3 rounded-3">
//             {campaign?.stages?.length > 0 && (
//                 campaign.stages.map((stage, stageIndex) => (
//                     <div key={stage.id} className="card mb-3 p-3 bg-F5F8FF">
//                         {/* Step Header */}
//                         <div className="d-flex justify-content-between align-items-center">
//                             <span className="fw-bold fs-14">Step {stage.stage_number}</span>
//                             {stageIndex !== 0 && ( // Hide delete button for the first step
//                                 <button
//                                     onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleDeleteStage(stage.id);
//                                     }}
//                                     className="btn btn-sm delete-variant"
//                                     title="Delete Stage"
//                                 >
//                                     <img src={DeleteBtn} className='delete-icon' alt="Delete" />
//                                 </button>
//                             )}
//                         </div>

//                         {stage.variants?.length > 0 &&
//                             stage.variants.map((variant, index) => {
//                                 const variantLetter = String.fromCharCode(65 + index);
//                                 return (
//                                     <div key={index} className="d-flex align-items-center bg-white p-3 my-2 rounded fs-14 main-variant">
//                                         {console.log("selectedStage", variant)}

//                                         <div className={selectedVariantIndex === index ? "round-bg-blue" : "round-bg-grey"}><span className='inner-round-text'>{String.fromCharCode(65 + index)}</span></div>
//                                         <input
//                                             type="text"
//                                             className="form-control fs-14 variant-text"
//                                             value={variant.subject || ""}
//                                             placeholder="<Empty Subject>"
//                                             onClick={() => handleVariantSelect(stage.id, index)}
//                                         />

//                                         <div className="form-check form-switch toggle-switch">
//                                             <input
//                                                 className="form-check-input"
//                                                 type="checkbox"
//                                                 id={`toggle-${stage.id}-${index}`}
//                                             />
//                                         </div>
//                                         <button
//                                             className="btn btn-sm delete-variant"
//                                             title="Delete Variant"
//                                             onClick={() => handleDeleteVariant(stage.id, index)}
//                                         >
//                                             <img src={DeleteBtn} className='delete-icon' alt="Delete" />
//                                         </button>

//                                     </div>
//                                 )
//                             })}

//                         <button onClick={() => handleAddVariant(stage.id)} className="btn mt-3 w-100 fs-14">
//                             + Add Variant
//                         </button>

//                         <div className="d-flex align-items-center mt-3">
//                             <span className="fs-14 me-1">Send next message in</span>
//                             <input
//                                 type="number"
//                                 className="form-control text-center"
//                                 style={{ width: "60px" }}
//                                 value={stage.days || ""}
//                                 placeholder="0"
//                                 onChange={(e) => handleDaysChange(stage.id, e.target.value)}
//                             />
//                             <span className="fs-14  ms-2">Days</span>
//                         </div>


//                     </div>
//                 ))
//             )}

//             <button
//                 // onClick={handleOpenModal}
//                 onClick={handleAddStage}
//                 className="btn mt-3 w-100 fs-14 bg-F5F8FF">
//                 + Add Step
//             </button>

//             <StepSelectionModal
//                 show={showModal}
//                 onClose={handleCloseModal}
//                 onSelect={handleSelectStep}
//             />
//         </div>
//     );
// };

// export default StageList;




import React from 'react';
import DeleteBtn from '../../../assets/img/DeleteBtn.png';

const StageList = ({ campaign, selectedStage, handleStageClick, handleAddStage, handleDelete }) => {
    return (
        <div className="bg-F5F8FF p-3 rounded-3">
            {campaign && campaign.stages && campaign.stages.length > 0 ? (
                campaign.stages.map((stage) => (
                    <div key={stage.id} className="card mb-3">
                        <a
                            href="javascript:void(0)"
                            className={`card-body d-flex justify-content-between align-items-center text-dark text-decoration-none ${selectedStage?.id === stage.id ? "bg-light" : ""}`}
                            onClick={() => handleStageClick(stage)}
                        >
                            <span>
                                Stage Number {stage.stage_number}
                            </span>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(stage.id);
                                }}
                                className="btn btn-sm"
                                title="Delete Stage"
                            >
                                 <img src={DeleteBtn} alt="Delete" />
                            </button>
                        </a>
                    </div>
                ))
            ) : (
                <p>No stages available</p>
            )}

            <button
                onClick={handleAddStage}
                className="btn border mt-3 center-block w-100"
            >
                Add Stage
            </button>
        </div>
    );
};

export default StageList;

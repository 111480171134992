import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, clearError, clearMessage } from '../features/authSlice';
import { isAuthenticated } from '../utils/auth';
import RegisterForm from '../components/RegisterForm';
import "./styles/LoginStye.css";
import eyeIcon from "../assets/img/eye_icon.png";
import eyecloseIcon from "../assets/img/eye-close-icon.png";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, message, token } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard', { replace: true });
        }
    }, [navigate, token]);

    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });

    const [showPassword, setShowPassword] = useState(false);
    const [keepSignedIn, setKeepSignedIn] = useState(false);

    // Handle login form changes
    const handleLoginChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        });
    };

    // Handle login submission
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        dispatch(clearError());
        dispatch(clearMessage());
        dispatch(loginUser(loginData));
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <div className="login-bg vh-100 w-100 d-flex align-items-center justify-content-center bg-image">
            <div className="w-390 bg-white">
                {message && (
                    <div className={`alert ${message.includes('Success') ? 'alert-success' : 'alert-danger'}`}>
                        {message}
                    </div>
                )}

                <ul className="nav nav-tabs justify-content-evenly text-center" id="myTab" role="tablist">
                    <li className="nav-item w-50 " role="presentation">
                        <a className="nav-link active border-0 w-100 border-bottom text-dark fs-14 tc-111111 p-3 d-inline-block tab-text"
                            id="log_in-tab" data-bs-toggle="tab" href="#log_in" role="tab">
                            Log in
                        </a>
                    </li>
                    <li className="nav-item w-50" role="presentation">
                        <a className="nav-link border-0 border-bottom w-100 text-dark fs-14 tc-111111 p-3 d-inline-block tab-text"
                            id="sign_up-tab" data-bs-toggle="tab" href="#sign_up" role="tab">
                            Sign up <img src="../assets/img/svg/sign-up.svg" alt="sign-up" />
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="log_in" role="tabpanel">
                        <div className="p-3 p-md-4">
                            <form className="row g-3" onSubmit={handleLoginSubmit}>
                                <div className="col-12 mb-2">
                                    <label htmlFor="loginEmail" className="form-label fs-14">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="loginEmail"
                                        name="email"
                                        value={loginData.email}
                                        onChange={handleLoginChange}
                                        required
                                    />
                                </div>
                                <div className="col-12 mb-2 position-relative">
                                    <label htmlFor="loginPassword" className="form-label fs-14">Password</label>
                                    <input
                                         type={showPassword ? "text" : "password"} 
                                        className="form-control"
                                        id="loginPassword"
                                        name="password"
                                        value={loginData.password}
                                        onChange={handleLoginChange}
                                        required
                                    />
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer my-3" onClick={togglePasswordVisibility}>
                                        <img src={showPassword ? eyeIcon : eyecloseIcon} alt="Toggle Password Visibility" width="20" />
                                    </span>
                                </div>

                                <div className="col-12 d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        id="keepSignedIn"
                                        className="me-2"
                                        checked={keepSignedIn}
                                        onChange={(e) => setKeepSignedIn(e.target.checked)}
                                    />
                                    <label htmlFor="keepSignedIn" className="form-label fs-14 my-2">Keep me signed in</label>
                                </div>

                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                        disabled={loading}
                                    >
                                        {loading ? 'Logging in...' : 'Log In'}
                                    </button>
                                </div>
                            </form>

                            <div className="position-relative my-4 py-1">
                                <hr />
                                <span className="bg-white position-absolute top-50 start-50 translate-middle px-3 pt-0 fs-14 tc-111111">
                                    or
                                </span>
                            </div>

                            <button className="btn text-center border p-2 text-center w-100 fs-14 mb-3">
                                <img src="./assets/img/svg/google.svg" className="me-2" alt="Google" />
                                Log in with Google
                            </button>
                            <button className="btn text-center border p-2 text-center w-100 fs-14 mb-3">
                                <img src="./assets/img/svg/microsoft.svg" className="me-2" alt="Microsoft" />
                                Log in with Microsoft
                            </button>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="sign_up" role="tabpanel">
                        <RegisterForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CampaignData, ScheduleTaskData } from '../../../services/authFunctions';

const CampaignButtonStart = () => {
    const { campaignId } = useParams(); // Get campaignId from URL
    const [stages, setStages] = useState([]); // Store stages
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showEmails, setShowEmails] = useState(false);
    const [storedEmails, setStoredEmails] = useState([]); // Store email addresses
    const [emailDetails, setEmailDetails] = useState({}); // Store email details for each stage
    const [sending, setSending] = useState(false); // New state to track sending status

    // Fetch campaign details (just stages)
    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const campaignsData = await CampaignData(campaignId);
                if (Array.isArray(campaignsData)) {
                    const filteredCampaign = campaignsData.find(campaign => campaign.id === parseInt(campaignId));
                    if (filteredCampaign) {
                        setStages(filteredCampaign.stages || []); // Set stages data
                    } else {
                        setError('Campaign not found');
                    }
                } else {
                    setError('Campaign data is not in the expected format.');
                }
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaigns:", err);
                setError('Failed to fetch campaigns');
                setLoading(false);
            }
        };

        if (campaignId) {
            fetchCampaign();
        } else {
            setError('Campaign ID is missing');
            setLoading(false);
        }
    }, [campaignId]);

    // Handle change in email details for each stage
    const handleChange = (e, stageId) => {
        const { name, value } = e.target;

        // Update the email details for the specific stage
        setEmailDetails(prev => ({
            ...prev,
            [stageId]: {
                ...prev[stageId],
                [name]: value,
            },
        }));
    };

    // Toggle email visibility and fetch emails from localStorage
    const toggleEmailVisibility = () => {
        setShowEmails(prevState => !prevState);

        if (!showEmails && !storedEmails.length) {
            const emailData = localStorage.getItem('emailIds');
            if (emailData) {
                const parsedEmails = JSON.parse(emailData);
                setStoredEmails(parsedEmails); // Parse and set stored email addresses
                console.log(parsedEmails); // Log emails for debugging
            }
        }
    };

    // Send emails to recipients for a specific stage
    const sendEmails = async (stageId) => {
        const stage = stages.find(stage => stage.id === stageId);
        if (!stage) {
            alert('Stage not found.');
            return;
        }

        const currentEmailDetails = emailDetails[stageId] || {
            subject: stage.subject || 'Default Subject',
            message: stage.description || 'Default Message',
            recipients: storedEmails.length > 0 ? storedEmails : ['exampla@gmailen.com'],
        };

        const stageStartDateTime = new Date(`${stage.start_date} ${stage.start_time}`);
        const currentTime = new Date();
        const timeDiff = stageStartDateTime - currentTime;

        if (timeDiff > 0) {
            alert('Emails will be sent at the scheduled start time.');
            setTimeout(async () => {
                setSending(true);
                try {
                    const response = await ScheduleTaskData({
                        date: stage.start_date,
                        time: stage.start_time,
                         /* users: currentEmailDetails.recipients.map((email, index) => ({
                            id: index + 1,  // Use a proper ID or generate one here
                            email: email,
                        })),*/ 
                       // subject: currentEmailDetails.subject,
                       // body: currentEmailDetails.message,
                        stage: stage.stage_number,
                        campaign_id : campaignId,
                    });
                    alert(response.data);
                } catch (error) {
                    alert('Error sending emails: ' + error.message);
                } finally {
                    setSending(false);
                }
            }, timeDiff);
        } else {
            setSending(true);
            try {
                const response = await ScheduleTaskData({
                    date: stage.start_date,
                    time: stage.start_time,
                    /* users: currentEmailDetails.recipients.map((email, index) => ({
                       id: index + 1,  // Use a proper ID or generate one here
                       email: email,
                   })),*/
                    // subject: currentEmailDetails.subject,
                    // body: currentEmailDetails.message,
                    stage: stage.stage_number,
                    campaign_id : campaignId,
                });
                alert(response.data);
            } catch (error) {
                alert('Error sending emails: ' + error.message);
            } finally {
                setSending(false);
            }
        }
    };

    // Check if emails are already in localStorage and fetch them if necessary
    useEffect(() => {
        const emailData = localStorage.getItem('emailIds');
        if (emailData) {
            const parsedEmails = JSON.parse(emailData);
            setStoredEmails(parsedEmails); // Set stored emails if present
        }
    }, []);

    if (loading) {
        return <div>Loading stages...</div>; // Show loading message while data is being fetched
    }

    if (error) {
        return <div>Error: {error}</div>; // Display error message if something went wrong
    }

    return (
        <div>
            <div>
                {stages.length > 0 ? (
                    <>
                        {stages.map((stage) => (
                            <span key={stage.id}>
                                {stage.stage_number === 1 && (
                                    <div>
                                        <input
                                            type="text"
                                            name="subject"
                                            value={emailDetails[stage.id]?.subject || stage.subject} // Bind to emailDetails for each stage
                                            onChange={(e) => handleChange(e, stage.id)} // Update for specific stage
                                            placeholder="Subject"
                                            hidden
                                        />
                                        <textarea
                                            name="message"
                                            value={emailDetails[stage.id]?.message || stage.description} // Bind to emailDetails for each stage
                                            onChange={(e) => handleChange(e, stage.id)} // Update for specific stage
                                            placeholder="Message"
                                            hidden
                                        />
                                        <button
                                            className="btn btn-primary mb-3"
                                            onClick={() => sendEmails(stage.id)}
                                            disabled={sending} // Disable button when sending
                                        >
                                            {sending ? 'Sending all mails...' : 'Run'}
                                        </button>
                                    </div>
                                )}
                            </span>
                        ))}
                    </>
                ) : (
                    <p>No stages available</p>
                )}
            </div>
        </div>
    );
};

export default CampaignButtonStart;

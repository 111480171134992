import React, { useState } from "react";

const UserCampaignSequence = ({ closeModal }) => {
    // State to track the visibility of the modal content
    const [showSecondModal, setShowSecondModal] = useState(false);

    // Function to handle showing the second modal content
    const handleAddFirstStep = () => {
        setShowSecondModal(true); // Show the second modal content
    };

    return (
        <>
            {/* Backdrop */}
            <div className="modal-backdrop fade show" onClick={closeModal}></div>

            {/* First Modal Content */}
            {!showSecondModal && (
                <div className="modal fade show d-block" id="from-scratch" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered h-75">
                        <div className="modal-content h-100">
                            <button
                                type="button"
                                className="btn-close position-absolute end-0 top-0 mt-2 me-2 zindex-1"
                                aria-label="Close"
                                onClick={closeModal}
                            ></button>
                            <div className="modal-body h-100">
                                <div className="d-flex w-100 h-100 align-items-center justify-content-center ff-inter">
                                    <div className="bg-white rounded-3 text-center">
                                        <img src="assets/img/from-scratch-add.png" alt="" />
                                        <p className="fs-14 text-center pb-2 mt-3 ">Add Steps to your Sequence!</p>
                                        <p className="text-center">
                                            <button
                                                className="btn btn-primary fs-14"
                                                onClick={handleAddFirstStep} // Handle the button click to show second modal content
                                            >
                                                Add First Step
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Second Modal Content */}
            {showSecondModal && (
                <div className="modal fade show d-block" id="from-scratch" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered h-75">
                        <div className="modal-content h-100">
                            <button
                                type="button"
                                className="btn-close position-absolute end-0 top-0 mt-2 me-2 zindex-1"
                                aria-label="Close"
                                onClick={closeModal}
                            ></button>
                            <div className="modal-body h-100">
                                <div className="d-flex w-100 h-100 align-items-center justify-content-center ff-inter">
                                    <div className="bg-white rounded-3 text-center">
                                        <h6 className="fw-500">Select a sequence step</h6>
                                        <div className="mb-3 pb-3 pt-3 px-3 ff-inter">
                                            <span className="fs-14 tc-979797">
                                                Add a step for the sequence to follow and automate for you.
                                            </span>
                                            <div className="d-flex align-items-center mt-4 bg-white border rounded-3 p-3 shadow">
                                                <span>
                                                    <img src="assets/img/email-1.png" alt="" />
                                                </span>
                                                <div className="ms-4">
                                                    <span className="text-dark">Email</span>
                                                    <p className="tc-979797 fs-14 mb-0">Task is created to edit and deliver email.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-4 bg-white border rounded-3 p-3 shadow">
                                                <span>
                                                    <img src="assets/img/whatsapp-1.png" alt="" />
                                                </span>
                                                <div className="ms-4">
                                                    <span className="text-dark">WhatsApp</span>
                                                    <p className="tc-979797 fs-14 mb-0">Task is created to edit and deliver WhatsApp messages.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-4 bg-white border rounded-3 p-3 shadow">
                                                <span>
                                                    <img src="assets/img/sms-1.png" alt="" />
                                                </span>
                                                <div className="ms-4">
                                                    <span className="text-dark">SMS</span>
                                                    <p className="tc-979797 fs-14 mb-0">Task is created to edit and deliver direct messages.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-4 bg-white border rounded-3 p-3 shadow">
                                                <span>
                                                    <img src="assets/img/call-1.png" alt="" />
                                                </span>
                                                <div className="ms-4">
                                                    <span className="text-dark">Phone Call</span>
                                                    <p className="tc-979797 fs-14 mb-0">Task is created to call prospect.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserCampaignSequence;



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_URL;
const API_ENDPOINT = 'api/campaign-analytics';

// Helper function to format dates as 'YYYY-MM-DD'
const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// Helper function to calculate this week's dates
// const calculateThisWeek = () => {
//     const today = new Date();
//     const thisWeekStart = new Date(today);
//     thisWeekStart.setDate(today.getDate() - today.getDay());
//     const thisWeekEnd = today;

//     return {
//         startDate: formatDate(thisWeekStart),
//         endDate: formatDate(thisWeekEnd),
//         selectedTimeRange: 'This Week'
//     };
// };




// export const fetchAnalyticsData = createAsyncThunk(
//     'analytics/fetchAnalyticsData',
//     async ({ campaignId, startDate, endDate }, thunkAPI) => { 
//         try {
//             const token = localStorage.getItem('token');

//             if (!token) {
//                 throw new Error('No token found in localStorage');
//             }

//             let apiUrl = `${url}/${API_ENDPOINT}?campaign_id=${campaignId}`;
//             if (startDate && endDate) {
//                 apiUrl += `&start_date=${startDate}&end_date=${endDate}`;
//             }

//             const response = await axios.get(apiUrl, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });

//             console.log(response.data, "fetch analytics data");
//             return response.data;

//         } catch (error) {
//             console.error('API Error:', error);
//             return thunkAPI.rejectWithValue(error.response?.data || error.message);
//         }
//     }
// );


export const fetchAnalyticsData = createAsyncThunk(
    'analytics/fetchAnalyticsData',
    async ({ campaignId, startDate, endDate }, thunkAPI) => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                throw new Error('No token found in localStorage');
            }

            let apiUrl = `${url}/${API_ENDPOINT}?campaign_id=${campaignId}`;
            // Remove conditional appending of dates
            if (startDate) {
                apiUrl += `&start_date=${startDate}`;
            }
            if (endDate) {
                apiUrl += `&end_date=${endDate}`;
            }

            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log(response.data, "fetch analytics data");
            return response.data;

        } catch (error) {
            console.error('API Error:', error);
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);




// const { startDate: thisWeekStart, endDate: thisWeekEnd, selectedTimeRange: thisWeekRange } = calculateThisWeek();

const initialState = {
    data: {
        status: {
            label: 'Active',
            percentage: 0
        },
        summaryCards: [
            {
                title: "Emails Sent",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: 100,
                metricKey: 'emails_sent',
                bgColor : "#D9F2FD"
            },
            {
                title: "Opened Emails",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'opened_emails',
                 bgColor : "#FFF4D9"
            },
            {
                title: "Clicks",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'clicks',
                bgColor:'#FFE2F7'
            },
            {
                title: "Replies",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'replies',
                bgColor:'#FFF6EF'
            },
            {
                title: "Booked Calls",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'booked_calls',
                bgColor:'#F3ECFF'
            },
            {
                title: "Bounces",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'total_bounces',
                bgColor:'#CEFBF0'
            },
            {
                title: "Positive Replies",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'positive_replies',
                bgColor:'#F3ECFF '
            },
            {
                title: "Negative Replies",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'negative_replies',
                bgColor:'#FFF6EF'
            },
            {
                title: "Neutral Replies",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'neutral_replies',
                bgColor:'#EAF4FF'
            },
            {
                title: "DND",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'dnd',
                bgColor:'#FFEAEA'
            },
            {
                title: "Out Of Office",
                tooltipCodes: "8",
                countForNumber: "Loading...",
                count: "0%",
                metricKey: 'total_ooo',
                bgColor:'#FFEAEA'
            },
        ],
        barChartData: {
            labels: [], // Initial Labels
            datasets: [
                {
                    label: 'Sent',
                    data: [], // Initial Data
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                    borderWidth: 2,
                    pointStyle: 'circle',
                    pointRadius: 4,
                },

                {
                    label: 'Total opens',
                    data: [],
                    borderColor: 'rgba(54, 162, 235, 1)',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                    borderWidth: 2,
                    pointStyle: 'circle',
                    pointRadius: 4,
                },
                {
                    label: 'Total replies',
                    data: [],
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                    borderWidth: 2,
                    pointStyle: 'circle',
                    pointRadius: 4,
                },
                {
                    label: 'Total clicks',
                    data: [],
                    borderColor: 'rgba(153, 102, 255, 1)',
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                    fill: true,
                    borderWidth: 2,
                    pointStyle: 'circle',
                    pointRadius: 4,
                }

            ]
        }
    },
    loading: 'idle',
    error: null,
    startDate: null,
    endDate: null,
    selectedTimeRange: 'All Data',
    selectedMetrics: ['emails_sent', 'opened_emails', 'clicks', 'replies', 'positive_replies', 'negative_replies', 'neutral_replies', 'dnd', 'total_ooo', 'booked_calls', 'total_bounces'], // new state for selected metrics in settings
    initialLoad: true, // Track initial load
};

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setTimeRange: (state, action) => {
            state.selectedTimeRange = action.payload;
            if (action.payload === 'Custom') {
                
                state.startDate = null;
                state.endDate = null;
            }
        },
        setSelectedMetrics: (state, action) => {
            state.selectedMetrics = action.payload;
        },
        clearInitialLoad: (state) => {
            state.initialLoad = false;
        },
        resetAnalyticsState: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnalyticsData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchAnalyticsData.fulfilled, (state, action) => {
                state.loading = 'succeeded';

                const labels = action.payload.chart_data?.map(item => item.date);
                const sentData = action.payload.chart_data?.map(item => item.total_sent);
                const opensData = action.payload.chart_data?.map(item => item.total_opens);
                const clicksData = action.payload.chart_data?.map(item => item.total_clicks);
                const repliesData = action.payload.chart_data?.map(item => item.total_replies);

                const metricDataMap = {
                    'emails_sent': { countForNumber: action.payload.total_sent, count: 100 },
                    'opened_emails': { countForNumber: action.payload.total_opens, count: parseInt(action.payload.open_rate) },
                    'clicks': { countForNumber: action.payload.total_clicks, count: parseInt(action.payload.click_through_rate) },
                    'replies': { countForNumber: action.payload.total_replies, count: parseInt(action.payload.reply_rate) },
                    'positive_replies': { countForNumber: action.payload.sentiment_counts.positive, count: parseInt(action.payload.sentiment_counts.positive_rate) },
                    'negative_replies': { countForNumber: action.payload.sentiment_counts.negative, count: parseInt(action.payload.sentiment_counts.negative_rate) },
                    'neutral_replies': { countForNumber: action.payload.sentiment_counts.neutral, count: parseInt(action.payload.sentiment_counts.neutral_rate) },
                    'dnd': { countForNumber: action.payload.total_dnd, count: parseInt("0") },
                    'total_ooo': { countForNumber: action.payload.total_ooo, count: parseInt(action.payload.ooo_rate) },
                    'booked_calls': { countForNumber: 0, count: parseInt("0") },
                    'total_bounces': { countForNumber: action.payload.total_bounces, count: parseInt(action.payload.bounce_rate) },
                };

                // Map over the existing summaryCards and update the data
                const updatedSummaryCards = state.data.summaryCards.map(card => {
                    const newData = metricDataMap[card.metricKey];
                    return newData ? { ...card, ...newData } : card;
                });

                state.data = {
                    ...state.data,
                    summaryCards: updatedSummaryCards,

                    status: {
                        ...state.data.status,
                        percentage: action.payload.open_rate,
                        label: action.payload.open_rate > 50 ? "Active" : "Active",
                    },
                    barChartData: {
                        labels: labels,
                        datasets: [
                            {
                                label: 'Sent',
                                data: sentData,
                                borderColor: 'rgba(255, 99, 132, 1)',
                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                fill: true,
                                borderWidth: 2,
                                pointStyle: 'circle',
                                pointRadius: 4,
                            },
                            {
                                label: 'Total opens',
                                data: opensData,
                                borderColor: 'rgba(54, 162, 235, 1)',
                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                fill: true,
                                borderWidth: 2,
                                pointStyle: 'circle',
                                pointRadius: 4,
                            },
                            {
                                label: 'Total replies',
                                data: repliesData,
                                borderColor: 'rgba(75, 192, 192, 1)',
                                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                                fill: true,
                                borderWidth: 2,
                                pointStyle: 'circle',
                                pointRadius: 4,
                            },
                            {
                                label: 'Total clicks',
                                data: clicksData,
                                borderColor: 'rgba(153, 102, 255, 1)',
                                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                                fill: true,
                                borderWidth: 2,
                                pointStyle: 'circle',
                                pointRadius: 4,
                            }
                        ]
                    }
                };
            })
            .addCase(fetchAnalyticsData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.payload || action.error.message;
            });
    }
});

export const { setDateRange, setTimeRange, clearInitialLoad, setSelectedMetrics, resetAnalyticsState } = analyticsSlice.actions;

export default analyticsSlice.reducer;